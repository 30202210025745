export const CAROUSEL = 'carousel';
export const CAROUSEL_IMAGE_SKELETON = 'carousel-image-skeleton';
export const CAROUSEL_IMAGE_ROOT = 'carousel-image-root';
export const CAROUSEL_IMAGE = 'carousel-image';
export const CAROUSEL_BANNER_TAGLINE = 'carousel-banner-tagline';
export const CAROUSEL_BANNER_HEADLINE = 'carousel-banner-headline';
export const CAROUSEL_BANNER_BUTTON = 'carousel-banner-button';
export const CAROUSEL_BANNER_ROOT = 'carousel-banner-root';
export const CAROUSEL_BANNER_DESKTOP_PLACEHOLDER = 'carousel-banner-desktop-placeholder';
export const CAROUSEL_BANNER_MOBILE_PLACEHOLDER = 'carousel-banner-mobile-placeholder';
