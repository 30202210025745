import type { SvgImageProps } from './types';

export const BestSellersPlaceholder = ({ className, height = '453', testId, width = '100%' }: SvgImageProps) => (
  <svg
    className={className}
    data-testid={testId}
    width={width}
    height={height}
    viewBox="0 0 300 453"
    preserveAspectRatio="xMidYMid slice"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g clipPath="url(#clip0_97_7890)">
      <rect
        width={width}
        height={height}
        rx="5"
        fill="#E0D2E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.094 357.302C237.633 354.84 236.25 351.502 236.25 348.021V326.12C236.25 321.771 239.776 318.245 244.125 318.245H266.026C269.507 318.245 272.845 319.628 275.307 322.089L309.432 356.214C314.557 361.34 314.557 369.65 309.432 374.776L292.781 391.427C287.655 396.552 279.345 396.552 274.219 391.427L240.094 357.302ZM257.25 334C257.25 331.101 254.899 328.75 252 328.75C249.101 328.75 246.75 331.101 246.75 334C246.75 336.9 249.101 339.25 252 339.25C254.899 339.25 257.25 336.9 257.25 334Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.0942 392.302C51.6328 389.84 50.25 386.502 50.25 383.021V361.12C50.25 356.771 53.7758 353.245 58.125 353.245H80.0258C83.5067 353.245 86.8451 354.628 89.3065 357.089L123.432 391.214C128.557 396.34 128.557 404.65 123.432 409.776L106.781 426.427C101.655 431.552 93.3449 431.552 88.2192 426.427L54.0942 392.302ZM71.25 369C71.25 366.101 68.8995 363.75 66 363.75C63.1005 363.75 60.75 366.101 60.75 369C60.75 371.9 63.1005 374.25 66 374.25C68.8995 374.25 71.25 371.9 71.25 369Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.699 201.094C165.16 198.633 168.498 197.25 171.979 197.25H193.88C198.229 197.25 201.755 200.776 201.755 205.125V227.026C201.755 230.507 200.372 233.845 197.911 236.307L163.786 270.432C158.66 275.557 150.35 275.557 145.224 270.432L128.574 253.781C123.448 248.655 123.448 240.345 128.574 235.219L162.699 201.094ZM186 218.25C188.899 218.25 191.25 215.899 191.25 213C191.25 210.101 188.899 207.75 186 207.75C183.101 207.75 180.75 210.101 180.75 213C180.75 215.899 183.101 218.25 186 218.25Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-18.3014 246.094C-15.84 243.633 -12.5016 242.25 -9.02063 242.25H12.8801C17.2294 242.25 20.7551 245.776 20.7551 250.125V272.026C20.7551 275.507 19.3723 278.845 16.9109 281.307L-17.2141 315.432C-22.3397 320.557 -30.65 320.557 -35.7757 315.432L-52.4264 298.781C-57.552 293.655 -57.552 285.345 -52.4264 280.219L-18.3014 246.094ZM5 263.25C7.89949 263.25 10.25 260.899 10.25 258C10.25 255.101 7.89949 252.75 5 252.75C2.1005 252.75 -0.25 255.101 -0.25 258C-0.25 260.899 2.1005 263.25 5 263.25Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.052 344.698C131.392 346.359 129.14 347.292 126.792 347.292H112.017C109.083 347.292 106.705 344.913 106.705 341.979V327.205C106.705 324.857 107.638 322.604 109.298 320.944L132.319 297.923C135.777 294.465 141.383 294.465 144.841 297.923L156.073 309.156C159.531 312.614 159.531 318.22 156.073 321.677L133.052 344.698ZM117.333 333.125C115.377 333.125 113.792 334.711 113.792 336.667C113.792 338.623 115.377 340.208 117.333 340.208C119.289 340.208 120.875 338.623 120.875 336.667C120.875 334.711 119.289 333.125 117.333 333.125Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        d="M152.988 380.016C152.988 375.1 154.697 370.934 158.115 367.516C161.533 364.098 165.7 362.389 170.615 362.389C175.433 362.389 179.551 364.114 182.969 367.564C186.387 370.982 188.096 375.133 188.096 380.016C188.096 384.866 186.387 389.016 182.969 392.467C179.551 395.885 175.433 397.594 170.615 397.594C165.732 397.594 161.566 395.885 158.115 392.467C154.697 389.049 152.988 384.898 152.988 380.016ZM191.807 423.082C191.807 418.199 193.516 414.049 196.934 410.631C200.352 407.213 204.502 405.504 209.385 405.504C214.3 405.504 218.467 407.213 221.885 410.631C225.303 414.016 227.012 418.167 227.012 423.082C227.012 427.965 225.286 432.132 221.836 435.582C218.418 439 214.268 440.709 209.385 440.709C204.535 440.709 200.384 438.984 196.934 435.533C193.516 432.083 191.807 427.932 191.807 423.082ZM164.365 373.766C162.673 375.491 161.826 377.574 161.826 380.016C161.826 382.457 162.673 384.524 164.365 386.217C166.058 387.877 168.141 388.707 170.615 388.707C173.057 388.707 175.091 387.877 176.719 386.217C178.379 384.524 179.209 382.457 179.209 380.016C179.209 377.574 178.379 375.491 176.719 373.766C175.091 372.04 173.057 371.178 170.615 371.178C168.141 371.178 166.058 372.04 164.365 373.766ZM203.184 416.881C201.523 418.574 200.693 420.641 200.693 423.082C200.693 425.523 201.523 427.59 203.184 429.283C204.876 430.976 206.943 431.822 209.385 431.822C211.826 431.822 213.91 430.976 215.635 429.283C217.36 427.59 218.223 425.523 218.223 423.082C218.223 420.641 217.36 418.574 215.635 416.881C213.91 415.156 211.826 414.293 209.385 414.293C206.943 414.293 204.876 415.156 203.184 416.881ZM205.723 364H215L174.326 439H165L205.723 364Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M-13.0117 423.016C-13.0117 418.1 -11.3027 413.934 -7.88477 410.516C-4.4668 407.098 -0.30013 405.389 4.61523 405.389C9.43294 405.389 13.5508 407.114 16.9688 410.564C20.3867 413.982 22.0957 418.133 22.0957 423.016C22.0957 427.866 20.3867 432.016 16.9688 435.467C13.5508 438.885 9.43294 440.594 4.61523 440.594C-0.267578 440.594 -4.43424 438.885 -7.88477 435.467C-11.3027 432.049 -13.0117 427.898 -13.0117 423.016ZM25.8066 466.082C25.8066 461.199 27.5156 457.049 30.9336 453.631C34.3516 450.213 38.502 448.504 43.3848 448.504C48.3001 448.504 52.4668 450.213 55.8848 453.631C59.3027 457.016 61.0117 461.167 61.0117 466.082C61.0117 470.965 59.2865 475.132 55.8359 478.582C52.418 482 48.2676 483.709 43.3848 483.709C38.5345 483.709 34.3841 481.984 30.9336 478.533C27.5156 475.083 25.8066 470.932 25.8066 466.082ZM-1.63477 416.766C-3.32747 418.491 -4.17383 420.574 -4.17383 423.016C-4.17383 425.457 -3.32747 427.524 -1.63477 429.217C0.0579427 430.877 2.14128 431.707 4.61523 431.707C7.05664 431.707 9.09115 430.877 10.7188 429.217C12.3789 427.524 13.209 425.457 13.209 423.016C13.209 420.574 12.3789 418.491 10.7188 416.766C9.09115 415.04 7.05664 414.178 4.61523 414.178C2.14128 414.178 0.0579427 415.04 -1.63477 416.766ZM37.1836 459.881C35.5234 461.574 34.6934 463.641 34.6934 466.082C34.6934 468.523 35.5234 470.59 37.1836 472.283C38.8763 473.976 40.9434 474.822 43.3848 474.822C45.8262 474.822 47.9095 473.976 49.6348 472.283C51.36 470.59 52.2227 468.523 52.2227 466.082C52.2227 463.641 51.36 461.574 49.6348 459.881C47.9095 458.156 45.8262 457.293 43.3848 457.293C40.9434 457.293 38.8763 458.156 37.1836 459.881ZM39.7227 407H49L8.32617 482H-1L39.7227 407Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M237.293 247.609C237.293 244.66 238.318 242.16 240.369 240.109C242.42 238.059 244.92 237.033 247.869 237.033C250.76 237.033 253.23 238.068 255.281 240.139C257.332 242.189 258.357 244.68 258.357 247.609C258.357 250.52 257.332 253.01 255.281 255.08C253.23 257.131 250.76 258.156 247.869 258.156C244.939 258.156 242.439 257.131 240.369 255.08C238.318 253.029 237.293 250.539 237.293 247.609ZM260.584 273.449C260.584 270.52 261.609 268.029 263.66 265.979C265.711 263.928 268.201 262.902 271.131 262.902C274.08 262.902 276.58 263.928 278.631 265.979C280.682 268.01 281.707 270.5 281.707 273.449C281.707 276.379 280.672 278.879 278.602 280.949C276.551 283 274.061 284.025 271.131 284.025C268.221 284.025 265.73 282.99 263.66 280.92C261.609 278.85 260.584 276.359 260.584 273.449ZM244.119 243.859C243.104 244.895 242.596 246.145 242.596 247.609C242.596 249.074 243.104 250.314 244.119 251.33C245.135 252.326 246.385 252.824 247.869 252.824C249.334 252.824 250.555 252.326 251.531 251.33C252.527 250.314 253.025 249.074 253.025 247.609C253.025 246.145 252.527 244.895 251.531 243.859C250.555 242.824 249.334 242.307 247.869 242.307C246.385 242.307 245.135 242.824 244.119 243.859ZM267.41 269.729C266.414 270.744 265.916 271.984 265.916 273.449C265.916 274.914 266.414 276.154 267.41 277.17C268.426 278.186 269.666 278.693 271.131 278.693C272.596 278.693 273.846 278.186 274.881 277.17C275.916 276.154 276.434 274.914 276.434 273.449C276.434 271.984 275.916 270.744 274.881 269.729C273.846 268.693 272.596 268.176 271.131 268.176C269.666 268.176 268.426 268.693 267.41 269.729ZM268.934 238H274.5L250.096 283H244.5L268.934 238Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M51.293 282.609C51.293 279.66 52.3184 277.16 54.3691 275.109C56.4199 273.059 58.9199 272.033 61.8691 272.033C64.7598 272.033 67.2305 273.068 69.2812 275.139C71.332 277.189 72.3574 279.68 72.3574 282.609C72.3574 285.52 71.332 288.01 69.2812 290.08C67.2305 292.131 64.7598 293.156 61.8691 293.156C58.9395 293.156 56.4395 292.131 54.3691 290.08C52.3184 288.029 51.293 285.539 51.293 282.609ZM74.584 308.449C74.584 305.52 75.6094 303.029 77.6602 300.979C79.7109 298.928 82.2012 297.902 85.1309 297.902C88.0801 297.902 90.5801 298.928 92.6309 300.979C94.6816 303.01 95.707 305.5 95.707 308.449C95.707 311.379 94.6719 313.879 92.6016 315.949C90.5508 318 88.0605 319.025 85.1309 319.025C82.2207 319.025 79.7305 317.99 77.6602 315.92C75.6094 313.85 74.584 311.359 74.584 308.449ZM58.1191 278.859C57.1035 279.895 56.5957 281.145 56.5957 282.609C56.5957 284.074 57.1035 285.314 58.1191 286.33C59.1348 287.326 60.3848 287.824 61.8691 287.824C63.334 287.824 64.5547 287.326 65.5312 286.33C66.5273 285.314 67.0254 284.074 67.0254 282.609C67.0254 281.145 66.5273 279.895 65.5312 278.859C64.5547 277.824 63.334 277.307 61.8691 277.307C60.3848 277.307 59.1348 277.824 58.1191 278.859ZM81.4102 304.729C80.4141 305.744 79.916 306.984 79.916 308.449C79.916 309.914 80.4141 311.154 81.4102 312.17C82.4258 313.186 83.666 313.693 85.1309 313.693C86.5957 313.693 87.8457 313.186 88.8809 312.17C89.916 311.154 90.4336 309.914 90.4336 308.449C90.4336 306.984 89.916 305.744 88.8809 304.729C87.8457 303.693 86.5957 303.176 85.1309 303.176C83.666 303.176 82.4258 303.693 81.4102 304.729ZM82.9336 273H88.5L64.0957 318H58.5L82.9336 273Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M171.396 290.305C171.396 288.83 171.909 287.58 172.935 286.555C173.96 285.529 175.21 285.017 176.685 285.017C178.13 285.017 179.365 285.534 180.391 286.569C181.416 287.595 181.929 288.84 181.929 290.305C181.929 291.76 181.416 293.005 180.391 294.04C179.365 295.065 178.13 295.578 176.685 295.578C175.22 295.578 173.97 295.065 172.935 294.04C171.909 293.015 171.396 291.77 171.396 290.305ZM183.042 303.225C183.042 301.76 183.555 300.515 184.58 299.489C185.605 298.464 186.851 297.951 188.315 297.951C189.79 297.951 191.04 298.464 192.065 299.489C193.091 300.505 193.604 301.75 193.604 303.225C193.604 304.689 193.086 305.939 192.051 306.975C191.025 308 189.78 308.513 188.315 308.513C186.86 308.513 185.615 307.995 184.58 306.96C183.555 305.925 183.042 304.68 183.042 303.225ZM174.81 288.43C174.302 288.947 174.048 289.572 174.048 290.305C174.048 291.037 174.302 291.657 174.81 292.165C175.317 292.663 175.942 292.912 176.685 292.912C177.417 292.912 178.027 292.663 178.516 292.165C179.014 291.657 179.263 291.037 179.263 290.305C179.263 289.572 179.014 288.947 178.516 288.43C178.027 287.912 177.417 287.653 176.685 287.653C175.942 287.653 175.317 287.912 174.81 288.43ZM186.455 301.364C185.957 301.872 185.708 302.492 185.708 303.225C185.708 303.957 185.957 304.577 186.455 305.085C186.963 305.593 187.583 305.847 188.315 305.847C189.048 305.847 189.673 305.593 190.19 305.085C190.708 304.577 190.967 303.957 190.967 303.225C190.967 302.492 190.708 301.872 190.19 301.364C189.673 300.847 189.048 300.588 188.315 300.588C187.583 300.588 186.963 300.847 186.455 301.364ZM187.217 285.5H190L177.798 308H175L187.217 285.5Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M5.39648 335.305C5.39648 333.83 5.90918 332.58 6.93457 331.555C7.95996 330.529 9.20996 330.017 10.6846 330.017C12.1299 330.017 13.3652 330.534 14.3906 331.569C15.416 332.595 15.9287 333.84 15.9287 335.305C15.9287 336.76 15.416 338.005 14.3906 339.04C13.3652 340.065 12.1299 340.578 10.6846 340.578C9.21973 340.578 7.96973 340.065 6.93457 339.04C5.90918 338.015 5.39648 336.77 5.39648 335.305ZM17.042 348.225C17.042 346.76 17.5547 345.515 18.5801 344.489C19.6055 343.464 20.8506 342.951 22.3154 342.951C23.79 342.951 25.04 343.464 26.0654 344.489C27.0908 345.505 27.6035 346.75 27.6035 348.225C27.6035 349.689 27.0859 350.939 26.0508 351.975C25.0254 353 23.7803 353.513 22.3154 353.513C20.8604 353.513 19.6152 352.995 18.5801 351.96C17.5547 350.925 17.042 349.68 17.042 348.225ZM8.80957 333.43C8.30176 333.947 8.04785 334.572 8.04785 335.305C8.04785 336.037 8.30176 336.657 8.80957 337.165C9.31738 337.663 9.94238 337.912 10.6846 337.912C11.417 337.912 12.0273 337.663 12.5156 337.165C13.0137 336.657 13.2627 336.037 13.2627 335.305C13.2627 334.572 13.0137 333.947 12.5156 333.43C12.0273 332.912 11.417 332.653 10.6846 332.653C9.94238 332.653 9.31738 332.912 8.80957 333.43ZM20.4551 346.364C19.957 346.872 19.708 347.492 19.708 348.225C19.708 348.957 19.957 349.577 20.4551 350.085C20.9629 350.593 21.583 350.847 22.3154 350.847C23.0479 350.847 23.6729 350.593 24.1904 350.085C24.708 349.577 24.9668 348.957 24.9668 348.225C24.9668 347.492 24.708 346.872 24.1904 346.364C23.6729 345.847 23.0479 345.588 22.3154 345.588C21.583 345.588 20.9629 345.847 20.4551 346.364ZM21.2168 330.5H24L11.7979 353H9L21.2168 330.5Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.0942 133.302C87.6328 130.84 86.25 127.502 86.25 124.021L86.25 102.12C86.25 97.771 89.7758 94.2452 94.125 94.2452H116.026C119.507 94.2452 122.845 95.6281 125.307 98.0895L159.432 132.214C164.557 137.34 164.557 145.65 159.432 150.776L142.781 167.427C137.655 172.552 129.345 172.552 124.219 167.427L90.0942 133.302ZM107.25 110C107.25 107.101 104.899 104.75 102 104.75C99.1005 104.75 96.75 107.101 96.75 110C96.75 112.9 99.1005 115.25 102 115.25C104.899 115.25 107.25 112.9 107.25 110Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.328 38.4641C265.599 41.9346 264.48 45.3704 262.217 48.0158L247.982 64.6595C245.156 67.9647 240.185 68.3525 236.879 65.5257L220.236 51.2908C217.59 49.0283 215.952 45.8076 215.681 42.3372L211.928 -5.77669C211.364 -13.0035 216.765 -19.319 223.992 -19.8827L247.469 -21.7142C254.695 -22.2779 261.011 -16.8765 261.575 -9.64972L265.328 38.4641ZM237.145 45.0215C235.261 47.225 235.519 50.5391 237.723 52.4237C239.926 54.3083 243.24 54.0497 245.125 51.8462C247.009 49.6427 246.751 46.3287 244.547 44.4441C242.344 42.5595 239.03 42.818 237.145 45.0215Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6986 -12.9058C20.16 -15.3672 23.4984 -16.75 26.9794 -16.75H48.8801C53.2294 -16.75 56.7551 -13.2242 56.7551 -8.875V13.0258C56.7551 16.5067 55.3723 19.8451 52.9109 22.3065L18.7859 56.4315C13.6603 61.5572 5.34998 61.5572 0.224346 56.4315L-16.4264 39.7808C-21.552 34.6551 -21.552 26.3449 -16.4264 21.2192L17.6986 -12.9058ZM41 4.25C43.8995 4.25 46.25 1.89949 46.25 -1C46.25 -3.8995 43.8995 -6.25 41 -6.25C38.1005 -6.25 35.75 -3.8995 35.75 -1C35.75 1.89949 38.1005 4.25 41 4.25Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.316 206.631C241.845 206.902 238.409 205.783 235.764 203.52L219.12 189.285C215.815 186.459 215.427 181.487 218.254 178.182L232.489 161.539C234.751 158.893 237.972 157.255 241.443 156.984L289.556 153.231C296.783 152.667 303.099 158.068 303.662 165.295L305.494 188.772C306.058 195.998 300.656 202.314 293.429 202.878L245.316 206.631ZM238.758 178.448C236.555 176.564 233.241 176.822 231.356 179.026C229.471 181.229 229.73 184.543 231.933 186.428C234.137 188.312 237.451 188.054 239.336 185.85C241.22 183.647 240.962 180.333 238.758 178.448Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-9.94753 128.698C-11.608 130.359 -13.8601 131.292 -16.2084 131.292H-30.9827C-33.9167 131.292 -36.2952 128.913 -36.2952 125.979V111.205C-36.2952 108.857 -35.3623 106.604 -33.7019 104.944L-10.681 81.9231C-7.22326 78.4654 -1.61712 78.4654 1.84065 81.9231L13.0733 93.1558C16.5311 96.6136 16.5311 102.22 13.0733 105.677L-9.94753 128.698ZM-25.6667 117.125C-27.6227 117.125 -29.2084 118.711 -29.2084 120.667C-29.2084 122.623 -27.6227 124.208 -25.6667 124.208C-23.7107 124.208 -22.1251 122.623 -22.1251 120.667C-22.1251 118.711 -23.7107 117.125 -25.6667 117.125Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.364 106.987C340.705 106.804 343.023 107.559 344.807 109.085L356.035 118.688C358.265 120.595 358.527 123.948 356.62 126.178L347.017 137.406C345.491 139.191 343.318 140.296 340.977 140.479L308.519 143.011C303.644 143.391 299.383 139.747 299.003 134.872L297.767 119.035C297.387 114.159 301.031 109.899 305.906 109.519L338.364 106.987ZM342.788 125.999C344.274 127.27 346.51 127.096 347.781 125.609C349.052 124.123 348.878 121.887 347.391 120.616C345.905 119.344 343.669 119.519 342.398 121.005C341.127 122.492 341.301 124.727 342.788 125.999Z"
        fill="#685BC7"
        fillOpacity="0.2"
      />
      <path
        d="M22.9883 164.016C22.9883 159.1 24.6973 154.934 28.1152 151.516C31.5332 148.098 35.6999 146.389 40.6152 146.389C45.4329 146.389 49.5508 148.114 52.9688 151.564C56.3867 154.982 58.0957 159.133 58.0957 164.016C58.0957 168.866 56.3867 173.016 52.9688 176.467C49.5508 179.885 45.4329 181.594 40.6152 181.594C35.7324 181.594 31.5658 179.885 28.1152 176.467C24.6973 173.049 22.9883 168.898 22.9883 164.016ZM61.8066 207.082C61.8066 202.199 63.5156 198.049 66.9336 194.631C70.3516 191.213 74.502 189.504 79.3848 189.504C84.3001 189.504 88.4668 191.213 91.8848 194.631C95.3027 198.016 97.0117 202.167 97.0117 207.082C97.0117 211.965 95.2865 216.132 91.8359 219.582C88.418 223 84.2676 224.709 79.3848 224.709C74.5345 224.709 70.3841 222.984 66.9336 219.533C63.5156 216.083 61.8066 211.932 61.8066 207.082ZM34.3652 157.766C32.6725 159.491 31.8262 161.574 31.8262 164.016C31.8262 166.457 32.6725 168.524 34.3652 170.217C36.0579 171.877 38.1413 172.707 40.6152 172.707C43.0566 172.707 45.0911 171.877 46.7188 170.217C48.3789 168.524 49.209 166.457 49.209 164.016C49.209 161.574 48.3789 159.491 46.7188 157.766C45.0911 156.04 43.0566 155.178 40.6152 155.178C38.1413 155.178 36.0579 156.04 34.3652 157.766ZM73.1836 200.881C71.5234 202.574 70.6934 204.641 70.6934 207.082C70.6934 209.523 71.5234 211.59 73.1836 213.283C74.8763 214.976 76.9434 215.822 79.3848 215.822C81.8262 215.822 83.9095 214.976 85.6348 213.283C87.36 211.59 88.2227 209.523 88.2227 207.082C88.2227 204.641 87.36 202.574 85.6348 200.881C83.9095 199.156 81.8262 198.293 79.3848 198.293C76.9434 198.293 74.8763 199.156 73.1836 200.881ZM75.7227 148H85L44.3262 223H35L75.7227 148Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M336.289 58.7396C333.094 62.4751 329.087 64.5308 324.268 64.9068C319.449 65.2827 315.172 63.8733 311.436 60.6784C307.775 57.5471 305.767 53.5595 305.412 48.7157C305.036 43.8966 306.435 39.6316 309.609 35.9209C312.761 32.2349 316.758 30.1915 321.598 29.7909C326.417 29.4149 330.657 30.7926 334.319 33.924C338.029 37.0977 340.085 41.1046 340.486 45.9449C340.862 50.764 339.463 55.0289 336.289 58.7396ZM334.781 0.78014C331.607 4.49088 327.61 6.53423 322.791 6.91017C317.972 7.28612 313.707 5.88725 309.997 2.71357C306.261 -0.481268 304.205 -4.48823 303.83 -9.30733C303.432 -14.1017 304.831 -18.3666 308.026 -22.1021C311.2 -25.8128 315.219 -27.858 320.084 -28.2375C324.903 -28.6134 329.168 -27.2146 332.879 -24.0409C336.565 -20.8884 338.598 -16.8796 338.977 -12.0146C339.332 -7.17078 337.933 -2.90586 334.781 0.78014ZM323.581 56.0947C325.989 55.8838 327.986 54.8507 329.573 52.9953C331.16 51.1399 331.86 49.0189 331.674 46.6323C331.466 44.2705 330.423 42.2855 328.542 40.6775C326.687 39.0907 324.601 38.3992 322.285 38.6029C319.924 38.8103 317.949 39.8416 316.362 41.697C314.776 43.5524 314.052 45.6751 314.193 48.0653C314.308 50.4343 315.294 52.4123 317.149 53.9991C319.029 55.6071 321.173 56.3056 323.581 56.0947ZM322.104 -1.90189C324.466 -2.10923 326.44 -3.14058 328.027 -4.99596C329.614 -6.85133 330.327 -8.96173 330.165 -11.3272C329.979 -13.7138 328.958 -15.7005 327.103 -17.2873C325.247 -18.8742 323.114 -19.5851 320.703 -19.4201C318.291 -19.255 316.292 -18.2448 314.705 -16.3895C313.119 -14.5341 312.431 -12.4025 312.642 -9.99477C312.831 -7.56228 313.854 -5.55261 315.709 -3.96577C317.565 -2.37893 319.696 -1.69097 322.104 -1.90189ZM285.803 36.6351L278.753 30.6052L358.411 0.0448901L365.499 6.10662L285.803 36.6351Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M87.293 23.6094C87.293 20.6602 88.3184 18.1602 90.3691 16.1094C92.4199 14.0586 94.9199 13.0332 97.8691 13.0332C100.76 13.0332 103.23 14.0684 105.281 16.1387C107.332 18.1895 108.357 20.6797 108.357 23.6094C108.357 26.5195 107.332 29.0098 105.281 31.0801C103.23 33.1309 100.76 34.1562 97.8691 34.1562C94.9395 34.1562 92.4395 33.1309 90.3691 31.0801C88.3184 29.0293 87.293 26.5391 87.293 23.6094ZM110.584 49.4492C110.584 46.5195 111.609 44.0293 113.66 41.9785C115.711 39.9277 118.201 38.9023 121.131 38.9023C124.08 38.9023 126.58 39.9277 128.631 41.9785C130.682 44.0098 131.707 46.5 131.707 49.4492C131.707 52.3789 130.672 54.8789 128.602 56.9492C126.551 59 124.061 60.0254 121.131 60.0254C118.221 60.0254 115.73 58.9902 113.66 56.9199C111.609 54.8496 110.584 52.3594 110.584 49.4492ZM94.1191 19.8594C93.1035 20.8945 92.5957 22.1445 92.5957 23.6094C92.5957 25.0742 93.1035 26.3145 94.1191 27.3301C95.1348 28.3262 96.3848 28.8242 97.8691 28.8242C99.334 28.8242 100.555 28.3262 101.531 27.3301C102.527 26.3145 103.025 25.0742 103.025 23.6094C103.025 22.1445 102.527 20.8945 101.531 19.8594C100.555 18.8242 99.334 18.3066 97.8691 18.3066C96.3848 18.3066 95.1348 18.8242 94.1191 19.8594ZM117.41 45.7285C116.414 46.7441 115.916 47.9844 115.916 49.4492C115.916 50.9141 116.414 52.1543 117.41 53.1699C118.426 54.1855 119.666 54.6934 121.131 54.6934C122.596 54.6934 123.846 54.1855 124.881 53.1699C125.916 52.1543 126.434 50.9141 126.434 49.4492C126.434 47.9844 125.916 46.7441 124.881 45.7285C123.846 44.6934 122.596 44.1758 121.131 44.1758C119.666 44.1758 118.426 44.6934 117.41 45.7285ZM118.934 14H124.5L100.096 59H94.5L118.934 14Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M196.16 123.647C194.243 125.888 191.839 127.121 188.948 127.347C186.056 127.572 183.49 126.727 181.249 124.81C179.052 122.931 177.847 120.539 177.634 117.632C177.409 114.741 178.248 112.182 180.152 109.955C182.044 107.744 184.442 106.518 187.346 106.277C190.237 106.052 192.781 106.878 194.978 108.757C197.204 110.661 198.438 113.066 198.678 115.97C198.904 118.861 198.065 121.42 196.16 123.647ZM195.255 88.8709C193.351 91.0974 190.953 92.3234 188.062 92.549C185.17 92.7745 182.611 91.9352 180.385 90.031C178.144 88.1141 176.91 85.7099 176.685 82.8185C176.446 79.9418 177.286 77.3829 179.203 75.1416C181.107 72.9152 183.518 71.6881 186.437 71.4604C189.329 71.2348 191.888 72.0741 194.114 73.9783C196.326 75.8698 197.545 78.2751 197.773 81.1941C197.986 84.1004 197.147 86.6593 195.255 88.8709ZM188.535 122.06C189.98 121.933 191.178 121.313 192.13 120.2C193.083 119.087 193.503 117.814 193.391 116.382C193.267 114.965 192.64 113.774 191.512 112.809C190.399 111.857 189.148 111.442 187.758 111.565C186.341 111.689 185.156 112.308 184.204 113.421C183.252 114.534 182.818 115.808 182.902 117.242C182.972 118.663 183.563 119.85 184.676 120.802C185.804 121.767 187.091 122.186 188.535 122.06ZM187.649 87.2617C189.066 87.1373 190.251 86.5185 191.203 85.4053C192.155 84.2921 192.583 83.0258 192.486 81.6066C192.374 80.1746 191.762 78.9826 190.649 78.0305C189.535 77.0784 188.255 76.6518 186.809 76.7508C185.362 76.8498 184.162 77.456 183.21 78.5692C182.258 79.6824 181.845 80.9613 181.972 82.406C182.086 83.8655 182.699 85.0713 183.812 86.0234C184.926 86.9755 186.205 87.3883 187.649 87.2617ZM165.869 110.384L161.639 106.766L209.434 88.4298L213.686 92.0668L165.869 110.384Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M41.3965 76.3047C41.3965 74.8301 41.9092 73.5801 42.9346 72.5547C43.96 71.5293 45.21 71.0166 46.6846 71.0166C48.1299 71.0166 49.3652 71.5342 50.3906 72.5693C51.416 73.5947 51.9287 74.8398 51.9287 76.3047C51.9287 77.7598 51.416 79.0049 50.3906 80.04C49.3652 81.0654 48.1299 81.5781 46.6846 81.5781C45.2197 81.5781 43.9697 81.0654 42.9346 80.04C41.9092 79.0146 41.3965 77.7695 41.3965 76.3047ZM53.042 89.2246C53.042 87.7598 53.5547 86.5146 54.5801 85.4893C55.6055 84.4639 56.8506 83.9512 58.3154 83.9512C59.79 83.9512 61.04 84.4639 62.0654 85.4893C63.0908 86.5049 63.6035 87.75 63.6035 89.2246C63.6035 90.6895 63.0859 91.9395 62.0508 92.9746C61.0254 94 59.7803 94.5127 58.3154 94.5127C56.8604 94.5127 55.6152 93.9951 54.5801 92.96C53.5547 91.9248 53.042 90.6797 53.042 89.2246ZM44.8096 74.4297C44.3018 74.9473 44.0479 75.5723 44.0479 76.3047C44.0479 77.0371 44.3018 77.6572 44.8096 78.165C45.3174 78.6631 45.9424 78.9121 46.6846 78.9121C47.417 78.9121 48.0273 78.6631 48.5156 78.165C49.0137 77.6572 49.2627 77.0371 49.2627 76.3047C49.2627 75.5723 49.0137 74.9473 48.5156 74.4297C48.0273 73.9121 47.417 73.6533 46.6846 73.6533C45.9424 73.6533 45.3174 73.9121 44.8096 74.4297ZM56.4551 87.3643C55.957 87.8721 55.708 88.4922 55.708 89.2246C55.708 89.957 55.957 90.5771 56.4551 91.085C56.9629 91.5928 57.583 91.8467 58.3154 91.8467C59.0479 91.8467 59.6729 91.5928 60.1904 91.085C60.708 90.5771 60.9668 89.957 60.9668 89.2246C60.9668 88.4922 60.708 87.8721 60.1904 87.3643C59.6729 86.8467 59.0479 86.5879 58.3154 86.5879C57.583 86.5879 56.9629 86.8467 56.4551 87.3643ZM57.2168 71.5H60L47.7979 94H45L57.2168 71.5Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
      <path
        d="M265.29 113.432C264.332 114.552 263.13 115.169 261.684 115.282C260.238 115.395 258.955 114.972 257.834 114.013C256.736 113.074 256.134 111.878 256.027 110.424C255.914 108.979 256.334 107.699 257.286 106.586C258.232 105.48 259.431 104.867 260.883 104.747C262.329 104.634 263.601 105.048 264.699 105.987C265.812 106.939 266.429 108.141 266.549 109.593C266.662 111.039 266.242 112.318 265.29 113.432ZM264.838 96.0438C263.886 97.157 262.687 97.77 261.241 97.8828C259.795 97.9956 258.516 97.5759 257.402 96.6238C256.282 95.6653 255.665 94.4633 255.552 93.0175C255.433 91.5792 255.853 90.2997 256.811 89.1791C257.763 88.0659 258.969 87.4523 260.429 87.3385C261.874 87.2257 263.154 87.6454 264.267 88.5975C265.373 89.5432 265.983 90.7458 266.097 92.2053C266.203 93.6585 265.783 94.938 264.838 96.0438ZM261.478 112.638C262.2 112.575 262.799 112.265 263.275 111.708C263.751 111.152 263.961 110.515 263.906 109.799C263.843 109.091 263.53 108.495 262.966 108.013C262.41 107.537 261.784 107.329 261.089 107.391C260.381 107.453 259.788 107.762 259.312 108.319C258.836 108.875 258.619 109.512 258.661 110.229C258.696 110.94 258.992 111.533 259.548 112.009C260.112 112.492 260.755 112.701 261.478 112.638ZM261.035 95.2392C261.743 95.177 262.335 94.8676 262.812 94.3109C263.288 93.7543 263.501 93.1212 263.453 92.4116C263.397 91.6956 263.091 91.0996 262.534 90.6235C261.978 90.1475 261.338 89.9342 260.614 89.9837C259.891 90.0332 259.291 90.3363 258.815 90.8929C258.339 91.4495 258.133 92.089 258.196 92.8113C258.253 93.541 258.56 94.1439 259.116 94.62C259.673 95.096 260.312 95.3024 261.035 95.2392ZM250.144 106.8L248.029 104.991L271.927 95.8232L274.053 97.6417L250.144 106.8Z"
        fill="#4A25AA"
        fillOpacity="0.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_97_7890">
        <rect
          width={width}
          height={height}
          rx="5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
