import type { FC } from 'react';

import { Root } from './styles';
import type { BannerPlaceholderProps } from './types';

export const BannerPlaceholder: FC<BannerPlaceholderProps> = ({ alignment, image, bannerOrientation }) => (
  <Root
    alignment={alignment}
    bannerOrientation={bannerOrientation}
  >
    {image}
  </Root>
);
