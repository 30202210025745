import { gql } from '@ori/fetching';

export const BENEFIT_BANNER_FRAGMENT = gql`
  fragment BenefitBanner on Frontpage {
    benefitBanner {
      messageTitle
      messageUrl
    }
  }
`;
