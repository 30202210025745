import { styled } from '@ori-ui/mui';

import { BRAND_DESKTOP_WIDTH, BRAND_MOBILE_WIDTH } from './constants';

export const Root = styled('img')(({ theme }) => ({
  borderRadius: '50%',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  maxHeight: BRAND_MOBILE_WIDTH,
  maxWidth: BRAND_MOBILE_WIDTH,

  [theme.breakpoints.up('sm')]: {
    maxHeight: BRAND_DESKTOP_WIDTH,
    maxWidth: BRAND_DESKTOP_WIDTH,
  },
}));
