import type { FrontpageContext } from '../../contexts';
import { useFrontpage } from '../../contexts';

export type UseGetNotificationBannerApi = Pick<FrontpageContext, 'notificationBanner'>;

export const useGetNotificationBanner = () => {
  const { notificationBanner } = useFrontpage();

  return { notificationBanner };
};
