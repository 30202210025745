import { useToken } from '@ori/auth';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerWithProductsAndButton } from '../../components';
import { BANNER_ROOT_PLACEHOLDER_LEFT } from '../../constants';
import { useFrontpage } from '../../contexts';
import { useTranslations } from '../../hooks';
import { WhatsNewPlaceholder } from '../../images';
import { WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY, WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY_MOBILE } from './constants';

export const WhatsNewSection: FC = () => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const {
    whatsNew: { banner, productBoxes, shouldRender },
    hideRating,
  } = useFrontpage();
  const { customerId } = useToken();

  if (!shouldRender) {
    return null;
  }

  return (
    <div>
      <BannerWithProductsAndButton
        header={{ title: translations.whatsNew }}
        button={{
          title: translations.viewAll,
          href: '/new',
          alignment: 'right',
        }}
        banner={{
          banner,
          alignment: 'left',
          bannerOrientation: 'landscape',
        }}
        bannerPlaceholder={{
          image: <WhatsNewPlaceholder testId={getTestId(BANNER_ROOT_PLACEHOLDER_LEFT)} />,
        }}
        products={{
          hideRating,
          maxProductsToDisplay: WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY,
          maxProductsToDisplayMobile: WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
          productBoxes,
          customerId,
        }}
      />
    </div>
  );
};
