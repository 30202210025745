import { DefaultLayoutPage, type DefaultLayoutPageProps } from '@ori-appshell/layouts';
import { SWRConfig } from '@ori/fetching';
import { LoggerProvider } from '@ori/logger';
import { type UserSegmentationDataProviderProps } from '@ori/presentation-http';
import { mainLogger } from '~/logger';

import { SegmentationProvider } from '../SegmentationProvider/SegmentationProvider';
import type { FrontPageContentProps } from './FrontPageContent';
import { FrontPageContent } from './FrontPageContent';

export type FrontPageProps = Pick<UserSegmentationDataProviderProps, 'atpClusterId' | 'customerTypeId'> &
  DefaultLayoutPageProps &
  FrontPageContentProps;

export function FrontPage({ data, hideRating, layout, page, atpClusterId, customerTypeId }: FrontPageProps) {
  return (
    <DefaultLayoutPage
      page={page}
      layout={layout}
    >
      <LoggerProvider logger={mainLogger}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            shouldRetryOnError: false,
          }}
        >
          <SegmentationProvider
            atpClusterId={atpClusterId}
            customerTypeId={customerTypeId}
          >
            <FrontPageContent
              data={data}
              hideRating={hideRating}
            />
          </SegmentationProvider>
        </SWRConfig>
      </LoggerProvider>
    </DefaultLayoutPage>
  );
}
