import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BenefitsList, Root } from '../VipBenefitsSection/styles';
import { VipBenefitSkeleton } from './VipBenefitSkeleton';
import { VIP_BENEFITS_SECTION_SKELETON } from './constants';
import { ButtonSkeleton } from './styles';

export const VipBenefitsSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(VIP_BENEFITS_SECTION_SKELETON)}>
      <BenefitsList>
        <VipBenefitSkeleton />
        <VipBenefitSkeleton />
        <VipBenefitSkeleton />
      </BenefitsList>
      <ButtonSkeleton width={200} />
    </Root>
  );
};
