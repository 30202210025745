import type { SessionStorageLike } from './SessionStorageLike';

export class SessionStoreRepository {
  protected storage: SessionStorageLike;
  constructor(storage: SessionStorageLike) {
    this.storage = storage;
  }

  get<T>(name: string): T | null {
    const item = this.storage.getItem(name);

    if (item === null) {
      return null;
    }

    return this.deserialize<T>(item);
  }

  getOrCreate<T>(name: string, defaultValue: T): T {
    const item = this.get<T>(name);

    if (item !== null) {
      return item;
    }

    return this.save(name, defaultValue);
  }

  save<T>(name: string, value: T): T {
    this.storage.setItem(name, this.serialize(value));

    return value;
  }

  has(name: string): boolean {
    return this.get(name) !== null;
  }

  delete(name: string): this {
    this.storage.removeItem(name);

    return this;
  }

  serialize<T>(data: T): string {
    return JSON.stringify(data);
  }

  deserialize<T>(data: string): T | null {
    try {
      return JSON.parse(data) as T;
    } catch (error) {
      return null;
    }
  }
}
