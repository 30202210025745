import { gql } from '@ori/fetching';

export const CATEGORY_BANNERS_FRAGMENT = gql`
  fragment CategoryBanners on CategoryBanner {
    headline
    shortText
    imageUrl
    fontColor
    mobileFontColor
    link {
      title
      relativeUrl
    }
    tagline
    validFrom
    validTo
  }
`;
