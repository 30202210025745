import useTestId from '@ori/testid-generator';

import { VIEW_MORE_BUTTON_LEFT, VIEW_MORE_BUTTON_RIGHT } from '../../../../constants';
import { Root } from './styles';
import type { ViewMoreButtonProps } from './types';

export const ViewMoreButton = ({ href, color, title, variant, alignment }: ViewMoreButtonProps) => {
  const { getTestId } = useTestId();

  return (
    <Root
      data-testid={getTestId(alignment === 'left' ? VIEW_MORE_BUTTON_LEFT : VIEW_MORE_BUTTON_RIGHT)}
      alignment={alignment}
      href={href}
      color={color ?? 'default'}
      variant={variant ?? 'outlined'}
    >
      {title}
    </Root>
  );
};
