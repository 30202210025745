import { mapProductBoxesFromGql } from '@ori/product-box';

import type { BestOffers, GetFrontPageQuery } from '../models';

export const shouldRenderBestOffers = ({ productBoxes }: Pick<BestOffers, 'productBoxes'>): boolean =>
  productBoxes.length > 0;

export const getBestOffers = (data: GetFrontPageQuery): BestOffers => {
  const [banner] = data.application?.frontpage?.digitalPromotions?.bestOffersBanners ?? [];
  const productBoxes = mapProductBoxesFromGql([
    ...(data.application?.frontpage?.digitalPromotions?.bestOffersProducts ?? []),
  ]);

  return {
    banner: banner ?? null,
    productBoxes,
    shouldRender: shouldRenderBestOffers({ productBoxes }),
  };
};
