import { gql } from '@ori/fetching';

export const VIP_CUSTOMER_BANNER_FRAGMENT = gql`
  fragment VipCustomerBanner on Query {
    vipCustomerBanner {
      linkTitle
      linkUrl
    }
  }
`;

export const vipCustomerBannerQuery = gql`
  ${VIP_CUSTOMER_BANNER_FRAGMENT}
  query GetVipCustomerBanner {
    ...VipCustomerBanner
  }
`;
