import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../../components';
import {
  PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE,
  PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE,
} from '../ProductStandardsSection/components/Image/constants';

export const SPACE_SIZE = 15;
export const WIDTH_WITH_SPACE = `calc(100% - ${SPACE_SIZE * 2}px)`;

export const TitleSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  maxWidth: 400,
  width: WIDTH_WITH_SPACE,
  margin: `0 ${SPACE_SIZE}px`,
  height: theme.spacing(10),
}));

export const DescriptionSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  maxWidth: 600,
  width: WIDTH_WITH_SPACE,
  height: 105,
  margin: `0 ${SPACE_SIZE}px 0`,
}));

export const ItemsWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  justifyContent: 'center',
  gap: theme.spacing(4, 1),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(4, 4),
  },
}));

export const ItemSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  width: PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE,
  height: PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE,
  [theme.breakpoints.up('sm')]: {
    width: PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE,
    height: PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE,
  },
}));
