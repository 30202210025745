import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useFrontpage } from '../../contexts';
import { Image } from './components';
import {
  PRODUCT_STANDARDS_SECTION,
  PRODUCT_STANDARDS_SECTION_DESCRIPTION,
  PRODUCT_STANDARDS_SECTION_IMAGES,
  PRODUCT_STANDARDS_SECTION_TITLE,
} from './constants';
import { Description, DescriptionWrapper, ImagesWrapper, Root, TitleWrapper, Wrapper } from './styles';

export const ProductStandardsSection: FC = () => {
  const { getTestId } = useTestId();
  const {
    productStandards: { headline, images, text },
  } = useFrontpage();

  return (
    <Root data-testid={getTestId(PRODUCT_STANDARDS_SECTION)}>
      <Wrapper>
        <TitleWrapper data-testid={getTestId(PRODUCT_STANDARDS_SECTION_TITLE)}>
          <Typography
            variant="h3"
            component="h3"
            textAlign="center"
            color="textPrimary"
          >
            {headline}
          </Typography>
        </TitleWrapper>
        <DescriptionWrapper data-testid={getTestId(PRODUCT_STANDARDS_SECTION_DESCRIPTION)}>
          <Description>{text}</Description>
        </DescriptionWrapper>
        {images.length > 0 ? (
          <ImagesWrapper data-testid={getTestId(PRODUCT_STANDARDS_SECTION_IMAGES)}>
            {images.map((image) => (
              <Image
                key={image.title}
                {...image}
              />
            ))}
          </ImagesWrapper>
        ) : null}
      </Wrapper>
    </Root>
  );
};
