import { alpha, Button, styled, Typography, type TypographyProps } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  alignContent: 'center',
  background: alpha(theme.palette.grey[100], 0.6),
  color: theme.palette.text.primary,
  display: 'flex',
  flex: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(6, 3),
  textAlign: 'center',
  borderRadius: theme.spacing(0, 1, 1, 0),
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    background: theme.palette.grey[100],
    flex: 1,
    padding: theme.spacing(6, 16, 6, 16),
    textAlign: 'left',
  },
}));

export const Title = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  fontSize: '3rem',
  textAlign: 'center',
  lineHeight: 1.235,
  [theme.breakpoints.up('md')]: {
    justifySelf: 'center',
    textAlign: 'center',
    fontSize: '3rem',
    lineHeight: 1.35,
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: '4rem',
    textAlign: 'left',
  },
}));

export const Description = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  justifySelf: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('lg')]: {
    justifySelf: 'start',
    textAlign: 'start',
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  justifySelf: 'center',
  textAlign: 'center',

  [theme.breakpoints.up('lg')]: {
    justifySelf: 'start',
    textAlign: 'start',
  },
}));

export const StyledButton = styled(Button)({
  height: '45px',
  textTransform: 'uppercase',
});
