import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { memo } from 'react';

import { BannerWithProductsAndButtonSkeleton } from '../../components';
import {
  WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY,
  WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
} from '../WhatsNewSection/constants';
import { WHATS_NEW_SECTION_SKELETON } from './constants';

export const WhatsNewSectionSkeleton: FC = memo(() => {
  const { getTestId } = useTestId();

  return (
    <BannerWithProductsAndButtonSkeleton
      testId={getTestId(WHATS_NEW_SECTION_SKELETON)}
      button={{
        alignment: 'right',
      }}
      banner={{
        bannerOrientation: 'landscape',
        alignment: 'left',
      }}
      products={{
        maxProductsToDisplay: WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY,
        maxProductsToDisplayMobile: WHATS_NEW_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
      }}
    />
  );
});
