import { capitalize, useTheme } from '@ori-ui/mui';
import { getImageVariants, generateSrcSet } from '@ori/image-sizes-helper';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useFrontpage } from '../../contexts';
import { CategoryListingPlaceholder } from '../../images';
import { onImageError } from '../../utils';
import {
  CAMPAIGN_BANNER_IMAGE,
  CAMPAIGN_BANNER_IMAGE_DESKTOP_WIDTH,
  CAMPAIGN_BANNER_IMAGE_IMAGE,
  CAMPAIGN_BANNER_IMAGE_PLACEHOLDER,
} from './constants';
import { Image, ImagePlaceholder, ImageWrapper, Root, Text } from './styles';

export const CampaignBannerImage: FC = () => {
  const theme = useTheme();
  const { getTestId } = useTestId();
  const { campaignBanner } = useFrontpage();
  const { tagline, imageUrl, fontColor, mobileFontColor } = campaignBanner ?? {};

  const srcSet = imageUrl
    ? generateSrcSet(
        getImageVariants({
          url: imageUrl,
          backgroundColor: theme.palette.grey[100],
          imageFormat: 'WebP',
          params: [
            {
              width: theme.breakpoints.values.sm,
              quality: 90,
            },
            {
              width: CAMPAIGN_BANNER_IMAGE_DESKTOP_WIDTH,
              quality: 90,
            },
          ],
        }),
      )
    : undefined;

  return (
    <Root data-testid={getTestId(CAMPAIGN_BANNER_IMAGE)}>
      <ImageWrapper>
        {imageUrl ? (
          <Image
            alt={tagline ?? ''}
            data-testid={getTestId(CAMPAIGN_BANNER_IMAGE_IMAGE)}
            sizes={`(max-width: ${theme.breakpoints.values.sm - 1}px) ${theme.breakpoints.values.sm}px, ${CAMPAIGN_BANNER_IMAGE_DESKTOP_WIDTH}px`}
            srcSet={srcSet}
            onError={onImageError}
          />
        ) : null}
        <ImagePlaceholder>
          <CategoryListingPlaceholder testId={getTestId(CAMPAIGN_BANNER_IMAGE_PLACEHOLDER)} />
        </ImagePlaceholder>
      </ImageWrapper>

      {tagline && imageUrl ? (
        <Text
          variant="h4"
          component="h4"
          desktopColor={mapBackendColorToTheme({
            theme,
            backendColor: capitalize(fontColor ?? ''),
            fallBackColor: 'inherit',
          })}
          mobileColor={mapBackendColorToTheme({
            theme,
            backendColor: capitalize(mobileFontColor ?? ''),
            fallBackColor: 'inherit',
          })}
        >
          {tagline}
        </Text>
      ) : null}
    </Root>
  );
};
