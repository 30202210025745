/* istanbul ignore file */
/**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */

export enum TranslationKey {
  bestOffers = 'bestOffers',
  bestSellers = 'bestSellers',
  close = 'close',
  favorites = 'favorites',
  iconicProducts = 'iconicProducts',
  play = 'play',
  quickBuy = 'quickBuy',
  viewAll = 'viewAll',
  viewAllBrands = 'viewAllBrands',
  viewOffers = 'viewOffers',
  whatsNew = 'whatsNew',
}
