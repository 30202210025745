import { gql } from '@ori/fetching';

export const ICONIC_PRODUCTS_FRAGMENT = gql`
  fragment IconicProducts on Frontpage {
    iconicProducts {
      headline
      description
      banner {
        ...ProductPromotionBanner
      }
      products {
        ...ProductWithShades
      }
    }
  }
`;
