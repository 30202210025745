import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerWithProductsAndButtonSkeleton } from '../../components';
import {
  CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY,
  CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
} from '../CategoryListingSection/constants';
import { CATEGORY_LISTING_SKELETON } from './constants';

export const CategoryListingSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <BannerWithProductsAndButtonSkeleton
      testId={getTestId(CATEGORY_LISTING_SKELETON)}
      header={{
        hasDescription: true,
      }}
      button={{
        alignment: 'right',
      }}
      banner={{
        alignment: 'left',
        bannerOrientation: 'landscape',
      }}
      products={{
        maxProductsToDisplay: CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY,
        maxProductsToDisplayMobile: CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
      }}
    />
  );
};
