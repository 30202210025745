// istanbul ignore file -- Not able to test content of next/head
import { useTheme } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import Head from 'next/head';
import { useMemo } from 'react';
import type { FC } from 'react';

import { useFrontpage } from '../../contexts';
import { getMediaQueryForRange } from '../../utils';
import { HERO_BANNER_METADATA_IMAGE } from './constants';
import { getImagesInfo } from './utils';

export const HeroBannerMetadata: FC = () => {
  const { getTestId } = useTestId();
  const theme = useTheme();
  const { heroBanners } = useFrontpage();

  const imagesInfo = useMemo(() => getImagesInfo({ heroBanners, theme }), [heroBanners, theme]);

  return (
    <Head>
      {imagesInfo.map(({ url, from, to }) => (
        <link
          key={url}
          rel="preload"
          as="image"
          href={url}
          media={getMediaQueryForRange({ from, to })}
          data-testid={getTestId(HERO_BANNER_METADATA_IMAGE)}
          fetchPriority="high"
        />
      ))}
    </Head>
  );
};
