import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { NOTIFICATION_BANNER_LINK_AREA } from './constants';
import { LinkArea } from './styles';
import type { NotificationBannerProps } from './types';

export type NotificationBannerLinkAreaProps = Pick<
  NotificationBannerProps,
  'messageLinkTitle' | 'messageLinkUrl' | 'messageText'
>;
export const NotificationBannerLinkArea: FC<NotificationBannerLinkAreaProps> = ({
  messageLinkUrl,
  messageText,
  messageLinkTitle,
}) => {
  const { getTestId } = useTestId();

  return (
    <LinkArea
      data-testid={getTestId(NOTIFICATION_BANNER_LINK_AREA)}
      href={messageLinkUrl}
      target="_blank"
      underline="hover"
    >
      <Typography>{messageText}</Typography>
      <Typography>{messageLinkTitle}</Typography>
    </LinkArea>
  );
};
