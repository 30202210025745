import { SliderSkeleton, type OlapicLibConfiguration } from '@ori/olapic-lib';
import dynamic from 'next/dynamic';

export const LazyOlapicWidget = dynamic<OlapicLibConfiguration>(
  async () => import('@ori/olapic-lib').then((module) => module.OlapicWidget),
  {
    ssr: true,
    loading: () => <SliderSkeleton />,
  },
);
