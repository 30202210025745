/* istanbul ignore file */

/**
  * DO NOT EDIT!
  * This file was autogenerated by graphql-codegen.
  * Changes may cause incorrect behavior and will be lost when the code is regenerated.
  *
  * Run `npx run codegen` to regenerate.
  */


export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: string; output: string; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: unknown; output: unknown; }
  JSON: { input: Record<string, any>; output: Record<string, any>; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: unknown; output: unknown; }
  Void: { input: void; output: void; }
};

export type AddThis = {
  readonly __typename?: 'AddThis';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly services?: Maybe<ReadonlyArray<Maybe<AddThisService>>>;
  readonly sharedUrl?: Maybe<Scalars['String']['output']>;
};

export type AddThisService = {
  readonly __typename?: 'AddThisService';
  readonly cssClassName?: Maybe<Scalars['String']['output']>;
  readonly jsClassName?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  readonly __typename?: 'Address';
  readonly addressId: Scalars['ID']['output'];
  readonly addressType?: Maybe<AddressType>;
  readonly area?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly coordinates?: Maybe<Coordinates>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly delivery?: Maybe<Scalars['String']['output']>;
  readonly delivery2?: Maybe<Scalars['String']['output']>;
  readonly delivery3?: Maybe<Scalars['String']['output']>;
  readonly district?: Maybe<Scalars['String']['output']>;
  readonly isPrimary?: Maybe<Scalars['Boolean']['output']>;
  readonly locality?: Maybe<Scalars['String']['output']>;
  readonly province?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadata = {
  readonly __typename?: 'AddressMetadata';
  readonly addressType?: Maybe<AddressMetadataType>;
  readonly area?: Maybe<AddressMetadataItem>;
  readonly city?: Maybe<AddressMetadataItem>;
  readonly country?: Maybe<CountryMetadataItem>;
  readonly delivery?: Maybe<AddressMetadataDelivery>;
  readonly delivery2?: Maybe<AddressMetadataDelivery>;
  readonly delivery3?: Maybe<AddressMetadataDelivery>;
  readonly district?: Maybe<AddressMetadataItem>;
  readonly isPrimary?: Maybe<IsPrimaryMetadataItem>;
  readonly kycOperations?: Maybe<MetadataOperations>;
  readonly locality?: Maybe<AddressMetadataItem>;
  readonly operations?: Maybe<AddressMetadataOperations>;
  readonly province?: Maybe<AddressMetadataItem>;
  readonly region?: Maybe<AddressMetadataItem>;
  readonly searchOptions?: Maybe<AddressMetadataSearchOptions>;
  readonly streetAddress?: Maybe<AddressMetadataItem>;
  readonly zipcode?: Maybe<AddressMetadataItem>;
};

export enum AddressMetadataAllowedSearchResults {
  Area = 'Area',
  City = 'City',
  Country = 'Country',
  District = 'District',
  Locality = 'Locality',
  Market = 'Market',
  Province = 'Province',
  Region = 'Region',
  Street = 'Street',
  ZipCode = 'ZipCode'
}

export type AddressMetadataDelivery = {
  readonly __typename?: 'AddressMetadataDelivery';
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  readonly validation?: Maybe<AddressMetadataDeliveryValidation>;
};

export type AddressMetadataDeliveryValidation = {
  readonly __typename?: 'AddressMetadataDeliveryValidation';
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
  readonly maxLength?: Maybe<Scalars['Int']['output']>;
  readonly minLength?: Maybe<Scalars['Int']['output']>;
  readonly regularExpression?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadataItem = {
  readonly __typename?: 'AddressMetadataItem';
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  readonly validation?: Maybe<AddressMetadataValidation>;
};

export type AddressMetadataOperation = {
  readonly __typename?: 'AddressMetadataOperation';
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly reasons?: Maybe<ReadonlyArray<Maybe<AddressMetadataOperationReason>>>;
};

export type AddressMetadataOperationReason = {
  readonly __typename?: 'AddressMetadataOperationReason';
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadataOperations = {
  readonly __typename?: 'AddressMetadataOperations';
  readonly create?: Maybe<AddressMetadataOperation>;
  readonly delete?: Maybe<AddressMetadataOperation>;
  readonly deletePrimary?: Maybe<AddressMetadataOperation>;
  readonly read?: Maybe<AddressMetadataOperation>;
  readonly update?: Maybe<AddressMetadataOperation>;
};

export type AddressMetadataSearchOptions = {
  readonly __typename?: 'AddressMetadataSearchOptions';
  readonly allowedSearchResults?: Maybe<ReadonlyArray<AddressMetadataAllowedSearchResults>>;
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly maxResults?: Maybe<Scalars['Int']['output']>;
};

export type AddressMetadataType = {
  readonly __typename?: 'AddressMetadataType';
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  readonly validation?: Maybe<AddressMetadataTypeValidation>;
};

export type AddressMetadataTypeValidation = {
  readonly __typename?: 'AddressMetadataTypeValidation';
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
};

export type AddressMetadataValidation = {
  readonly __typename?: 'AddressMetadataValidation';
  readonly isAddressMatchRequired?: Maybe<Scalars['Boolean']['output']>;
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
  readonly maxLength?: Maybe<Scalars['Int']['output']>;
  readonly minLength?: Maybe<Scalars['Int']['output']>;
  readonly regularExpression?: Maybe<Scalars['String']['output']>;
};

export enum AddressType {
  Channel = 'Channel',
  Delivery = 'Delivery',
  Foreign = 'Foreign',
  Home = 'Home',
  Kyc = 'Kyc',
  Other = 'Other'
}

export type AppLink = {
  readonly __typename?: 'AppLink';
  readonly deepLink?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Scalars['String']['output']>;
};

export type Application = {
  readonly __typename?: 'Application';
  readonly bazaarvoiceConfiguration?: Maybe<BazaarvoiceConfiguration>;
  readonly breadcrumbLinks?: Maybe<BreadcrumbLinks>;
  readonly catalogueLandingPage?: Maybe<CatalogueLandingPage>;
  readonly editorialLandingPage?: Maybe<EditorialLandingPage>;
  readonly editorialLandingPages?: Maybe<ReadonlyArray<Maybe<EditorialLandingPage>>>;
  readonly editorialPage?: Maybe<EditorialPage>;
  readonly editorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  readonly feedbackBenefitBanners?: Maybe<ReadonlyArray<Maybe<FeedbackBenefitBanner>>>;
  readonly footer?: Maybe<Footer>;
  readonly frontpage?: Maybe<Frontpage>;
  readonly ingredientLibrary?: Maybe<IngredientLibrary>;
  readonly makeupTryOnConfiguration?: Maybe<MakeupTryOnConfiguration>;
  readonly navigation?: Maybe<NavigationCollection>;
  readonly navigations?: Maybe<ReadonlyArray<Maybe<NavigationCollection>>>;
  readonly olapic?: Maybe<OlapicConfiguration>;
  readonly pageNavigation?: Maybe<PageNavigation>;
  readonly pageNavigations?: Maybe<ReadonlyArray<Maybe<PageNavigation>>>;
  readonly permanentTopBanners?: Maybe<ReadonlyArray<Maybe<PermanentTopBanner>>>;
  readonly personalProductListConfiguration?: Maybe<PersonalProductListConfiguration>;
  readonly productDetailPage?: Maybe<ProductDetailPage>;
  readonly productListingPage?: Maybe<ProductListingPage>;
  readonly productSearchPage?: Maybe<ProductSearchPage>;
  readonly productSearchPreview?: Maybe<ProductSearchPreview>;
  readonly shareAndEarn?: Maybe<ShareAndEarn>;
  readonly sharingLinks?: Maybe<SharingLinks>;
  readonly videoShopping?: Maybe<VideoShopping>;
  readonly wellnessClub?: Maybe<WellnessClub>;
};


export type ApplicationBreadcrumbLinksArgs = {
  pageUrl?: InputMaybe<Scalars['String']['input']>;
};


export type ApplicationEditorialLandingPageArgs = {
  channelType?: EditorialChannelType;
  landingPageId?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationEditorialLandingPagesArgs = {
  channelType?: EditorialChannelType;
  landingPageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


export type ApplicationEditorialPageArgs = {
  channelType?: EditorialChannelType;
  pageId?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationEditorialPagesArgs = {
  channelType?: EditorialChannelType;
  pageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


export type ApplicationFeedbackBenefitBannersArgs = {
  bannerTypes?: InputMaybe<ReadonlyArray<FeedbackBenefitBannerType>>;
};


export type ApplicationNavigationArgs = {
  customerTypeId?: InputMaybe<Scalars['Int']['input']>;
  navigationType: NavigationType;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


export type ApplicationNavigationsArgs = {
  customerTypeId?: InputMaybe<Scalars['Int']['input']>;
  navigationTypes?: InputMaybe<ReadonlyArray<NavigationType>>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


export type ApplicationOlapicArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  olapicType: OlapicTypeEnum;
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationPageNavigationArgs = {
  pageId?: InputMaybe<Scalars['String']['input']>;
};


export type ApplicationPageNavigationsArgs = {
  pageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type ApplicationPermanentTopBannersArgs = {
  bannerType: PermanentTopBannerType;
};


export type ApplicationProductDetailPageArgs = {
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


export type ApplicationProductListingPageArgs = {
  pageId?: InputMaybe<Scalars['ID']['input']>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Approval = {
  readonly __typename?: 'Approval';
  readonly blockGuaranteeOrdering?: Maybe<Scalars['Boolean']['output']>;
  readonly contact?: Maybe<Scalars['Boolean']['output']>;
  readonly marketingConsultant?: Maybe<Scalars['Boolean']['output']>;
  readonly marketingExternal?: Maybe<Scalars['Boolean']['output']>;
  readonly marketingOriflame?: Maybe<Scalars['Boolean']['output']>;
  readonly newsletters?: Maybe<Scalars['Boolean']['output']>;
  readonly optedOutOfSponsorSelect?: Maybe<Scalars['Boolean']['output']>;
  readonly orderHistory?: Maybe<Scalars['Boolean']['output']>;
  readonly personalData?: Maybe<Scalars['Boolean']['output']>;
  readonly publication?: Maybe<Scalars['Boolean']['output']>;
  readonly uplineLeaderBrowse?: Maybe<Scalars['Boolean']['output']>;
  readonly uplineLeaderData?: Maybe<Scalars['Boolean']['output']>;
};

export type Article = {
  readonly __typename?: 'Article';
  readonly articleType?: Maybe<ArticleType>;
  readonly categoryTitle?: Maybe<Scalars['String']['output']>;
  readonly image?: Maybe<ArticleImage>;
  readonly introText?: Maybe<Scalars['String']['output']>;
  readonly isVideoArticle?: Maybe<Scalars['Boolean']['output']>;
  readonly link?: Maybe<Link>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly published?: Maybe<Scalars['DateTime']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ArticleImage = {
  readonly __typename?: 'ArticleImage';
  readonly alt?: Maybe<Scalars['String']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum ArticleType {
  BeautyEdit = 'BeautyEdit',
  RelatedToProduct = 'RelatedToProduct',
  Undefined = 'Undefined'
}

export type Attribute = {
  readonly __typename?: 'Attribute';
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type BackInStockAvailability = {
  readonly __typename?: 'BackInStockAvailability';
  readonly showBackInStockNotification?: Maybe<Scalars['Boolean']['output']>;
};

export enum BackInStockNotificationChannel {
  Email = 'Email',
  Sms = 'Sms'
}

export type BackInStockNotificationSubscriptionInput = {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly itemCode?: InputMaybe<Scalars['String']['input']>;
  readonly notificationChannel: BackInStockNotificationChannel;
  readonly telephoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum BackgroundType {
  Color = 'Color',
  Image = 'Image',
  None = 'None',
  Video = 'Video'
}

export type Bank = {
  readonly __typename?: 'Bank';
  readonly bankId?: Maybe<Scalars['Int']['output']>;
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly swiftBic?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = {
  readonly __typename?: 'BankAccount';
  readonly bank?: Maybe<Bank>;
  readonly bankAccountId: Scalars['ID']['output'];
  readonly bankBranch?: Maybe<BankBranch>;
  readonly bankSubBranch?: Maybe<Scalars['String']['output']>;
  readonly number?: Maybe<Scalars['String']['output']>;
  readonly owner?: Maybe<Scalars['String']['output']>;
};

export enum BankBonusPaymentTransferState {
  NothingForTransfer = 'NothingForTransfer',
  Pending = 'Pending',
  Transferred = 'Transferred',
  Undefined = 'Undefined'
}

export type BankBranch = {
  readonly __typename?: 'BankBranch';
  readonly bankBranchId?: Maybe<Scalars['Int']['output']>;
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type BazaarvoiceConfiguration = {
  readonly __typename?: 'BazaarvoiceConfiguration';
  readonly conversationsPlatformEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly dreamCatcherEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly dreamCatcherProductCode?: Maybe<Scalars['String']['output']>;
  readonly legalNoticeLink?: Maybe<Link>;
  readonly legalNoticeText?: Maybe<Scalars['String']['output']>;
  readonly questionsAndAnswersEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly questionsAndAnswersEnabledForSegments?: Maybe<Scalars['Boolean']['output']>;
  readonly ratingsAndReviewsEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly submissionClientScriptUrl?: Maybe<Scalars['String']['output']>;
  readonly submissionPageId?: Maybe<Scalars['String']['output']>;
  readonly transactionTrackingEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type BeautyEdit = {
  readonly __typename?: 'BeautyEdit';
  readonly editorialArticles?: Maybe<ReadonlyArray<Maybe<Article>>>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};


export type BeautyEditEditorialArticlesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type BeautyVideo = {
  readonly __typename?: 'BeautyVideo';
  readonly link?: Maybe<Link>;
  readonly video?: Maybe<Video>;
};

export type BenefitBanner = {
  readonly __typename?: 'BenefitBanner';
  readonly messageTitle?: Maybe<Scalars['String']['output']>;
  readonly messageUrl?: Maybe<Scalars['String']['output']>;
};

export type BestOffersBanner = {
  readonly __typename?: 'BestOffersBanner';
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type BestsellerProducts = {
  readonly __typename?: 'BestsellerProducts';
  readonly banner?: Maybe<ProductsPromotionBanner>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type Brand = {
  readonly __typename?: 'Brand';
  readonly brandId: Scalars['ID']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly logo?: Maybe<ReadonlyArray<Maybe<SizedImage>>>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly originalName?: Maybe<Scalars['String']['output']>;
  readonly subBrands?: Maybe<ReadonlyArray<Maybe<Brand>>>;
  readonly taxonomyId: Scalars['ID']['output'];
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly titleImage?: Maybe<Scalars['String']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type BreadcrumbLinks = {
  readonly __typename?: 'BreadcrumbLinks';
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
};

export type BundleComponent = {
  readonly __typename?: 'BundleComponent';
  readonly product?: Maybe<Product>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type CampaignBanner = {
  readonly __typename?: 'CampaignBanner';
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  readonly shortText?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type Catalogue = {
  readonly __typename?: 'Catalogue';
  readonly catalogueCode: Scalars['ID']['output'];
};

export type CatalogueInfo = {
  readonly __typename?: 'CatalogueInfo';
  readonly catalogueType: CatalogueType;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly validFrom: Scalars['DateTime']['output'];
  readonly validTo: Scalars['DateTime']['output'];
};

export type CatalogueLandingPage = {
  readonly __typename?: 'CatalogueLandingPage';
  readonly digitalCatalogues?: Maybe<ReadonlyArray<Maybe<CatalogueInfo>>>;
  readonly otherCatalogues?: Maybe<ReadonlyArray<Maybe<CatalogueInfo>>>;
};

export type CatalogueOffer = {
  readonly __typename?: 'CatalogueOffer';
  readonly benefits?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProductBenefit>>>;
  readonly benefitsQuantity?: Maybe<Scalars['Int']['output']>;
  readonly concepts?: Maybe<ReadonlyArray<Maybe<CatalogueOffersConcept>>>;
  readonly conceptsBenefits?: Maybe<ReadonlyArray<Maybe<CatalogueOffersConceptBenefit>>>;
  readonly discountPercent?: Maybe<Scalars['Float']['output']>;
  readonly missingQuantity?: Maybe<Scalars['Int']['output']>;
  readonly offerId?: Maybe<Scalars['Int']['output']>;
  readonly products?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProduct>>>;
  readonly totalPrice?: Maybe<Scalars['Float']['output']>;
  readonly totalPriceAfterDiscount?: Maybe<Scalars['Float']['output']>;
  readonly type?: Maybe<CatalogueOfferType>;
};

export enum CatalogueOfferType {
  Buy = 'Buy',
  Choose = 'Choose',
  ChooseAndChoose = 'ChooseAndChoose',
  ChooseAndGet = 'ChooseAndGet',
  Get = 'Get',
  None = 'None'
}

export type CatalogueOffersConcept = {
  readonly __typename?: 'CatalogueOffersConcept';
  readonly bp?: Maybe<Scalars['Float']['output']>;
  readonly concept?: Maybe<Concept>;
  readonly price?: Maybe<Scalars['Float']['output']>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  readonly shades?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProduct>>>;
};

export type CatalogueOffersConceptBenefit = {
  readonly __typename?: 'CatalogueOffersConceptBenefit';
  readonly bp?: Maybe<Scalars['Float']['output']>;
  readonly concept?: Maybe<Concept>;
  readonly price?: Maybe<Scalars['Float']['output']>;
  readonly purchasePrice?: Maybe<Scalars['Float']['output']>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  readonly shades?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProductBenefit>>>;
};

export type CatalogueOffersProduct = {
  readonly __typename?: 'CatalogueOffersProduct';
  readonly bp?: Maybe<Scalars['Float']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
  readonly product?: Maybe<Product>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type CatalogueOffersProductBenefit = {
  readonly __typename?: 'CatalogueOffersProductBenefit';
  readonly bp?: Maybe<Scalars['Float']['output']>;
  readonly isPurchased?: Maybe<Scalars['Boolean']['output']>;
  readonly price?: Maybe<Scalars['Float']['output']>;
  readonly product?: Maybe<Product>;
  readonly purchasePrice?: Maybe<Scalars['Float']['output']>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  readonly replacedPoduct?: Maybe<Product>;
};

export enum CatalogueType {
  DigitalCurrent = 'DigitalCurrent',
  DigitalNext = 'DigitalNext',
  LocalCustom = 'LocalCustom',
  Lookbook = 'Lookbook',
  None = 'None'
}

export type Category = {
  readonly __typename?: 'Category';
  readonly expirationDateDescription?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly navigationTags?: Maybe<ReadonlyArray<Maybe<NavigationTag>>>;
  readonly originalName?: Maybe<Scalars['String']['output']>;
  readonly subCategories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  readonly taxonomyId: Scalars['ID']['output'];
};

export type CategoryBanner = {
  readonly __typename?: 'CategoryBanner';
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  readonly shortText?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChannelType {
  Mv7 = 'MV7',
  Website = 'Website'
}

export enum ColorType {
  Brown = 'Brown',
  Green = 'Green',
  White = 'White'
}

export type CommissionSheetDocument = {
  readonly __typename?: 'CommissionSheetDocument';
  readonly documentId?: Maybe<Scalars['String']['output']>;
};

export type Concept = {
  readonly __typename?: 'Concept';
  readonly about?: Maybe<Scalars['String']['output']>;
  readonly activeIngredients?: Maybe<Scalars['String']['output']>;
  readonly availability?: Maybe<ConceptAvailability>;
  readonly benefits?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly brand?: Maybe<Brand>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  readonly conceptCode: Scalars['ID']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly formattedPrice?: Maybe<ConceptFormattedPrice>;
  readonly hashCode?: Maybe<Scalars['Int']['output']>;
  readonly howToUse?: Maybe<Scalars['String']['output']>;
  readonly howToUseSections?: Maybe<ReadonlyArray<Maybe<ConceptHowToUseSection>>>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly info?: Maybe<Scalars['String']['output']>;
  readonly ingredients?: Maybe<Scalars['String']['output']>;
  readonly isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  readonly isMultiProduct?: Maybe<Scalars['Boolean']['output']>;
  readonly isOutlet?: Maybe<Scalars['Boolean']['output']>;
  readonly isSample?: Maybe<Scalars['Boolean']['output']>;
  readonly labels?: Maybe<ReadonlyArray<Maybe<ProductLabel>>>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly originalName?: Maybe<Scalars['String']['output']>;
  readonly permission?: Maybe<ConceptPermission>;
  readonly price?: Maybe<ConceptPrice>;
  readonly primaryProduct?: Maybe<Product>;
  readonly productCode?: Maybe<Scalars['String']['output']>;
  readonly productCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Use Product Name instead. */
  readonly productName?: Maybe<Scalars['String']['output']>;
  readonly productStory?: Maybe<Scalars['String']['output']>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly ratingAverage?: Maybe<Scalars['Float']['output']>;
  readonly ratingCount?: Maybe<Scalars['Int']['output']>;
  /** Count of Ratings with review text */
  readonly ratingsWithReviewCount?: Maybe<Scalars['Int']['output']>;
  readonly showOnSite?: Maybe<Scalars['Boolean']['output']>;
  readonly subBrand?: Maybe<Brand>;
  readonly thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
};

export type ConceptAvailability = {
  readonly __typename?: 'ConceptAvailability';
  readonly isBackorder?: Maybe<Scalars['Boolean']['output']>;
  readonly isBundle?: Maybe<Scalars['Boolean']['output']>;
  readonly isComingSoon?: Maybe<Scalars['Boolean']['output']>;
  readonly isDeleted?: Maybe<Scalars['Boolean']['output']>;
  readonly isLastChance?: Maybe<Scalars['Boolean']['output']>;
  readonly isLowStock?: Maybe<Scalars['Boolean']['output']>;
  readonly isNew?: Maybe<Scalars['Boolean']['output']>;
  readonly isNoLongerForSale?: Maybe<Scalars['Boolean']['output']>;
  readonly isNotSoldOnline?: Maybe<Scalars['Boolean']['output']>;
  readonly isOffer?: Maybe<Scalars['Boolean']['output']>;
  readonly isOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  readonly isSalesLimited?: Maybe<Scalars['Boolean']['output']>;
  readonly isSuperDeal?: Maybe<Scalars['Boolean']['output']>;
};

export type ConceptFormattedPrice = {
  readonly __typename?: 'ConceptFormattedPrice';
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  readonly price?: Maybe<FormattedPrice>;
};

export type ConceptHowToUseSection = {
  readonly __typename?: 'ConceptHowToUseSection';
  readonly bulletList?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ConceptPermission = {
  readonly __typename?: 'ConceptPermission';
  readonly canDisplay?: Maybe<Scalars['Boolean']['output']>;
};

export type ConceptPrice = {
  readonly __typename?: 'ConceptPrice';
  readonly currency?: Maybe<Scalars['String']['output']>;
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  readonly price?: Maybe<Price>;
};

export type ConsultantSetting = {
  readonly __typename?: 'ConsultantSetting';
  readonly format?: Maybe<SettingsFormatType>;
  readonly setting?: Maybe<Scalars['String']['output']>;
  readonly timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type Coordinates = {
  readonly __typename?: 'Coordinates';
  readonly latitude?: Maybe<Scalars['Float']['output']>;
  readonly longitude?: Maybe<Scalars['Float']['output']>;
};

export type CountryMetadataItem = {
  readonly __typename?: 'CountryMetadataItem';
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  readonly validation?: Maybe<AddressMetadataValidation>;
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly addressMetadata?: Maybe<AddressMetadata>;
  readonly addresses?: Maybe<ReadonlyArray<Maybe<Address>>>;
  readonly alias?: Maybe<Scalars['String']['output']>;
  readonly approval?: Maybe<Approval>;
  readonly bankAccounts?: Maybe<ReadonlyArray<Maybe<BankAccount>>>;
  readonly beautyStore?: Maybe<PersonalBeautyStore>;
  readonly birthDate?: Maybe<Scalars['Date']['output']>;
  readonly careerTitle?: Maybe<Scalars['String']['output']>;
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  readonly customerId: Scalars['ID']['output'];
  readonly customerType?: Maybe<CustomerType>;
  readonly directSponsor?: Maybe<Sponsor>;
  readonly displayName?: Maybe<Scalars['String']['output']>;
  readonly emails?: Maybe<ReadonlyArray<Maybe<Email>>>;
  readonly ewalletAccounts?: Maybe<ReadonlyArray<Maybe<EWalletAccount>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly gender?: Maybe<Gender>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly myBenefitsSummary?: Maybe<MyBenefitsSummary>;
  readonly myProgramPlus?: Maybe<MyProgramPlus>;
  readonly passportNumber?: Maybe<Scalars['String']['output']>;
  readonly phones?: Maybe<ReadonlyArray<Maybe<Phone>>>;
  readonly pin?: Maybe<Scalars['String']['output']>;
  readonly preferredLanguage?: Maybe<Scalars['String']['output']>;
  readonly productLists?: Maybe<ReadonlyArray<Maybe<ProductList>>>;
  readonly settings?: Maybe<ConsultantSetting>;
  readonly taxClassification?: Maybe<TaxClassification>;
  readonly taxCode?: Maybe<Scalars['String']['output']>;
  readonly taxCode2?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly uniqueId?: Maybe<Scalars['String']['output']>;
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
  readonly visitorSegment: VisitorSegment;
};


export type CustomerMyBenefitsSummaryArgs = {
  periodId: Scalars['Int']['input'];
};


export type CustomerProductListsArgs = {
  productListType: ProductListType;
};


export type CustomerSettingsArgs = {
  settingId?: InputMaybe<Scalars['String']['input']>;
  settingsClient: SettingsClientType;
};

export enum CustomerCategory {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  Employee = 'Employee',
  EndConsumer = 'EndConsumer',
  EndCustomer = 'EndCustomer',
  Invitation = 'Invitation',
  Other = 'Other'
}

export type CustomerType = {
  readonly __typename?: 'CustomerType';
  readonly customerCategory?: Maybe<CustomerCategory>;
  readonly customerTypeId?: Maybe<Scalars['Int']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly isVipCustomer?: Maybe<Scalars['Boolean']['output']>;
};

export type DeliveryAddress = {
  readonly __typename?: 'DeliveryAddress';
  readonly area?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly delivery?: Maybe<Scalars['String']['output']>;
  readonly district?: Maybe<Scalars['String']['output']>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly locality?: Maybe<Scalars['String']['output']>;
  readonly province?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type DeliveryChannel = {
  readonly __typename?: 'DeliveryChannel';
  readonly address?: Maybe<SharedAddress>;
  readonly coordinates?: Maybe<Coordinates>;
  readonly deliveryChannelId: Scalars['ID']['output'];
  readonly deliveryMethod: DeliveryMethodType;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isCodAllowed: Scalars['Boolean']['output'];
  readonly isLuxury: Scalars['Boolean']['output'];
  readonly orisalesId: Scalars['Int']['output'];
  readonly telephoneNumber?: Maybe<Scalars['String']['output']>;
  readonly workingHours?: Maybe<Scalars['String']['output']>;
};

export enum DeliveryMethodType {
  DepartmentStore = 'DepartmentStore',
  Home = 'Home',
  Lpo = 'Lpo',
  NextOrder = 'NextOrder',
  PickupPoint = 'PickupPoint',
  PostOffice = 'PostOffice',
  Spo = 'Spo'
}

export type DeliveryOption = {
  readonly __typename?: 'DeliveryOption';
  readonly deliveryAddress?: Maybe<DeliveryAddress>;
  readonly deliveryAddressId?: Maybe<Scalars['Long']['output']>;
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly deliveryChannelId?: Maybe<Scalars['Long']['output']>;
  readonly deliveryCustomerId?: Maybe<Scalars['Long']['output']>;
  readonly estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly isAllowed: Scalars['Boolean']['output'];
  readonly isShippingJoker: Scalars['Boolean']['output'];
  readonly originalShippingFee?: Maybe<Scalars['Decimal']['output']>;
  readonly shippingFee: Scalars['Decimal']['output'];
};

export type DeliveryOptions = {
  readonly __typename?: 'DeliveryOptions';
  readonly collection?: Maybe<ReadonlyArray<Maybe<DeliveryOption>>>;
  readonly totalRecords: Scalars['Int']['output'];
};

export enum DeliverySearchFilter {
  AllAddresses = 'AllAddresses',
  DepartmentStore = 'DepartmentStore',
  History = 'History',
  Lpo = 'Lpo',
  MyAddresses = 'MyAddresses',
  MyFriends = 'MyFriends',
  MyHistoryAddresses = 'MyHistoryAddresses',
  MyHistoryFriends = 'MyHistoryFriends',
  MySpo = 'MySpo',
  PickUp = 'PickUp',
  PostOffice = 'PostOffice',
  PremiumSpo = 'PremiumSpo',
  Spo = 'Spo'
}

export type DeliverySelectionSummary = {
  readonly __typename?: 'DeliverySelectionSummary';
  readonly addressId?: Maybe<Scalars['Long']['output']>;
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly deliveryChannelId: Scalars['Long']['output'];
  readonly deliveryComment?: Maybe<Scalars['String']['output']>;
  readonly estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly isAllowed: Scalars['Boolean']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  readonly isLastUsed: Scalars['Boolean']['output'];
  readonly isShippingJoker: Scalars['Boolean']['output'];
  readonly originalShippingFee?: Maybe<Scalars['Decimal']['output']>;
  readonly outOfStockQuantity: Scalars['Int']['output'];
  readonly shippingFee: Scalars['Decimal']['output'];
};

export type DigitalPromotions = {
  readonly __typename?: 'DigitalPromotions';
  readonly bestOffersBanners?: Maybe<ReadonlyArray<Maybe<BestOffersBanner>>>;
  readonly bestOffersProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly campaignBanners?: Maybe<ReadonlyArray<Maybe<CampaignBanner>>>;
  readonly campaignProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly categoryBanners?: Maybe<ReadonlyArray<Maybe<CategoryBanner>>>;
  readonly categoryProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly channel: ChannelType;
  readonly heroBanners?: Maybe<ReadonlyArray<Maybe<HeroBanner>>>;
  readonly heroProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly validityDate?: Maybe<Scalars['DateTime']['output']>;
  readonly whatsNewBanners?: Maybe<ReadonlyArray<Maybe<WhatsNewBanner>>>;
  readonly whatsNewProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type DocumentInfo = {
  readonly __typename?: 'DocumentInfo';
  readonly documentId?: Maybe<Scalars['Int']['output']>;
  readonly fileName?: Maybe<Scalars['String']['output']>;
  readonly fileSize?: Maybe<Scalars['Int']['output']>;
};

export type EWalletAccount = {
  readonly __typename?: 'EWalletAccount';
  readonly accountName?: Maybe<Scalars['String']['output']>;
  readonly eWalletAccountId: Scalars['ID']['output'];
  readonly owner?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<EWalletType>;
};

export enum EWalletType {
  Revolut = 'Revolut'
}

export type EditorialBanner = {
  readonly __typename?: 'EditorialBanner';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly layout: EditorialBannerLayoutEnum;
  readonly modules?: Maybe<ReadonlyArray<Maybe<EditorialBannerModule>>>;
};

export type EditorialBannerColor = {
  readonly __typename?: 'EditorialBannerColor';
  readonly type: ColorType;
};

export type EditorialBannerContent = {
  readonly __typename?: 'EditorialBannerContent';
  readonly showReadMoreOption: Scalars['Boolean']['output'];
  readonly textCollection?: Maybe<ReadonlyArray<Maybe<EditorialBannerText>>>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly titleColor: EditorialBannerTitleColorEnum;
  readonly titleSize: EditorialBannerTitleSizeEnum;
  readonly verticalAlignment: VerticalContentAlignment;
};

export type EditorialBannerImage = {
  readonly __typename?: 'EditorialBannerImage';
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum EditorialBannerLayoutEnum {
  FourMedia = 'FourMedia',
  Headline = 'Headline',
  OneMediaCenterBottomText = 'OneMediaCenterBottomText',
  OneMediaOverlayLeftText = 'OneMediaOverlayLeftText',
  OneMediaOverlayRightText = 'OneMediaOverlayRightText',
  OneMediaParagraphText = 'OneMediaParagraphText',
  OneMediaProductPromo = 'OneMediaProductPromo',
  ParagraphText = 'ParagraphText',
  Quote = 'Quote',
  ThreeMediaRightBottomText = 'ThreeMediaRightBottomText',
  TwoMedia = 'TwoMedia',
  TwoMediaRightTopText = 'TwoMediaRightTopText',
  Video = 'Video'
}

export type EditorialBannerModule = {
  readonly __typename?: 'EditorialBannerModule';
  readonly backgroundColor?: Maybe<EditorialBannerColor>;
  readonly backgroundImage?: Maybe<EditorialBannerImage>;
  readonly backgroundType: BackgroundType;
  readonly backgroundVideo?: Maybe<Video>;
  readonly content?: Maybe<EditorialBannerContent>;
  readonly link?: Maybe<Link>;
};

export type EditorialBannerText = {
  readonly __typename?: 'EditorialBannerText';
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly textLinks?: Maybe<ReadonlyArray<Maybe<Link>>>;
};

export enum EditorialBannerTitleColorEnum {
  Black = 'Black',
  DarkGrey = 'DarkGrey',
  White = 'White'
}

export enum EditorialBannerTitleSizeEnum {
  H5 = 'H5',
  H6 = 'H6'
}

export type EditorialCarousel = {
  readonly __typename?: 'EditorialCarousel';
  readonly editorials?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  readonly id: Scalars['ID']['output'];
  readonly product?: Maybe<Product>;
};

export enum EditorialChannelType {
  Mobile = 'Mobile',
  Web = 'Web'
}

export type EditorialHeader = {
  readonly __typename?: 'EditorialHeader';
  readonly authorName?: Maybe<Scalars['String']['output']>;
  readonly backgroundStorybookColor: StorybookColor;
  readonly extraLongHeadline?: Maybe<Scalars['Boolean']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly headlineStorybookColor: StorybookColor;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isSharingEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
  readonly photographerName?: Maybe<Scalars['String']['output']>;
  readonly publishedDate?: Maybe<Scalars['DateTime']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type EditorialLandingPage = {
  readonly __typename?: 'EditorialLandingPage';
  readonly backgroundStorybookColor?: Maybe<StorybookColor>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly editorialItems?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  readonly headlineStorybookColor?: Maybe<StorybookColor>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly landingPageId: Scalars['ID']['output'];
  readonly themeItems?: Maybe<ReadonlyArray<Maybe<EditorialLandingPage>>>;
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};


export type EditorialLandingPageEditorialItemsArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export type EditorialMixedPlpContent = Product;

export type EditorialOlapic = {
  readonly __typename?: 'EditorialOlapic';
  readonly categoryId?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly product?: Maybe<Product>;
  readonly type?: Maybe<OlapicTypeEnum>;
};

export type EditorialPage = {
  readonly __typename?: 'EditorialPage';
  readonly contentItems?: Maybe<ReadonlyArray<Maybe<EditorialPageContent>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly pageId: Scalars['ID']['output'];
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type EditorialPageContent = EditorialBanner | EditorialCarousel | EditorialHeader | EditorialOlapic | EditorialPage | EditorialPlpRows | EditorialProductBoxCarousel;

export type EditorialPlpRow = {
  readonly __typename?: 'EditorialPlpRow';
  readonly content?: Maybe<ReadonlyArray<Maybe<EditorialMixedPlpContent>>>;
};

export type EditorialPlpRows = {
  readonly __typename?: 'EditorialPlpRows';
  readonly facets?: Maybe<ReadonlyArray<Maybe<PlpFacet>>>;
  readonly id?: Maybe<Scalars['ID']['output']>;
  readonly orderByOptions?: Maybe<ReadonlyArray<Maybe<PlpOrderingDefinition>>>;
  readonly rows?: Maybe<ReadonlyArray<Maybe<EditorialPlpRow>>>;
  readonly totalBannersCount: Scalars['Int']['output'];
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type EditorialProductBoxCarousel = {
  readonly __typename?: 'EditorialProductBoxCarousel';
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['ID']['output']>;
  readonly popularProductCategories?: Maybe<ReadonlyArray<Maybe<PopularProductCategory>>>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type Email = {
  readonly __typename?: 'Email';
  readonly emailAddress?: Maybe<Scalars['String']['output']>;
  readonly isPending?: Maybe<Scalars['Boolean']['output']>;
};

export type ErrorDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'ErrorDialogNewsletterSubsciptionConfiguration';
  readonly buttonText?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ExternalCampaign = {
  readonly __typename?: 'ExternalCampaign';
  readonly campaignId?: Maybe<Scalars['String']['output']>;
  readonly campaignUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedbackBenefitBanner = {
  readonly __typename?: 'FeedbackBenefitBanner';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly readMoreLink?: Maybe<Link>;
  readonly registerLink?: Maybe<Link>;
  readonly signInLink?: Maybe<Link>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly welcomeMessage?: Maybe<Scalars['String']['output']>;
};

export enum FeedbackBenefitBannerType {
  Cashback = 'Cashback',
  ShareAndEarn = 'ShareAndEarn'
}

export type Footer = {
  readonly __typename?: 'Footer';
  readonly disableLanguageSwitcherPopUp?: Maybe<Scalars['Boolean']['output']>;
  readonly languageConfiguration?: Maybe<LanguageConfiguration>;
};

export type FooterNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'FooterNewsletterSubsciptionConfiguration';
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type FormattedPrice = {
  readonly __typename?: 'FormattedPrice';
  readonly basicCashback?: Maybe<Scalars['String']['output']>;
  readonly basicCatalogueConsultantPrice?: Maybe<Scalars['String']['output']>;
  readonly basicCataloguePrice?: Maybe<Scalars['String']['output']>;
  readonly currentConsultantPrice?: Maybe<Scalars['String']['output']>;
  readonly currentPrice?: Maybe<Scalars['String']['output']>;
  readonly previousMinOfferPrice?: Maybe<Scalars['String']['output']>;
  readonly priceAfterImmediateDiscount?: Maybe<Scalars['String']['output']>;
  readonly sharingBonus?: Maybe<Scalars['String']['output']>;
};

export type FreeDelivery = {
  readonly __typename?: 'FreeDelivery';
  readonly thresholdPriceText?: Maybe<Scalars['String']['output']>;
};

export type FrontPageImage = {
  readonly __typename?: 'FrontPageImage';
  readonly subTitle?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type Frontpage = {
  readonly __typename?: 'Frontpage';
  readonly beautyEdit?: Maybe<BeautyEdit>;
  readonly beautyVideo?: Maybe<BeautyVideo>;
  readonly benefitBanner?: Maybe<BenefitBanner>;
  readonly bestsellerProducts?: Maybe<BestsellerProducts>;
  readonly categoryNavigation?: Maybe<ReadonlyArray<Maybe<Link>>>;
  readonly digitalPromotions?: Maybe<DigitalPromotions>;
  readonly iconicProducts?: Maybe<IconicProducts>;
  readonly motivationContent?: Maybe<MotivationContent>;
  readonly notificationBanner?: Maybe<NotificationBanner>;
  readonly productStandards?: Maybe<ProductStandards>;
  readonly topBrands?: Maybe<TopBrands>;
  readonly vipBenefits?: Maybe<VipBenefits>;
};


export type FrontpageDigitalPromotionsArgs = {
  channel: ChannelType;
  validityDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum Gender {
  Man = 'Man',
  Other = 'Other',
  Woman = 'Woman'
}

export type GlobalBonusItemsByLocalPeriod = {
  readonly __typename?: 'GlobalBonusItemsByLocalPeriod';
  readonly globalBonusItems?: Maybe<ReadonlyArray<Maybe<MyBenefitsBonusItemV2>>>;
  readonly localPeriodCode: Scalars['Int']['output'];
};

export type HeroBanner = {
  readonly __typename?: 'HeroBanner';
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  readonly priority?: Maybe<Scalars['Int']['output']>;
  readonly smallImageUrl?: Maybe<Scalars['String']['output']>;
  readonly tagline?: Maybe<Scalars['String']['output']>;
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
  readonly videoUrl?: Maybe<Scalars['String']['output']>;
};

export type IconicProducts = {
  readonly __typename?: 'IconicProducts';
  readonly banner?: Maybe<ProductsPromotionBanner>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type Ingredient = {
  readonly __typename?: 'Ingredient';
  readonly benefitSummary?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly didYouKnow?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly keyBenefit1?: Maybe<Scalars['String']['output']>;
  readonly keyBenefit2?: Maybe<Scalars['String']['output']>;
  readonly keyBenefit3?: Maybe<Scalars['String']['output']>;
  readonly keyBenefits?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly latinName?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly numberOfProducts: Scalars['Int']['output'];
  readonly products: ReadonlyArray<Product>;
  readonly relatedArticles: ReadonlyArray<Article>;
  readonly shortName?: Maybe<Scalars['String']['output']>;
};


export type IngredientProductsArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


export type IngredientRelatedArticlesArgs = {
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export type IngredientLibrary = {
  readonly __typename?: 'IngredientLibrary';
  readonly inspiration?: Maybe<IngredientLibraryInspiration>;
};

export type IngredientLibraryInspiration = {
  readonly __typename?: 'IngredientLibraryInspiration';
  readonly inspirationTexts?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
};

export type InviteFriendProfile = {
  readonly __typename?: 'InviteFriendProfile';
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  readonly contacts?: Maybe<ReadonlyArray<Maybe<SocialContact>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly photoUrl?: Maybe<Scalars['String']['output']>;
};

export type IsPrimaryMetadataItem = {
  readonly __typename?: 'IsPrimaryMetadataItem';
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
};

export type Language = {
  readonly __typename?: 'Language';
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<LanguageType>;
};

export type LanguageConfiguration = {
  readonly __typename?: 'LanguageConfiguration';
  readonly countryLabel?: Maybe<Scalars['String']['output']>;
  readonly currentCountry?: Maybe<Scalars['String']['output']>;
  readonly languageLabel?: Maybe<Scalars['String']['output']>;
  readonly selectCountryLabel?: Maybe<Scalars['String']['output']>;
  readonly selectCountryUrl?: Maybe<Scalars['String']['output']>;
};

export enum LanguageType {
  Alternative = 'Alternative',
  Default = 'Default'
}

export type Link = {
  readonly __typename?: 'Link';
  readonly appLink?: Maybe<AppLink>;
  readonly attributes?: Maybe<ReadonlyArray<Maybe<Attribute>>>;
  readonly data?: Maybe<LinkData>;
  readonly dynamicSharingUrl?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly relativeUrl?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly type: LinkType;
};


export type LinkDynamicSharingUrlArgs = {
  storeName?: InputMaybe<Scalars['ID']['input']>;
};

export type LinkData = Brand | Catalogue | Category | EditorialPage | ExternalCampaign | NovageSkincareAdvisor | Product | ProductListingPage;

export enum LinkType {
  Brand = 'Brand',
  Campaign = 'Campaign',
  Catalogue = 'Catalogue',
  Category = 'Category',
  CategoryNavigation = 'CategoryNavigation',
  Custom = 'Custom',
  DigitalCatalogueCurrentNavigation = 'DigitalCatalogueCurrentNavigation',
  EditorialArticle = 'EditorialArticle',
  EditorialCampaign = 'EditorialCampaign',
  Frontpage = 'Frontpage',
  Ingredient = 'Ingredient',
  InvitationOffer = 'InvitationOffer',
  Invite = 'Invite',
  NewProducts = 'NewProducts',
  NovageSkincareAdvisor = 'NovageSkincareAdvisor',
  PlPage = 'PlPage',
  Product = 'Product',
  RegistrationPage = 'RegistrationPage',
  SignIn = 'SignIn'
}

export type MakeupTryOnConfiguration = {
  readonly __typename?: 'MakeupTryOnConfiguration';
  readonly productDetail?: Maybe<ProductDetailMakeupTryOnConfiguration>;
};

export type MakeupTryOnProductRelation = {
  readonly __typename?: 'MakeupTryOnProductRelation';
  readonly shiShiProductId?: Maybe<Scalars['String']['output']>;
};

export type Manufacturer = {
  readonly __typename?: 'Manufacturer';
  readonly address?: Maybe<ManufacturerAddress>;
  readonly manufacturerId?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type ManufacturerAddress = {
  readonly __typename?: 'ManufacturerAddress';
  readonly address1?: Maybe<Scalars['String']['output']>;
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly countryCode?: Maybe<Scalars['String']['output']>;
  readonly countryName?: Maybe<Scalars['String']['output']>;
  readonly state?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<Scalars['String']['output']>;
};

export type MetadataOperations = {
  readonly __typename?: 'MetadataOperations';
  readonly create?: Maybe<AddressMetadataOperation>;
  readonly delete?: Maybe<AddressMetadataOperation>;
  readonly read?: Maybe<AddressMetadataOperation>;
  readonly update?: Maybe<AddressMetadataOperation>;
};

export type MixedPlpContent = Product;

export type MobileApp = {
  readonly __typename?: 'MobileApp';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly images?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly links?: Maybe<MobileAppLinks>;
  readonly mobileAppType?: Maybe<MobileAppTypeData>;
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly titleImage?: Maybe<Scalars['String']['output']>;
};

export type MobileAppLinks = {
  readonly __typename?: 'MobileAppLinks';
  readonly appleStoreLink?: Maybe<Scalars['String']['output']>;
  readonly googlePlayStoreLink?: Maybe<Scalars['String']['output']>;
};

export enum MobileAppType {
  None = 'None',
  ToSell = 'ToSell',
  ToShop = 'ToShop'
}

export type MobileAppTypeData = {
  readonly __typename?: 'MobileAppTypeData';
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<MobileAppType>;
};

export type MobileApps = {
  readonly __typename?: 'MobileApps';
  readonly mobileAppCollection?: Maybe<ReadonlyArray<Maybe<MobileApp>>>;
  readonly pageHeader?: Maybe<MobileAppsPageHeader>;
};

export type MobileAppsPageHeader = {
  readonly __typename?: 'MobileAppsPageHeader';
  readonly appleStoreImage?: Maybe<Scalars['String']['output']>;
  readonly googlePlayImage?: Maybe<Scalars['String']['output']>;
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type MotivationContent = {
  readonly __typename?: 'MotivationContent';
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly backInStockNotificationSubscription?: Maybe<Scalars['Void']['output']>;
  readonly shareAndEarnPersonalCodeUpdate?: Maybe<MutationResult>;
};


export type MutationBackInStockNotificationSubscriptionArgs = {
  input?: InputMaybe<BackInStockNotificationSubscriptionInput>;
};


export type MutationShareAndEarnPersonalCodeUpdateArgs = {
  input?: InputMaybe<ShareAndEarnUpdatePersonalCodeInput>;
};

export type MutationResult = {
  readonly __typename?: 'MutationResult';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly success: Scalars['Boolean']['output'];
};

export type MyBenefitsBonusItem = {
  readonly __typename?: 'MyBenefitsBonusItem';
  readonly amount: Scalars['Decimal']['output'];
  readonly bonusTypeDescription?: Maybe<Scalars['String']['output']>;
  readonly paymentChannel: PaymentChannel;
  readonly paymentDescription?: Maybe<Scalars['String']['output']>;
};

export type MyBenefitsBonusItemV2 = {
  readonly __typename?: 'MyBenefitsBonusItemV2';
  readonly amount: Scalars['Decimal']['output'];
  readonly bonusTypeDescription?: Maybe<Scalars['String']['output']>;
  readonly globalPeriodCode: Scalars['Int']['output'];
  readonly paymentChannel: PaymentChannel;
  readonly paymentDescription?: Maybe<Scalars['String']['output']>;
};

export type MyBenefitsSummary = {
  readonly __typename?: 'MyBenefitsSummary';
  readonly bankTransferAmount: Scalars['Decimal']['output'];
  readonly bankTransferState: BankBonusPaymentTransferState;
  readonly bonusItems?: Maybe<ReadonlyArray<Maybe<MyBenefitsBonusItem>>>;
  readonly bonusesAndCashAwards: Scalars['Decimal']['output'];
  readonly commissionSheet?: Maybe<CommissionSheetDocument>;
  readonly currencyIsoCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use GlobalBonusItemsByLocalPeriod instead */
  readonly globalBonusItems?: Maybe<ReadonlyArray<Maybe<MyBenefitsBonusItem>>>;
  readonly globalBonusItemsByLocalPeriod?: Maybe<ReadonlyArray<Maybe<GlobalBonusItemsByLocalPeriod>>>;
  readonly immediateDiscounts: Scalars['Decimal']['output'];
  readonly oriflameWallet: Scalars['Decimal']['output'];
  readonly totalBenefits: Scalars['Decimal']['output'];
};

export type MyProgramPlus = {
  readonly __typename?: 'MyProgramPlus';
  readonly salesBoosterInfo?: Maybe<ReadonlyArray<Maybe<SalesBoosterQualificationInfo>>>;
};

export type Navigation = {
  readonly __typename?: 'Navigation';
  readonly children?: Maybe<ReadonlyArray<Maybe<Navigation>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly linkType?: Maybe<NavigationLinkType>;
  readonly nodeId?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type NavigationCollection = {
  readonly __typename?: 'NavigationCollection';
  readonly children?: Maybe<ReadonlyArray<Maybe<Navigation>>>;
  readonly navigationType?: Maybe<NavigationType>;
};

export enum NavigationLinkType {
  AllCategory = 'AllCategory',
  BeautyTips = 'BeautyTips',
  Bestsellers = 'Bestsellers',
  BestsellersInCategory = 'BestsellersInCategory',
  Bottom = 'Bottom',
  BottomCopyright = 'BottomCopyright',
  BottomLinks = 'BottomLinks',
  Catalogue = 'Catalogue',
  Catalogues = 'Catalogues',
  Category = 'Category',
  DigitalCatalogueCurrent = 'DigitalCatalogueCurrent',
  DigitalCatalogueNext = 'DigitalCatalogueNext',
  FocusPage = 'FocusPage',
  FooterColumn = 'FooterColumn',
  FooterColumnItem = 'FooterColumnItem',
  IDontKnowYet = 'IDontKnowYet',
  Icons = 'Icons',
  IconsInCategory = 'IconsInCategory',
  Inspiration = 'Inspiration',
  JoinRewards = 'JoinRewards',
  KeyIngredients = 'KeyIngredients',
  MenuTiles = 'MenuTiles',
  New = 'New',
  None = 'None',
  OurApps = 'OurApps',
  OutletShop = 'OutletShop',
  PaymentMethod = 'PaymentMethod',
  PaymentMethods = 'PaymentMethods',
  PopularBrand = 'PopularBrand',
  PopularBrands = 'PopularBrands',
  PopularLookbook = 'PopularLookbook',
  Qrtr = 'Qrtr',
  RewardsShop = 'RewardsShop',
  SampleShop = 'SampleShop',
  Segment = 'Segment',
  SubCategory = 'SubCategory',
  SupportCenter = 'SupportCenter',
  TopNavigation = 'TopNavigation',
  WhatsNewInCategory = 'WhatsNewInCategory'
}

export type NavigationTag = {
  readonly __typename?: 'NavigationTag';
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum NavigationType {
  AboutTiles = 'AboutTiles',
  Footer = 'Footer',
  InspirationTiles = 'InspirationTiles',
  JoinTiles = 'JoinTiles',
  ShopSection = 'ShopSection',
  TopNavigation = 'TopNavigation'
}

export type NewsletterSubsciptionConfiguration = {
  readonly __typename?: 'NewsletterSubsciptionConfiguration';
  readonly errorDialog?: Maybe<ErrorDialogNewsletterSubsciptionConfiguration>;
  readonly footer?: Maybe<FooterNewsletterSubsciptionConfiguration>;
  readonly popup?: Maybe<PopupNewsletterSubsciptionConfiguration>;
  readonly responseMessages?: Maybe<ReadonlyArray<Maybe<ResponseMessageNewsletterSubsciptionConfiguration>>>;
  readonly submitDialog?: Maybe<SubmitDialogNewsletterSubsciptionConfiguration>;
  readonly subscribedDialog?: Maybe<SubscribedDialogNewsletterSubsciptionConfiguration>;
};

export type NotificationBanner = {
  readonly __typename?: 'NotificationBanner';
  readonly messageLinkTitle?: Maybe<Scalars['String']['output']>;
  readonly messageLinkUrl?: Maybe<Scalars['String']['output']>;
  readonly messageText?: Maybe<Scalars['String']['output']>;
  readonly messageTheme?: Maybe<NotificationBannerThemeType>;
};

export enum NotificationBannerThemeType {
  Black = 'Black',
  Grey = 'Grey'
}

export type NovageSkincareAdvisor = {
  readonly __typename?: 'NovageSkincareAdvisor';
  readonly environment?: Maybe<Scalars['String']['output']>;
  readonly partnerId?: Maybe<Scalars['String']['output']>;
};

export type OlapicConfiguration = {
  readonly __typename?: 'OlapicConfiguration';
  readonly apiKey?: Maybe<Scalars['String']['output']>;
  readonly category?: Maybe<Category>;
  readonly hideCaption?: Maybe<Scalars['Boolean']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly instance?: Maybe<Scalars['String']['output']>;
  readonly itemName?: Maybe<Scalars['String']['output']>;
  readonly languageCode?: Maybe<Scalars['String']['output']>;
  readonly product?: Maybe<Product>;
  readonly scriptUrl?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<OlapicTypeEnum>;
};

export enum OlapicTypeEnum {
  Category = 'Category',
  FrontPage = 'FrontPage',
  Gallery = 'Gallery',
  ProductDetails = 'ProductDetails'
}

export enum OrderOrigin {
  Automatic = 'Automatic',
  Bambuser = 'Bambuser',
  Claim = 'Claim',
  Demo = 'Demo',
  Email = 'Email',
  Immediate = 'Immediate',
  NowApp = 'NowApp',
  OnBehalf = 'OnBehalf',
  Online = 'Online',
  Orisales = 'Orisales',
  Other = 'Other',
  Pbs = 'Pbs',
  Pharmacy = 'Pharmacy',
  Registration = 'Registration',
  Telephone = 'Telephone',
  Vip = 'Vip',
  WeChat = 'WeChat',
  WeChatApp = 'WeChatApp'
}

export type PageNavigation = {
  readonly __typename?: 'PageNavigation';
  readonly items?: Maybe<ReadonlyArray<Maybe<PageNavigationItem>>>;
  readonly pageId?: Maybe<Scalars['String']['output']>;
};

export type PageNavigationItem = {
  readonly __typename?: 'PageNavigationItem';
  readonly itemId?: Maybe<Scalars['String']['output']>;
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum PaymentChannel {
  Commission = 'Commission',
  Discount = 'Discount',
  SalesActionDiscount = 'SalesActionDiscount'
}

export type PaymentMethod = {
  readonly __typename?: 'PaymentMethod';
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type PermanentTopBanner = {
  readonly __typename?: 'PermanentTopBanner';
  readonly link?: Maybe<Link>;
  readonly message?: Maybe<Scalars['String']['output']>;
};

export enum PermanentTopBannerType {
  Checkout = 'Checkout',
  MyPages = 'MyPages',
  PublicPage = 'PublicPage',
  PublicPageWithReferral = 'PublicPageWithReferral'
}

export type PersonalBeautyStore = {
  readonly __typename?: 'PersonalBeautyStore';
  readonly customerId?: Maybe<Scalars['Long']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly productLists?: Maybe<ReadonlyArray<Maybe<ProductList>>>;
  readonly profile?: Maybe<PersonalBeautyStoreProfile>;
  readonly url?: Maybe<Scalars['String']['output']>;
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
};


export type PersonalBeautyStoreProductListsArgs = {
  productListType: ProductListType;
};

export type PersonalBeautyStoreProfile = {
  readonly __typename?: 'PersonalBeautyStoreProfile';
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  readonly contacts?: Maybe<ReadonlyArray<Maybe<SocialContact>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly photoUrl?: Maybe<Scalars['String']['output']>;
  readonly referralProgram?: Maybe<ReferralProgram>;
};

export type PersonalProductListConfiguration = {
  readonly __typename?: 'PersonalProductListConfiguration';
  readonly addThis?: Maybe<AddThis>;
  readonly homePageUrl?: Maybe<Scalars['String']['output']>;
  readonly isDynamicLinkEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isPbsRequired?: Maybe<Scalars['Boolean']['output']>;
  readonly isPplEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly pbsPageUrl?: Maybe<Scalars['String']['output']>;
};

export type Phone = {
  readonly __typename?: 'Phone';
  readonly isPrimary?: Maybe<Scalars['Boolean']['output']>;
  readonly isVerified?: Maybe<Scalars['Boolean']['output']>;
  readonly telephoneId: Scalars['ID']['output'];
  readonly telephoneNumber?: Maybe<Scalars['String']['output']>;
  readonly telephoneType?: Maybe<TelephoneType>;
};

export type PickupPoint = {
  readonly __typename?: 'PickupPoint';
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly deliveryChannelId: Scalars['Long']['output'];
  readonly estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  readonly isAllowed: Scalars['Boolean']['output'];
  readonly isShippingJoker: Scalars['Boolean']['output'];
  readonly originalShippingFee?: Maybe<Scalars['Decimal']['output']>;
  readonly outOfStockQuantity: Scalars['Int']['output'];
  readonly shippingFee: Scalars['Decimal']['output'];
};

export type PickupPoints = {
  readonly __typename?: 'PickupPoints';
  readonly collection?: Maybe<ReadonlyArray<Maybe<PickupPoint>>>;
  readonly totalRecords: Scalars['Int']['output'];
};

export enum PickupSearchFilter {
  All = 'All',
  DepartmentStore = 'DepartmentStore',
  Lpo = 'Lpo',
  MySpo = 'MySpo',
  PickupPoint = 'PickupPoint',
  PostOffice = 'PostOffice',
  PremiumSpo = 'PremiumSpo',
  Spo = 'Spo'
}

export type PlpFacet = {
  readonly __typename?: 'PlpFacet';
  readonly active: Scalars['Boolean']['output'];
  readonly displayName?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly values: ReadonlyArray<PlpFacetValue>;
};

export type PlpFacetInfo = {
  readonly __typename?: 'PlpFacetInfo';
  readonly color?: Maybe<Scalars['String']['output']>;
};

export type PlpFacetValue = {
  readonly __typename?: 'PlpFacetValue';
  readonly active: Scalars['Boolean']['output'];
  readonly count: Scalars['Int']['output'];
  readonly displayName?: Maybe<Scalars['String']['output']>;
  readonly facetInfo: PlpFacetInfo;
  readonly key?: Maybe<Scalars['String']['output']>;
};

export enum PlpOrderBy {
  HighestDiscount = 'HighestDiscount',
  HighestPrice = 'HighestPrice',
  HighestRating = 'HighestRating',
  Latest = 'Latest',
  LowestPrice = 'LowestPrice',
  Recommended = 'Recommended'
}

export type PlpOrderingDefinition = {
  readonly __typename?: 'PlpOrderingDefinition';
  readonly displayText?: Maybe<Scalars['String']['output']>;
  readonly orderBy: PlpOrderBy;
};

export type PlpRow = {
  readonly __typename?: 'PlpRow';
  readonly content?: Maybe<ReadonlyArray<Maybe<MixedPlpContent>>>;
};

export type PlpRowsFacetFilterInput = {
  readonly keys?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type PopularProductCategory = {
  readonly __typename?: 'PopularProductCategory';
  readonly taxonomyId: Scalars['Int']['output'];
};

export type PopupNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'PopupNewsletterSubsciptionConfiguration';
  readonly delayInSeconds?: Maybe<Scalars['Int']['output']>;
  readonly displayFrequencyInDays?: Maybe<Scalars['Int']['output']>;
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type Price = {
  readonly __typename?: 'Price';
  readonly basicCashback?: Maybe<Scalars['Float']['output']>;
  readonly basicCatalogueConsultantPrice?: Maybe<Scalars['Float']['output']>;
  readonly basicCataloguePrice?: Maybe<Scalars['Float']['output']>;
  readonly currentConsultantPrice?: Maybe<Scalars['Float']['output']>;
  readonly currentPrice?: Maybe<Scalars['Float']['output']>;
  readonly previousMinOfferPrice?: Maybe<Scalars['Float']['output']>;
  readonly priceAfterImmediateDiscount?: Maybe<Scalars['Float']['output']>;
  readonly sharingBonus?: Maybe<Scalars['Float']['output']>;
};

export type Product = {
  readonly __typename?: 'Product';
  readonly adr?: Maybe<Scalars['String']['output']>;
  readonly alternativeImageUrls?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly availability?: Maybe<ProductAvailability>;
  readonly backInStockAvailability?: Maybe<BackInStockAvailability>;
  readonly barcode?: Maybe<Scalars['String']['output']>;
  readonly benefitCatalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  readonly bestsellerPriority?: Maybe<Scalars['Int']['output']>;
  readonly bundleComponents?: Maybe<ReadonlyArray<Maybe<BundleComponent>>>;
  readonly catalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly colorHexCode?: Maybe<Scalars['String']['output']>;
  readonly colorImageUrl?: Maybe<Scalars['String']['output']>;
  readonly concept?: Maybe<Concept>;
  readonly conceptCode?: Maybe<Scalars['String']['output']>;
  readonly dimension?: Maybe<Scalars['String']['output']>;
  readonly dimensionsText?: Maybe<Scalars['String']['output']>;
  readonly discoDate?: Maybe<Scalars['DateTime']['output']>;
  readonly euroMonitorClassId?: Maybe<Scalars['String']['output']>;
  readonly formattedPrice?: Maybe<ProductFormattedPrice>;
  readonly globalCommercialIntent?: Maybe<Scalars['String']['output']>;
  readonly hashCode?: Maybe<Scalars['Int']['output']>;
  readonly hexColors?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly inci?: Maybe<Scalars['String']['output']>;
  readonly includedInBundles?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<Ingredient>>>;
  readonly introDate?: Maybe<Scalars['DateTime']['output']>;
  readonly isBestseller?: Maybe<Scalars['Boolean']['output']>;
  readonly isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  readonly isOutlet?: Maybe<Scalars['Boolean']['output']>;
  readonly isProductCustomized?: Maybe<Scalars['Boolean']['output']>;
  readonly isSample?: Maybe<Scalars['Boolean']['output']>;
  readonly labels?: Maybe<ReadonlyArray<Maybe<ProductLabel>>>;
  readonly makeupTryOn?: Maybe<MakeupTryOnProductRelation>;
  readonly manufacturers?: Maybe<ReadonlyArray<Maybe<Manufacturer>>>;
  readonly parentProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly pdpUrl?: Maybe<Link>;
  readonly permission?: Maybe<ProductPermission>;
  readonly price?: Maybe<ProductPrice>;
  readonly productCode: Scalars['ID']['output'];
  readonly productExpectedInbound?: Maybe<ProductExpectedInbound>;
  readonly productType?: Maybe<ProductType>;
  readonly purchaseCatalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  readonly recommendations?: Maybe<ReadonlyArray<Maybe<ProductRecommendation>>>;
  readonly relations?: Maybe<ReadonlyArray<Maybe<ProductRelation>>>;
  readonly replacedBy?: Maybe<Scalars['String']['output']>;
  readonly replacedByProduct?: Maybe<Product>;
  readonly replacementFor?: Maybe<Scalars['String']['output']>;
  readonly replacementForProduct?: Maybe<Product>;
  readonly replacements?: Maybe<ReadonlyArray<Maybe<ProductReplacement>>>;
  readonly sample?: Maybe<Product>;
  readonly shadeName?: Maybe<Scalars['String']['output']>;
  readonly showOnSite?: Maybe<Scalars['Boolean']['output']>;
  readonly subscriptionBenefitBanner?: Maybe<SubscriptionBenefitBanner>;
  readonly tags?: Maybe<ReadonlyArray<Maybe<Tag>>>;
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  readonly toolingBanners?: Maybe<ReadonlyArray<Maybe<ProductToolingBanner>>>;
  readonly videoUrl?: Maybe<Scalars['String']['output']>;
  readonly volume?: Maybe<Scalars['Float']['output']>;
  readonly volumeText?: Maybe<Scalars['String']['output']>;
  readonly volumeUnit?: Maybe<Scalars['String']['output']>;
  readonly volumeUnitText?: Maybe<Scalars['String']['output']>;
  readonly volumeValue?: Maybe<Scalars['Float']['output']>;
};


export type ProductBenefitCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPurchaseCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductRelationsArgs = {
  relationTypes?: InputMaybe<ReadonlyArray<ProductRelationType>>;
};

export type ProductAvailability = {
  readonly __typename?: 'ProductAvailability';
  readonly expectedInbound?: Maybe<Scalars['DateTime']['output']>;
  readonly isBackorder?: Maybe<Scalars['Boolean']['output']>;
  readonly isBundle?: Maybe<Scalars['Boolean']['output']>;
  readonly isComingSoon?: Maybe<Scalars['Boolean']['output']>;
  readonly isLastChance?: Maybe<Scalars['Boolean']['output']>;
  readonly isLowStock?: Maybe<Scalars['Boolean']['output']>;
  readonly isNew?: Maybe<Scalars['Boolean']['output']>;
  readonly isNoLongerForSale?: Maybe<Scalars['Boolean']['output']>;
  readonly isNotSoldOnline?: Maybe<Scalars['Boolean']['output']>;
  readonly isOffer?: Maybe<Scalars['Boolean']['output']>;
  readonly isOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  readonly isSalesLimited?: Maybe<Scalars['Boolean']['output']>;
  readonly isSuperDeal?: Maybe<Scalars['Boolean']['output']>;
  readonly replacements?: Maybe<ReadonlyArray<Maybe<ProductReplacement>>>;
};

export type ProductDetailMakeupTryOnConfiguration = {
  readonly __typename?: 'ProductDetailMakeupTryOnConfiguration';
  readonly allowsLiveCameraLabel?: Maybe<Scalars['String']['output']>;
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
  readonly shiShiComponentUrl?: Maybe<Scalars['String']['output']>;
  readonly tryItOnLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailMetadata = {
  readonly __typename?: 'ProductDetailMetadata';
  readonly jsonLd?: Maybe<Scalars['JSON']['output']>;
};

export type ProductDetailPage = {
  readonly __typename?: 'ProductDetailPage';
  readonly breadcrumbLinks?: Maybe<BreadcrumbLinks>;
  readonly configuration: ProductDetailPageConfiguration;
  readonly editorialCarousel?: Maybe<EditorialCarousel>;
  readonly guarantees: ProductDetailPageGuarantees;
  readonly metadata: ProductDetailMetadata;
  readonly product?: Maybe<Product>;
  readonly sections?: Maybe<ProductDetailPageSectionsContent>;
  readonly toolingBanners: ReadonlyArray<ProductToolingBanner>;
};

export type ProductDetailPageAboutSection = {
  readonly __typename?: 'ProductDetailPageAboutSection';
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageAdditionalInfoSection = {
  readonly __typename?: 'ProductDetailPageAdditionalInfoSection';
  readonly expiryDate?: Maybe<ProductDetailPageExpirationDate>;
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly manufacturers?: Maybe<ProductDetailPageManufacturers>;
};

export type ProductDetailPageConfiguration = {
  readonly __typename?: 'ProductDetailPageConfiguration';
  readonly isMrpStatementEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isSharingEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly maxQuantity?: Maybe<Scalars['Int']['output']>;
  readonly sections?: Maybe<ProductDetailPageSections>;
  readonly shippingInfo?: Maybe<ProductDetailShippingInfo>;
};

export type ProductDetailPageExpirationDate = {
  readonly __typename?: 'ProductDetailPageExpirationDate';
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageGuarantees = {
  readonly __typename?: 'ProductDetailPageGuarantees';
  readonly freeDelivery?: Maybe<FreeDelivery>;
  readonly paymentMethods: ReadonlyArray<PaymentMethod>;
  readonly timeGuarantees?: Maybe<TimeGuarantees>;
};

export type ProductDetailPageIngredient = {
  readonly __typename?: 'ProductDetailPageIngredient';
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageManufacturer = {
  readonly __typename?: 'ProductDetailPageManufacturer';
  readonly address1?: Maybe<Scalars['String']['output']>;
  readonly address2?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly countryCode?: Maybe<Scalars['String']['output']>;
  readonly countryName?: Maybe<Scalars['String']['output']>;
  readonly manufacturerId?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly state?: Maybe<Scalars['String']['output']>;
  readonly zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPageManufacturers = {
  readonly __typename?: 'ProductDetailPageManufacturers';
  readonly countryOfOriginLabel?: Maybe<Scalars['String']['output']>;
  readonly informationLabel?: Maybe<Scalars['String']['output']>;
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly manufacturers?: Maybe<ReadonlyArray<Maybe<ProductDetailPageManufacturer>>>;
};

export type ProductDetailPageSections = {
  readonly __typename?: 'ProductDetailPageSections';
  readonly aboutDisabled?: Maybe<Scalars['Boolean']['output']>;
  readonly additionalInformationDisabled?: Maybe<Scalars['Boolean']['output']>;
  readonly beautyEditModuleDisabled?: Maybe<Scalars['Boolean']['output']>;
  readonly howToUseDisabled?: Maybe<Scalars['Boolean']['output']>;
  readonly ingredientsDisabled?: Maybe<Scalars['Boolean']['output']>;
  readonly ratingAndReviewsDisabled?: Maybe<Scalars['Boolean']['output']>;
  readonly shippingInfoDisabled?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductDetailPageSectionsContent = {
  readonly __typename?: 'ProductDetailPageSectionsContent';
  readonly about?: Maybe<ProductDetailPageAboutSection>;
  readonly additionalInformation?: Maybe<ProductDetailPageAdditionalInfoSection>;
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<ProductDetailPageIngredient>>>;
};

export type ProductDetailShippingInfo = {
  readonly __typename?: 'ProductDetailShippingInfo';
  readonly content?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ProductExpectedInbound = {
  readonly __typename?: 'ProductExpectedInbound';
  readonly expectedInboundDate?: Maybe<Scalars['DateTime']['output']>;
  readonly expectedInboundLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductFormattedPrice = {
  readonly __typename?: 'ProductFormattedPrice';
  readonly bp?: Maybe<Scalars['Float']['output']>;
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  readonly price?: Maybe<FormattedPrice>;
};

export type ProductLabel = {
  readonly __typename?: 'ProductLabel';
  readonly helpText?: Maybe<Scalars['String']['output']>;
  readonly labelKey: Scalars['ID']['output'];
  readonly productLabelDomain?: Maybe<ProductLabelDomainType>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export enum ProductLabelDomainType {
  Deal = 'Deal',
  Ppa = 'PPA',
  Shopping = 'Shopping'
}

export type ProductList = {
  readonly __typename?: 'ProductList';
  readonly created?: Maybe<Scalars['DateTime']['output']>;
  readonly items?: Maybe<ReadonlyArray<Maybe<ProductListItem>>>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly productListId: Scalars['ID']['output'];
  readonly productListType?: Maybe<ProductListType>;
};

export type ProductListItem = {
  readonly __typename?: 'ProductListItem';
  readonly itemCode?: Maybe<Scalars['String']['output']>;
};

export enum ProductListType {
  Favorite = 'Favorite',
  Personal = 'Personal',
  Private = 'Private',
  Wishlist = 'Wishlist'
}

export type ProductListingBanner = {
  readonly __typename?: 'ProductListingBanner';
  readonly contentVerticalAlignment?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageHoverUrl?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isUpperCase: Scalars['Boolean']['output'];
  readonly link?: Maybe<Link>;
  readonly size: Scalars['Int']['output'];
  readonly textColour?: Maybe<Scalars['String']['output']>;
};

export enum ProductListingBannerVideoType {
  Mp4 = 'Mp4',
  None = 'None',
  Youku = 'Youku',
  Youtube = 'Youtube'
}

export type ProductListingPage = {
  readonly __typename?: 'ProductListingPage';
  readonly contentCollection?: Maybe<ReadonlyArray<Maybe<ProductListingPageContent>>>;
  readonly desription?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly pageId: Scalars['ID']['output'];
  readonly relatedEditorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  readonly title?: Maybe<Scalars['String']['output']>;
};


export type ProductListingPageRelatedEditorialPagesArgs = {
  channelType: EditorialChannelType;
};

export type ProductListingPageContent = Product | ProductListingPromoBanner;

export type ProductListingProduct = {
  readonly __typename?: 'ProductListingProduct';
  readonly pricePosX: Scalars['Int']['output'];
  readonly pricePosY: Scalars['Int']['output'];
  readonly product?: Maybe<Product>;
};

export type ProductListingPromoBanner = {
  readonly __typename?: 'ProductListingPromoBanner';
  readonly productListingBanner?: Maybe<ProductListingBanner>;
  readonly productListingProduct?: Maybe<ProductListingProduct>;
  readonly productListingVideo?: Maybe<ProductListingVideo>;
};

export type ProductListingVideo = {
  readonly __typename?: 'ProductListingVideo';
  readonly autoplayOnScroll: Scalars['Boolean']['output'];
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly playInLoop: Scalars['Boolean']['output'];
  readonly type: ProductListingBannerVideoType;
};

export type ProductPermission = {
  readonly __typename?: 'ProductPermission';
  readonly canBuy?: Maybe<Scalars['Boolean']['output']>;
  readonly canDisplay?: Maybe<Scalars['Boolean']['output']>;
  readonly canSell?: Maybe<Scalars['Boolean']['output']>;
  readonly productCode?: Maybe<Scalars['String']['output']>;
};

export type ProductPrice = {
  readonly __typename?: 'ProductPrice';
  readonly bp?: Maybe<Scalars['Float']['output']>;
  readonly currency?: Maybe<Scalars['String']['output']>;
  readonly dateCalculated?: Maybe<Scalars['DateTime']['output']>;
  readonly price?: Maybe<Price>;
};

export type ProductRecommendation = {
  readonly __typename?: 'ProductRecommendation';
  readonly recommendedProduct?: Maybe<Product>;
  /** @deprecated Use ProductCode from RecommendedProduct instead. */
  readonly recommendedProductCode?: Maybe<Scalars['ID']['output']>;
  readonly similarity?: Maybe<Scalars['Float']['output']>;
};

export type ProductRelation = {
  readonly __typename?: 'ProductRelation';
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly relationType?: Maybe<ProductRelationEnum>;
};

export enum ProductRelationEnum {
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related'
}

export enum ProductRelationType {
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related',
  Undefined = 'Undefined'
}

export type ProductReplacement = {
  readonly __typename?: 'ProductReplacement';
  readonly product?: Maybe<Product>;
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type ProductSearchPage = {
  readonly __typename?: 'ProductSearchPage';
  readonly products: ProductSearchPageProducts;
};


export type ProductSearchPageProductsArgs = {
  facetFiltering?: InputMaybe<ReadonlyArray<PlpRowsFacetFilterInput>>;
  orderBy?: PlpOrderBy;
  query: Scalars['String']['input'];
  skipRows?: Scalars['Int']['input'];
  topRows?: Scalars['Int']['input'];
};

export type ProductSearchPageProducts = {
  readonly __typename?: 'ProductSearchPageProducts';
  readonly facets: ReadonlyArray<PlpFacet>;
  readonly orderByOptions: ReadonlyArray<PlpOrderingDefinition>;
  readonly rows: ReadonlyArray<PlpRow>;
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type ProductSearchPreview = {
  readonly __typename?: 'ProductSearchPreview';
  readonly searchResults: ProductSearchResults;
};


export type ProductSearchPreviewSearchResultsArgs = {
  query: Scalars['String']['input'];
  top?: Scalars['Int']['input'];
};

export type ProductSearchResults = {
  readonly __typename?: 'ProductSearchResults';
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly suggestions: ReadonlyArray<Scalars['String']['output']>;
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type ProductStandards = {
  readonly __typename?: 'ProductStandards';
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly images?: Maybe<ReadonlyArray<Maybe<FrontPageImage>>>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductToolingBanner = {
  readonly __typename?: 'ProductToolingBanner';
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Bundle = 'Bundle',
  Composite = 'Composite',
  Fee = 'Fee',
  Product = 'Product',
  Sample = 'Sample'
}

export type ProductsPromotionBanner = {
  readonly __typename?: 'ProductsPromotionBanner';
  readonly fontColor: StorybookColor;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly application?: Maybe<Application>;
  readonly beautyStore?: Maybe<PersonalBeautyStore>;
  readonly categories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  readonly category?: Maybe<Category>;
  readonly concept?: Maybe<Concept>;
  readonly concepts?: Maybe<ReadonlyArray<Maybe<Concept>>>;
  readonly customer?: Maybe<Customer>;
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<Ingredient>>>;
  readonly inviteFriendProfile?: Maybe<InviteFriendProfile>;
  readonly languages?: Maybe<ReadonlyArray<Maybe<Language>>>;
  readonly mobileApps?: Maybe<MobileApps>;
  readonly newsletterSubsciptionConfiguration?: Maybe<NewsletterSubsciptionConfiguration>;
  readonly product?: Maybe<Product>;
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  readonly shopping?: Maybe<Shopping>;
  readonly socialMediaLinks?: Maybe<ReadonlyArray<Maybe<SocialMediaLinks>>>;
  readonly vipCustomerBanner?: Maybe<VipCustomerBanner>;
};


export type QueryBeautyStoreArgs = {
  name?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCategoriesArgs = {
  taxonomyIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryCategoryArgs = {
  taxonomyId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryConceptArgs = {
  conceptCode?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryConceptsArgs = {
  conceptCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIngredientsArgs = {
  ingredientNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInviteFriendProfileArgs = {
  consultantNumber: Scalars['Int']['input'];
};


export type QueryProductArgs = {
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductsArgs = {
  productCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};

export type ReferralProgram = {
  readonly __typename?: 'ReferralProgram';
  readonly allowedContacts?: Maybe<ReadonlyArray<SocialContactType>>;
  readonly benefitsLink?: Maybe<Link>;
  readonly greetingMessage?: Maybe<Scalars['String']['output']>;
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ResponseMessageNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'ResponseMessageNewsletterSubsciptionConfiguration';
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<ResponseMessageNewsletterSubsciptionsType>;
};

export enum ResponseMessageNewsletterSubsciptionsType {
  Cleaned = 'Cleaned',
  New = 'New',
  Pending = 'Pending',
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed'
}

export type SalesBoosterQualificationInfo = {
  readonly __typename?: 'SalesBoosterQualificationInfo';
  readonly actualBp: Scalars['Float']['output'];
  readonly bonusAmount: Scalars['Float']['output'];
  readonly bonusAmountDifference?: Maybe<Scalars['Float']['output']>;
  readonly bpConditionsFulfilled: Scalars['Boolean']['output'];
  readonly dashboardImage?: Maybe<Scalars['String']['output']>;
  readonly firstLevelBvRateReward: Scalars['Float']['output'];
  readonly firstLevelMemberSubtypeBvRateReward: Scalars['Float']['output'];
  readonly firstLineMemberBp: Scalars['Float']['output'];
  readonly firstLineMemberBpPercentage: Scalars['Float']['output'];
  readonly level?: Maybe<Scalars['Int']['output']>;
  readonly maxAmountCapPersonal: Scalars['Float']['output'];
  readonly maxAmountCapTotal: Scalars['Float']['output'];
  readonly maxBpFromFirstLineMembers: Scalars['Float']['output'];
  readonly pageTopImage?: Maybe<Scalars['String']['output']>;
  readonly personalBp: Scalars['Float']['output'];
  readonly personalBpPercentage: Scalars['Float']['output'];
  readonly personalBvRateReward: Scalars['Float']['output'];
  readonly personalBvRateRewardDifference?: Maybe<Scalars['Float']['output']>;
  readonly showPersonalBpAlert: Scalars['Boolean']['output'];
  readonly targetBp: Scalars['Float']['output'];
  readonly termsAndConditionsLink?: Maybe<Scalars['String']['output']>;
  readonly version?: Maybe<Scalars['Int']['output']>;
  readonly youtubeVideoId?: Maybe<Scalars['String']['output']>;
};

export enum SettingsClientType {
  OnlineReportingGrid = 'OnlineReportingGrid',
  WellnessClub = 'WellnessClub'
}

export enum SettingsFormatType {
  Json = 'Json',
  String = 'String',
  Xml = 'Xml'
}

export type ShareAndEarn = {
  readonly __typename?: 'ShareAndEarn';
  readonly faqSection?: Maybe<ShareAndEarnFaqSection>;
  readonly howItWorksSection?: Maybe<ShareAndEarnHowItWorksSection>;
  readonly personalCodeEditPage?: Maybe<ShareAndEarnPersonalCodeEditPage>;
  readonly personalLinksContent?: Maybe<ShareAndEarnPersonalLinks>;
  readonly shareAndEarnSection?: Maybe<ShareAndEarnShareSection>;
  readonly sharingConditions?: Maybe<ShareAndEarnConditions>;
  readonly topSection?: Maybe<ShareAndEarnTopSection>;
};

export type ShareAndEarnConditionItem = {
  readonly __typename?: 'ShareAndEarnConditionItem';
  readonly lines?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnConditions = {
  readonly __typename?: 'ShareAndEarnConditions';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ShareAndEarnConditionItem>>>;
  readonly headline?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnFaqItem = {
  readonly __typename?: 'ShareAndEarnFaqItem';
  readonly content?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnFaqSection = {
  readonly __typename?: 'ShareAndEarnFaqSection';
  readonly collection?: Maybe<ReadonlyArray<Maybe<ShareAndEarnFaqItem>>>;
  readonly headline?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnHowItWorksSection = {
  readonly __typename?: 'ShareAndEarnHowItWorksSection';
  readonly conditionsLinkTitle?: Maybe<Scalars['String']['output']>;
  readonly earnDescription?: Maybe<Scalars['String']['output']>;
  readonly earnTitle?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly inviteDescription?: Maybe<Scalars['String']['output']>;
  readonly inviteTitle?: Maybe<Scalars['String']['output']>;
  readonly shareDescription?: Maybe<Scalars['String']['output']>;
  readonly shareTitle?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnPersonalCodeEditPage = {
  readonly __typename?: 'ShareAndEarnPersonalCodeEditPage';
  readonly buttonAppliedLabel?: Maybe<Scalars['String']['output']>;
  readonly buttonCancelLabel?: Maybe<Scalars['String']['output']>;
  readonly buttonChangeLabel?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly invalidCodeMessage?: Maybe<Scalars['String']['output']>;
  readonly yourCodeLabel?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnPersonalLinks = {
  readonly __typename?: 'ShareAndEarnPersonalLinks';
  readonly copyLabel?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly instructions?: Maybe<Scalars['String']['output']>;
  readonly links?: Maybe<ReadonlyArray<Maybe<SharingLink>>>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnShareSection = {
  readonly __typename?: 'ShareAndEarnShareSection';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly sharingCard?: Maybe<SharingCard>;
};

export type ShareAndEarnTopSection = {
  readonly __typename?: 'ShareAndEarnTopSection';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnUpdatePersonalCodeInput = {
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly newPersonalCode?: InputMaybe<Scalars['String']['input']>;
};

export type SharedAddress = {
  readonly __typename?: 'SharedAddress';
  readonly addressId: Scalars['ID']['output'];
  readonly area?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly country?: Maybe<Scalars['String']['output']>;
  readonly district?: Maybe<Scalars['String']['output']>;
  readonly locality?: Maybe<Scalars['String']['output']>;
  readonly province?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

export type SharingCard = {
  readonly __typename?: 'SharingCard';
  readonly fromLabel?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly useCodeLabel?: Maybe<Scalars['String']['output']>;
};

export type SharingLink = {
  readonly __typename?: 'SharingLink';
  readonly link?: Maybe<Link>;
  readonly tooltip?: Maybe<Scalars['String']['output']>;
};

export type SharingLinks = {
  readonly __typename?: 'SharingLinks';
  readonly currentCatalogue: Link;
  readonly otherCatalogue: Link;
};


export type SharingLinksCurrentCatalogueArgs = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type SharingLinksOtherCatalogueArgs = {
  catalogueId: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type Shopping = {
  readonly __typename?: 'Shopping';
  readonly deliveryChannel?: Maybe<DeliveryChannel>;
  readonly shoppingSession?: Maybe<ShoppingSession>;
};


export type ShoppingDeliveryChannelArgs = {
  deliveryChannelId: Scalars['Long']['input'];
};


export type ShoppingShoppingSessionArgs = {
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type ShoppingSession = {
  readonly __typename?: 'ShoppingSession';
  readonly customerId: Scalars['Long']['output'];
  readonly delivery?: Maybe<DeliverySelectionSummary>;
  readonly deliveryChannels?: Maybe<PickupPoints>;
  readonly deliveryOptions?: Maybe<DeliveryOptions>;
  readonly isAnonymous: Scalars['Boolean']['output'];
  readonly isShoppingBlocked: Scalars['Boolean']['output'];
  readonly isVip: Scalars['Boolean']['output'];
  readonly origin: OrderOrigin;
  readonly sessionId: Scalars['ID']['output'];
};


export type ShoppingSessionDeliveryChannelsArgs = {
  deliveryMethod?: InputMaybe<DeliveryMethodType>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  searchFilter?: InputMaybe<PickupSearchFilter>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};


export type ShoppingSessionDeliveryOptionsArgs = {
  filter?: DeliverySearchFilter;
  skip?: Scalars['Int']['input'];
  top?: Scalars['Int']['input'];
};

export enum ShowType {
  Ended = 'Ended',
  Live = 'Live',
  Scheduled = 'Scheduled'
}

export type SizedImage = {
  readonly __typename?: 'SizedImage';
  readonly url?: Maybe<Scalars['String']['output']>;
  readonly width?: Maybe<Scalars['Int']['output']>;
};

export type SocialContact = {
  readonly __typename?: 'SocialContact';
  readonly type?: Maybe<SocialContactType>;
  readonly value?: Maybe<Scalars['String']['output']>;
};

export enum SocialContactType {
  Email = 'Email',
  Facebook = 'Facebook',
  PhoneNumber = 'PhoneNumber',
  VKontakte = 'VKontakte',
  WeChat = 'WeChat',
  WhatsUpNumber = 'WhatsUpNumber'
}

export type SocialMediaLinks = {
  readonly __typename?: 'SocialMediaLinks';
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<SocialMediaType>;
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum SocialMediaType {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Line = 'Line',
  Odnoklassniki = 'Odnoklassniki',
  Pinterest = 'Pinterest',
  Qq = 'Qq',
  Telegram = 'Telegram',
  Twitter = 'Twitter',
  VKontakte = 'VKontakte',
  WeChat = 'WeChat',
  Weibo = 'Weibo',
  YouTube = 'YouTube',
  Youku = 'Youku'
}

export type Sponsor = {
  readonly __typename?: 'Sponsor';
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  readonly customerId?: Maybe<Scalars['Int']['output']>;
  readonly emails?: Maybe<ReadonlyArray<Maybe<Email>>>;
  readonly fullName?: Maybe<Scalars['String']['output']>;
  readonly phones?: Maybe<ReadonlyArray<Maybe<Phone>>>;
  readonly profileImage?: Maybe<DocumentInfo>;
  readonly sponsorType?: Maybe<SponsorType>;
};

export enum SponsorType {
  DirectSponsor = 'DirectSponsor'
}

export enum StorybookColor {
  Blue100 = 'Blue100',
  Brown50 = 'Brown50',
  Brown300 = 'Brown300',
  Brown400 = 'Brown400',
  CommonWhite = 'CommonWhite',
  DefaultContrastText = 'DefaultContrastText',
  DefaultMain = 'DefaultMain',
  Green50 = 'Green50',
  Green400 = 'Green400',
  Grey50 = 'Grey50',
  InfoDark = 'InfoDark',
  Pink50 = 'Pink50',
  Pink300 = 'Pink300',
  PrimaryLight = 'PrimaryLight',
  Purple50 = 'Purple50',
  Purple200 = 'Purple200',
  Purple400 = 'Purple400',
  Red50 = 'Red50',
  SecondaryMain = 'SecondaryMain',
  TextPrimary = 'TextPrimary',
  TextSecondary = 'TextSecondary',
  Undefined = 'Undefined',
  Yellow50 = 'Yellow50',
  Yellow400 = 'Yellow400'
}

export type SubmitDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'SubmitDialogNewsletterSubsciptionConfiguration';
  readonly emailLabel?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly invalidEmailLabel?: Maybe<Scalars['String']['output']>;
  readonly privacyPolicyText?: Maybe<Scalars['String']['output']>;
  readonly privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  readonly privacyPolicyUrlText?: Maybe<Scalars['String']['output']>;
  readonly subscribeButtonText?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type SubscribedDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'SubscribedDialogNewsletterSubsciptionConfiguration';
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionBenefitBanner = {
  readonly __typename?: 'SubscriptionBenefitBanner';
  readonly message?: Maybe<Scalars['String']['output']>;
  readonly readMoreLink?: Maybe<Link>;
  readonly registerLink?: Maybe<Link>;
  readonly signInLink?: Maybe<Link>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type Tag = {
  readonly __typename?: 'Tag';
  readonly groupName?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly tags?: Maybe<ReadonlyArray<Maybe<TagValue>>>;
  readonly translation?: Maybe<Scalars['String']['output']>;
};

export type TagValue = {
  readonly __typename?: 'TagValue';
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type TaxClassification = {
  readonly __typename?: 'TaxClassification';
  readonly entrepreneurDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
  readonly isEntrepreneur?: Maybe<Scalars['Boolean']['output']>;
  readonly isPhysicalPerson?: Maybe<Scalars['Boolean']['output']>;
  readonly isVatPayer?: Maybe<Scalars['Boolean']['output']>;
  readonly physicalPersonDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
  readonly vatPayerDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
};

export type TaxClassificationDocument = {
  readonly __typename?: 'TaxClassificationDocument';
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly documentNumber?: Maybe<Scalars['String']['output']>;
  readonly isActive?: Maybe<Scalars['Boolean']['output']>;
  readonly issueDate?: Maybe<Scalars['DateTime']['output']>;
  readonly taxClassificationId: Scalars['ID']['output'];
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};

export enum TelephoneType {
  Business = 'Business',
  Fax = 'Fax',
  Home = 'Home',
  Mobile = 'Mobile'
}

export type TimeGuarantees = {
  readonly __typename?: 'TimeGuarantees';
  readonly deliveryDurationText?: Maybe<Scalars['String']['output']>;
  readonly moreInfoLink?: Maybe<Link>;
};

export type TopBrands = {
  readonly __typename?: 'TopBrands';
  readonly brands?: Maybe<ReadonlyArray<Maybe<Brand>>>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export enum VerticalContentAlignment {
  Bottom = 'Bottom',
  Middle = 'Middle',
  Top = 'Top',
  Undefined = 'Undefined'
}

export type Video = {
  readonly __typename?: 'Video';
  readonly autoplayOnScroll?: Maybe<Scalars['Boolean']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly playInLoop?: Maybe<Scalars['Boolean']['output']>;
  readonly type?: Maybe<VideoType>;
};

export type VideoShopping = {
  readonly __typename?: 'VideoShopping';
  readonly configuration?: Maybe<VideoShoppingConfiguration>;
  readonly localization?: Maybe<VideoShoppingLocalization>;
};

export type VideoShoppingConfiguration = {
  readonly __typename?: 'VideoShoppingConfiguration';
  readonly isSpaLandingPageEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isVideoShoppingEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly usePreviewImage?: Maybe<Scalars['Boolean']['output']>;
};

export type VideoShoppingLocalization = {
  readonly __typename?: 'VideoShoppingLocalization';
  readonly defaultLanguage?: Maybe<Scalars['String']['output']>;
};

export type VideoShow = {
  readonly __typename?: 'VideoShow';
  readonly durationSeconds?: Maybe<Scalars['Int']['output']>;
  readonly endedAtUtc?: Maybe<Scalars['DateTime']['output']>;
  readonly isCurrent?: Maybe<Scalars['Boolean']['output']>;
  readonly previewImageUrl?: Maybe<Scalars['String']['output']>;
  readonly scheduledStartAtUtc?: Maybe<Scalars['DateTime']['output']>;
  readonly showId: Scalars['ID']['output'];
  readonly startedAtUtc?: Maybe<Scalars['DateTime']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
  readonly typeOfShow?: Maybe<ShowType>;
};

export enum VideoType {
  Mp4 = 'Mp4',
  Youku = 'Youku',
  Youtube = 'Youtube'
}

export type VipBenefits = {
  readonly __typename?: 'VipBenefits';
  readonly images?: Maybe<ReadonlyArray<Maybe<FrontPageImage>>>;
  readonly link?: Maybe<Link>;
};

export type VipCustomerBanner = {
  readonly __typename?: 'VipCustomerBanner';
  readonly linkTitle?: Maybe<Scalars['String']['output']>;
  readonly linkUrl?: Maybe<Scalars['String']['output']>;
};

export enum VisitorSegment {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  DirectorAndAbove = 'DirectorAndAbove',
  EndCustomer = 'EndCustomer',
  Manager = 'Manager',
  Member = 'Member',
  NewConsultant = 'NewConsultant',
  NotDefined = 'NotDefined',
  Spo = 'Spo'
}

export type WellnessClub = {
  readonly __typename?: 'WellnessClub';
  readonly banners?: Maybe<WellnessClubBanners>;
  readonly configuration?: Maybe<WellnessClubConfiguration>;
  readonly localization?: Maybe<WellnessClubLocalization>;
};

export type WellnessClubBanners = {
  readonly __typename?: 'WellnessClubBanners';
  readonly dailyWellbeingBannerUrl?: Maybe<Scalars['String']['output']>;
  readonly mainBannerUrl?: Maybe<Scalars['String']['output']>;
  readonly sportBannerUrl?: Maybe<Scalars['String']['output']>;
  readonly weightManagementBannerUrl?: Maybe<Scalars['String']['output']>;
};

export type WellnessClubConfiguration = {
  readonly __typename?: 'WellnessClubConfiguration';
  readonly certifiedUrl?: Maybe<Scalars['String']['output']>;
  readonly isClubMembersManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isDailyWellbeingTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isSportsTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly isWeightManagementTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type WellnessClubLocalization = {
  readonly __typename?: 'WellnessClubLocalization';
  readonly contents?: Maybe<Scalars['JSON']['output']>;
  readonly cultures?: Maybe<Scalars['JSON']['output']>;
  readonly defaultLanguage?: Maybe<Scalars['String']['output']>;
  readonly translations?: Maybe<Scalars['JSON']['output']>;
};

export type WhatsNewBanner = {
  readonly __typename?: 'WhatsNewBanner';
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  readonly headline?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Link>;
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  readonly validFrom?: Maybe<Scalars['DateTime']['output']>;
  readonly validTo?: Maybe<Scalars['DateTime']['output']>;
};
