import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useFrontpage } from '../../contexts';
import {
  MOTIVATION_CONTENT_SECTION,
  MOTIVATION_CONTENT_SECTION_DESCRIPTION,
  MOTIVATION_CONTENT_SECTION_HEADER,
} from './constants';
import { Line, Root, StyledContent, TitleWrapper, Wrapper } from './styles';

export const MotivationContentSection: FC = () => {
  const { getTestId } = useTestId();
  const {
    motivationContent: { headline, text },
  } = useFrontpage();

  return (
    <Root data-testid={getTestId(MOTIVATION_CONTENT_SECTION)}>
      <Wrapper>
        <TitleWrapper>
          <Typography
            data-testid={getTestId(MOTIVATION_CONTENT_SECTION_HEADER)}
            variant="h3"
            component="h3"
            textAlign="center"
            color="textPrimary"
          >
            {headline}
          </Typography>
        </TitleWrapper>
        <StyledContent data-testid={getTestId(MOTIVATION_CONTENT_SECTION_DESCRIPTION)}>{text}</StyledContent>
        <Line />
      </Wrapper>
    </Root>
  );
};
