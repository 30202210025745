import { gql } from '@ori/fetching';

export const BEAUTY_VIDEO_FRAGMENT = gql`
  fragment BeautyVideo on Frontpage {
    beautyVideo {
      video {
        id
        playInLoop
      }
      link {
        title
        relativeUrl
      }
    }
  }
`;
