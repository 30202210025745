export const LANDSCAPE_MD_WIDTH = 544;
export const PORTRAIT_MD_WIDTH = 300;

export const LANDSCAPE_SM_WIDTH = 360;
export const PORTRAIT_SM_WIDTH = 300;

export const BANNER_WIDTH_BY_ORIENTATION = {
  landscape: {
    md: LANDSCAPE_MD_WIDTH,
    sm: LANDSCAPE_SM_WIDTH,
  },
  portrait: {
    md: PORTRAIT_MD_WIDTH,
    sm: PORTRAIT_SM_WIDTH,
  },
};

export const LANDSCAPE_MD_HEIGHT = 380;
export const PORTRAIT_MD_HEIGHT = 453;

export const LANDSCAPE_SM_HEIGHT = 215;
export const PORTRAIT_SM_HEIGHT = 453;

export const BANNER_HEIGHT_BY_ORIENTATION = {
  landscape: {
    md: LANDSCAPE_MD_HEIGHT,
    sm: LANDSCAPE_SM_HEIGHT,
  },
  portrait: {
    md: PORTRAIT_MD_HEIGHT,
    sm: PORTRAIT_SM_HEIGHT,
  },
};
