import { styled } from '@ori-ui/mui';
import { Video as VideoComponent } from '@ori/presentation-components';

export const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginBlockEnd: theme.spacing(4),
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('sm')]: {
    marginBlockEnd: theme.spacing(12),
  },
}));

export const Video = styled(VideoComponent)({
  maxWidth: '800px',
  width: '100%',
});
