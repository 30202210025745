import { Team, TestIdProvider } from '@ori/testid-generator';
import type { ComponentType, FC, ReactNode } from 'react';
import type { OnEnter } from 'react-cool-inview';
import { useInView } from 'react-cool-inview';

import { lazyWrappersTestIds } from './lazyWrappersTestIds';

interface LazyOnScrollWrapperProps {
  Component: ComponentType;
  section: keyof typeof lazyWrappersTestIds;
  shouldRender: boolean;
  skeleton: ReactNode;
}

// istanbul ignore next -- no need to test unobserve.
const onEnter: OnEnter = ({ unobserve }) => unobserve();

export const LazyWrapper: FC<LazyOnScrollWrapperProps> = ({ Component, section, shouldRender, skeleton }) => {
  const { observe, inView } = useInView({ rootMargin: '50px', onEnter });

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      ref={observe}
      data-testid={lazyWrappersTestIds[section]}
    >
      <TestIdProvider
        team={Team.Presentation}
        project={section}
      >
        {inView ? <Component /> : skeleton}
      </TestIdProvider>
    </div>
  );
};
