import { BestOffersSectionSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';

import { LazyWrapper } from './LazyWrapper';

const DynamicBestOffersApp = dynamic(
  async () => (await import(/* webpackChunkName: "ori_best-offers-component" */ '@ori/frontpage')).BestOffersSection,
  {
    ssr: true,
    loading: () => <BestOffersSectionSkeleton />,
  },
);

export const LazyBestOffersSection = () => {
  const {
    bestOffers: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicBestOffersApp}
      section="best-offers"
      shouldRender={shouldRender}
      skeleton={<BestOffersSectionSkeleton />}
    />
  );
};
