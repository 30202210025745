import { styled } from '@ori-ui/mui';

import { Root as BannerRoot } from '../Banner/styles';

export const Root = styled(BannerRoot)(({ theme }) => ({
  paddingTop: 0,
  zIndex: -1,
  svg: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    left: 0,
    transform: 'none',
  },
}));
