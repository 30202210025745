import { useToken } from '@ori/auth';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerWithProductsAndButton } from '../../components';
import { BANNER_ROOT_PLACEHOLDER_RIGHT } from '../../constants';
import { useFrontpage } from '../../contexts';
import { useTranslations } from '../../hooks';
import { BestOffersPlaceholder } from '../../images';
import {
  BEST_OFFERS_MAX_PRODUCTS_TO_DISPLAY,
  BEST_OFFERS_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
} from '../BestOffersSectionSkeleton';

export const BestOffersSection: FC = () => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const {
    bestOffers: { banner, productBoxes },
    hideRating,
  } = useFrontpage();
  const { customerId } = useToken();

  return (
    <BannerWithProductsAndButton
      displayCircle
      header={{ title: translations.bestOffers }}
      button={{
        title: translations.viewOffers,
        href: '/focus',
        alignment: 'left',
      }}
      banner={{
        banner,
        alignment: 'right',
        bannerOrientation: 'portrait',
      }}
      bannerPlaceholder={{
        image: <BestOffersPlaceholder testId={getTestId(BANNER_ROOT_PLACEHOLDER_RIGHT)} />,
      }}
      products={{
        hideRating,
        maxProductsToDisplay: BEST_OFFERS_MAX_PRODUCTS_TO_DISPLAY,
        maxProductsToDisplayMobile: BEST_OFFERS_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
        productBoxes,
        customerId,
      }}
    />
  );
};
