import { styled } from '@ori-ui/mui';

import { VIP_BENEFIT_ICON_DESKTOP_SIZE, VIP_BENEFIT_ICON_MOBILE_SIZE } from './constants';

export const Root = styled('img')(({ theme }) => ({
  height: VIP_BENEFIT_ICON_MOBILE_SIZE,
  padding: 6,
  width: VIP_BENEFIT_ICON_MOBILE_SIZE,

  [theme.breakpoints.up('sm')]: {
    height: VIP_BENEFIT_ICON_DESKTOP_SIZE,
    padding: 7,
    width: VIP_BENEFIT_ICON_DESKTOP_SIZE,
  },
}));
