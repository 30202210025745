import { gql } from '@ori/fetching';

export const VIP_BENEFITS_FRAGMENT = gql`
  fragment VipBenefits on Frontpage {
    vipBenefits {
      link {
        title
        relativeUrl
      }
      images {
        title
        subTitle
        url
      }
    }
  }
`;
