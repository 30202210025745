import type { Brand, TopBrands, TopBrandsFragment } from '../models';

export const getBrands = (brands: NonNullable<TopBrandsFragment['topBrands']>['brands']): Brand[] =>
  (brands ?? [])
    .map(
      (brand): Brand => ({
        imageUrl: brand?.logo?.[0]?.url ?? '',
        name: brand?.name ?? '',
        url: brand?.url ?? '',
      }),
    )
    .filter((brand) => !!brand.imageUrl)
    .slice(0, 4);

export const shouldRenderTopBrands = ({ brands }: Pick<TopBrands, 'brands'>): boolean => brands.length > 0;

export const getTopBrands = (data: TopBrandsFragment['topBrands']): TopBrands => {
  const parsedData: Pick<TopBrands, 'brands' | 'title'> = {
    brands: getBrands(data?.brands),
    title: data?.title ?? '',
  };

  return {
    ...parsedData,
    shouldRender: shouldRenderTopBrands(parsedData),
  };
};
