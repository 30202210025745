import type { BeautyVideo, BeautyVideoFragment } from '../models';

export const shouldRenderBeautyVideo = ({ relativeUrl, title, videoId }: Omit<BeautyVideo, 'shouldRender'>): boolean =>
  !!videoId && !!relativeUrl && !!title;

export const getBeautyVideo = (data: BeautyVideoFragment['beautyVideo']): BeautyVideo => {
  const parsedData: Omit<BeautyVideo, 'shouldRender'> = {
    relativeUrl: data?.link?.relativeUrl ?? '',
    title: data?.link?.title ?? '',
    videoId: data?.video?.id ?? '',
    videoPlayInLoop: data?.video?.playInLoop ?? false,
  };

  return {
    ...parsedData,
    shouldRender: shouldRenderBeautyVideo(parsedData),
  };
};
