import { useBannerVisibilityToggle } from './useBannerVisibilityToggle';
import { useGetNotificationBanner } from './useGetNotificationBanner';

export type UseNotificationBannerControllerApiVisible = {
  visible: true;
  messageLinkTitle: string;
  messageLinkUrl: string;
  messageText: string;
  onCloseClick: () => void;
};

export type UseNotificationBannerControllerApiHidden = { visible: false };

export type UseNotificationBannerControllerApi =
  | UseNotificationBannerControllerApiHidden
  | UseNotificationBannerControllerApiVisible;

export const useNotificationBannerController = (): UseNotificationBannerControllerApi => {
  const { notificationBanner } = useGetNotificationBanner();
  const { showBanner, toggleBannerVisibility } = useBannerVisibilityToggle();

  if (!notificationBanner || !showBanner) {
    return {
      visible: false,
    };
  }

  return {
    visible: true,
    messageLinkTitle: notificationBanner.messageLinkTitle ?? '',
    messageLinkUrl: notificationBanner.messageLinkUrl ?? '',
    messageText: notificationBanner.messageText ?? '',
    onCloseClick: toggleBannerVisibility,
  };
};
