/* istanbul ignore file */
import { mainLogger } from '../../logger';
import type { SessionStorageLike } from './SessionStorageLike';

const localLogger = mainLogger.createChild('ServerSessionStorage');

export class ServerSessionStorage implements SessionStorageLike {
  getItem(key: string): string | null {
    return null;
  }

  removeItem(key: string): void {
    localLogger.warn(`Tried remove sessionStorage key “${key}” even though environment is not a client`);
  }

  setItem(key: string, value: string): void {
    localLogger.warn(`Tried setting sessionStorage key “${key}” even though environment is not a client`);
  }
}
