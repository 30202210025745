import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import type { VipBenefitsImage } from '../../models';
import { VipBenefitIcon } from '../VipBenefitIcon';
import { VIP_BENEFIT } from './constants';
import { Content, Root } from './styles';

export const VipBenefit: FC<VipBenefitsImage> = ({ title, subTitle, url }) => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(VIP_BENEFIT)}>
      <VipBenefitIcon url={url} />
      <Content>
        <Typography
          variant="h6"
          component="h6"
        >
          {title}
        </Typography>
        <Typography>{subTitle}</Typography>
      </Content>
    </Root>
  );
};
