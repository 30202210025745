import { gql } from '@ori/fetching';

export const MOTIVATION_CONTENT_FRAGMENT = gql`
  fragment MotivationContent on Frontpage {
    motivationContent {
      headline
      text
    }
  }
`;
