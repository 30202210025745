/* eslint-disable @typescript-eslint/naming-convention -- Needed. */
import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

export const Root = styled('ul', { shouldForwardProp: shouldForwardProp() })<{ productCount: number }>(
  ({ theme, productCount }) => ({
    gridArea: 'products',
    display: 'grid',
    gap: theme.spacing(3, 2),
    gridTemplateColumns: `repeat(2, 1fr)`,
    listStyle: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      'li:nth-child(3)': {
        display: 'none',
      },
      'li:nth-child(4)': {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: `repeat(3, 1fr)`,
      'li:nth-child(3)': {
        display: 'list-item',
      },
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: `repeat(${productCount}, 1fr)`,
      'li:nth-child(4)': {
        display: 'list-item',
      },
    },
  }),
);
