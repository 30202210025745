import { gql } from '@ori/fetching';

export const CATEGORY_NAVIGATION_FRAGMENT = gql`
  fragment CategoryNavigation on Frontpage {
    categoryNavigation {
      title
      relativeUrl
    }
  }
`;
