import { gql } from '@ori/fetching';

export const TOP_BRANDS_FRAGMENT = gql`
  fragment TopBrands on Frontpage {
    topBrands {
      brands {
        name
        logo {
          url
        }
        url
      }
      title
    }
  }
`;
