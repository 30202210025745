import { styled, Typography, type TypographyProps } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '4rem',
  maxWidth: 1346,
  alignItems: 'center',
  textAlign: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  order: 0,
  gridArea: 'title',
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
}));

export const Description = styled(Typography)(({ theme }) => ({
  minHeight: 45,
  textAlign: 'center',
  gridArea: 'title',
  maxWidth: 600,
  width: '100%',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,

  [theme.breakpoints.up('sm')]: {
    maxWidth: 350,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 500,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 600,
  },
}));

export const Title = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  minWidth: '70%',
  [theme.breakpoints.between('md', 1367)]: {
    marginLeft: theme.spacing(4),
  },
}));
