import useTestId from '@ori/testid-generator';

import { Root as HeaderRoot } from '../../../BannerWithProductsAndButton/components/Header/styles';
import { HEADER_SKELETON, HEADER_DESCRIPTION_SKELETON } from './constants';
import { CaptionSkeleton, DescriptionWrapper, TitleSkeleton } from './styles';
import { type HeaderSkeletonProps } from './types';

export const HeaderSkeleton = ({ hasDescription }: HeaderSkeletonProps) => {
  const { getTestId } = useTestId();

  return (
    <HeaderRoot data-testid={getTestId(HEADER_SKELETON)}>
      <TitleSkeleton />
      {hasDescription ? (
        <DescriptionWrapper data-testid={getTestId(HEADER_DESCRIPTION_SKELETON)}>
          <CaptionSkeleton width="100%" />
          <CaptionSkeleton width="60%" />
        </DescriptionWrapper>
      ) : null}
    </HeaderRoot>
  );
};
