import useTestId from '@ori/testid-generator';

import { BANNER_SKELETON_LEFT, BANNER_SKELETON_RIGHT } from './constants';
import { BannerSkeletonRoot, InnerSkeleton } from './styles';
import { type BannerSkeletonProps } from './types';

export const BannerSkeleton = ({ alignment, bannerOrientation }: BannerSkeletonProps) => {
  const { getTestId } = useTestId();

  return (
    <BannerSkeletonRoot
      bannerOrientation={bannerOrientation}
      alignment={alignment}
      data-testid={getTestId(alignment === 'left' ? BANNER_SKELETON_LEFT : BANNER_SKELETON_RIGHT)}
    >
      <InnerSkeleton />
    </BannerSkeletonRoot>
  );
};
