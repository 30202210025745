import * as BannerClick from '@ori-events/banner-click';
import type { PositionData } from '@ori/analytics-data';
import { Placement } from '@ori/analytics-data';
import useTestId from '@ori/testid-generator';
import type { KeyboardEvent, FC } from 'react';
import { useCallback } from 'react';

import type { HeroBannerFragment } from '../../models';
import { analyticsBannersMapper } from '../../utils';
import { CarouselBannerContent } from './CarouselBannerContent';
import { CAROUSEL_BANNER_ROOT } from './constants';
import { StyledBanner } from './styles';

export type CarouselBannerProps = Pick<
  HeroBannerFragment & PositionData,
  'fontColor' | 'headline' | 'imageUrl' | 'link' | 'mobileFontColor' | 'position' | 'smallImageUrl' | 'tagline'
>;

export const CarouselBanner: FC<CarouselBannerProps> = ({
  headline,
  imageUrl,
  link,
  position,
  tagline,
  smallImageUrl,
  fontColor,
  mobileFontColor,
}) => {
  const { getTestId } = useTestId();
  const dispatchAnalyticsEvent = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent<BannerClick.Data>(BannerClick.Name, {
        detail: {
          banners: analyticsBannersMapper([{ headline, imageUrl, link }]),
          placement: Placement.HeroBanner,
          position,
        },
      }),
    );
  }, [headline, imageUrl, link, position]);
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        dispatchAnalyticsEvent();
      }
    },
    [dispatchAnalyticsEvent],
  );

  return (
    <StyledBanner
      data-testid={getTestId(CAROUSEL_BANNER_ROOT)}
      {...(link?.relativeUrl
        ? {
            href: link.relativeUrl,
          }
        : {
            tabIndex: -1,
            role: 'button',
            onKeyDown,
          })}
      onClick={dispatchAnalyticsEvent}
    >
      <CarouselBannerContent
        headline={headline}
        imageUrl={imageUrl}
        link={link}
        tagline={tagline}
        smallImageUrl={smallImageUrl}
        fontColor={fontColor}
        mobileFontColor={mobileFontColor}
        position={position}
      />
    </StyledBanner>
  );
};
