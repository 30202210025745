import { useToken } from '@ori/auth';
import type { FC } from 'react';

import { BannerWithProductsAndButton } from '../../components';
import { BANNER_ROOT_PLACEHOLDER_LEFT } from '../../constants';
import { useFrontpage } from '../../contexts';
import { useTranslations } from '../../hooks';
import { BestSellersPlaceholder } from '../../images/bestSellersPlaceholder';
import { BEST_SELLER_PRODUCTS_TO_DISPLAY_MOBILE, BEST_SELLER_PRODUCTS_TO_DISPLAY_DESKTOP } from './constants';

export const BestsellerProductsSection: FC = () => {
  const { translations } = useTranslations();
  const {
    bestsellerProducts: { banner, headline, productBoxes },
    hideRating,
  } = useFrontpage();
  const { customerId } = useToken();

  return (
    <BannerWithProductsAndButton
      header={{ title: headline ?? translations.bestSellers }}
      button={{
        title: translations.viewAll,
        href: '/bestsellers',
        alignment: 'right',
      }}
      bannerPlaceholder={{
        image: <BestSellersPlaceholder testId={BANNER_ROOT_PLACEHOLDER_LEFT} />,
      }}
      banner={{
        banner,
        alignment: 'left',
        bannerOrientation: 'portrait',
      }}
      products={{
        hideRating,
        maxProductsToDisplay: BEST_SELLER_PRODUCTS_TO_DISPLAY_DESKTOP,
        maxProductsToDisplayMobile: BEST_SELLER_PRODUCTS_TO_DISPLAY_MOBILE,
        productBoxes,
        customerId,
      }}
    />
  );
};
