import { isSSR } from '@ori/presentation-utils';

import { ServerSessionStorage } from './ServerSessionStorage';
import { SessionStoreRepository } from './SessionStoreRepository';

export const createSessionStoreRepository = () => {
  if (isSSR()) {
    return new SessionStoreRepository(new ServerSessionStorage());
  }

  return new SessionStoreRepository(window.sessionStorage);
};
