import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { UnifiedSkeleton } from '../../components';
import { CAMPAIGN_BANNER_SECTION_SKELETON } from './constants';
import { ButtonSkeleton, DetailsRoot, Headline, InnerRoot, Root, Text } from './styles';

export const CampaignBannerSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(CAMPAIGN_BANNER_SECTION_SKELETON)}>
      <InnerRoot>
        <UnifiedSkeleton />
        <DetailsRoot>
          <Headline
            width={350}
            height={37}
          />
          <Text width="100%" />
          <Text width="100%" />
          <Text width="65%" />
          <ButtonSkeleton
            width={138}
            height={45}
          />
        </DetailsRoot>
      </InnerRoot>
    </Root>
  );
};
