import { capitalize, useTheme } from '@ori-ui/mui';
import type { PositionData } from '@ori/analytics-data';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import type { HeroBannerFragment } from '../../models';
import { CarouselImage } from './CarouselImage';
import { CAROUSEL_BANNER_BUTTON, CAROUSEL_BANNER_HEADLINE, CAROUSEL_BANNER_TAGLINE } from './constants';
import { ButtonWrapper, Headline, StyledButton, TagLine, TextBox } from './styles';

export type CarouselBannerContentProps = Pick<
  HeroBannerFragment & PositionData,
  'fontColor' | 'headline' | 'imageUrl' | 'link' | 'mobileFontColor' | 'smallImageUrl' | 'tagline' | 'position'
>;

export const CarouselBannerContent: FC<CarouselBannerContentProps> = ({
  headline,
  imageUrl,
  link,
  tagline,
  smallImageUrl,
  fontColor,
  mobileFontColor,
  position,
}) => {
  const { getTestId } = useTestId();
  const theme = useTheme();

  return (
    <>
      <CarouselImage
        headline={headline}
        imageUrl={imageUrl}
        smallImageUrl={smallImageUrl}
        position={position}
      />
      <TextBox
        desktopColor={mapBackendColorToTheme({
          theme,
          backendColor: capitalize(fontColor ?? ''),
          fallBackColor: 'inherit',
        })}
        mobileColor={mapBackendColorToTheme({
          theme,
          backendColor: capitalize(mobileFontColor ?? ''),
          fallBackColor: 'inherit',
        })}
      >
        {tagline ? <TagLine data-testid={getTestId(CAROUSEL_BANNER_TAGLINE)}>{tagline}</TagLine> : null}
        {headline ? (
          <Headline
            data-testid={getTestId(CAROUSEL_BANNER_HEADLINE)}
            variant="h3"
            component="h3"
          >
            {headline}
          </Headline>
        ) : null}
        {link?.relativeUrl && link.title ? (
          <ButtonWrapper>
            <StyledButton
              data-testid={getTestId(CAROUSEL_BANNER_BUTTON)}
              variant="contained"
              color="bannerButton"
            >
              {link.title}
            </StyledButton>
          </ButtonWrapper>
        ) : null}
      </TextBox>
    </>
  );
};
