import { gql } from '@ori/fetching';

export const HERO_BANNER_FRAGMENT = gql`
  fragment HeroBanner on HeroBanner {
    fontColor
    mobileFontColor
    headline
    imageUrl
    link {
      relativeUrl
      title
    }
    priority
    smallImageUrl
    tagline
  }
`;
