import { styled, Typography } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 3, 20, 3),
}));

export const Wrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(4),
}));

export const StyledTitle = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '4rem',
  letterSpacing: '0px',
  opacity: 1,
  textAlign: 'center',
}));

export const TitleWrapper = styled(StyledTitle)({
  maxWidth: '718px',
});

export const Description = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },

  letterSpacing: '0px',
  maxWidth: '600px',
  opacity: 1,
  textAlign: 'center',
}));

export const DescriptionWrapper = styled('div')(({ theme }) => ({
  maxWidth: '600px',
  position: 'relative',
}));

export const ImagesWrapper = styled('div')(({ theme }) => ({
  alignContent: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  position: 'relative',
  gap: theme.spacing(4, 1),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(4, 4),
  },
}));
