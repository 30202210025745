// istanbul ignore file -- Will be replaced with TopAreaBanner
import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useGraphqlQuery } from '@ori/fetching';
import { useHeaders } from '@ori/presentation-http';
import { vipCustomerBannerQuery } from '@ori/vip-customer-banner';

import { useFrontpage } from '../contexts';
import type {
  GetVipCustomerBannerQuery,
  GetVipCustomerBannerQueryVariables,
  VipCustomerBannerFragment,
} from '../models';

const CACHE_CONTROL = 'Cache-Control';

interface UseVipCustomerBannerDataParams {
  enabled: boolean;
}

interface UseVipCustomerBannerData {
  loading: boolean;
  vipCustomerBannerData: VipCustomerBannerFragment['vipCustomerBanner'];
}

export const useVipCustomerBannerData = ({ enabled }: UseVipCustomerBannerDataParams): UseVipCustomerBannerData => {
  const { isAnonymousUser } = useToken();
  const { graphQlNetUrl } = useServiceUrls(['graphQlNet']);
  const { headers, loading } = useHeaders();
  const skip = loading || !enabled || isAnonymousUser;
  const { data, error } = useGraphqlQuery<GetVipCustomerBannerQuery, GetVipCustomerBannerQueryVariables>({
    query: vipCustomerBannerQuery,
    skip,
    url: graphQlNetUrl,
    headers: {
      ...headers,
      [CACHE_CONTROL]: 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  const { vipCustomerBanner } = useFrontpage();

  return {
    loading: !skip && !data && !error,
    vipCustomerBannerData: isAnonymousUser ? vipCustomerBanner : data?.vipCustomerBanner,
  };
};
