import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { TOP_BRANDS_SECTION_SKELETON } from './constants';
import { ButtonSkeleton, ItemSkeleton, ItemsWrap, Root, SkeletonWrapper, TitleSkeleton } from './styles';

export const TopBrandsSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(TOP_BRANDS_SECTION_SKELETON)}>
      <TitleSkeleton />
      <ItemsWrap>
        <SkeletonWrapper>
          <ItemSkeleton />
        </SkeletonWrapper>
        <SkeletonWrapper>
          <ItemSkeleton />
        </SkeletonWrapper>
        <SkeletonWrapper>
          <ItemSkeleton />
        </SkeletonWrapper>
        <SkeletonWrapper>
          <ItemSkeleton />
        </SkeletonWrapper>
      </ItemsWrap>
      <ButtonSkeleton />
    </Root>
  );
};
