import useTestId from '@ori/testid-generator';
import range from 'lodash.range';
import type { FC } from 'react';

import { Root, Wrapper } from '../ProductStandardsSection/styles';
import { PRODUCT_STANDARDS_SECTION_SKELETON } from './constants';
import { DescriptionSkeleton, ItemSkeleton, ItemsWrap, TitleSkeleton } from './styles';

export const ProductStandardsSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(PRODUCT_STANDARDS_SECTION_SKELETON)}>
      <Wrapper>
        <TitleSkeleton />
        <DescriptionSkeleton />
        <ItemsWrap>
          {range(6).map((key) => (
            <ItemSkeleton key={key} />
          ))}
        </ItemsWrap>
      </Wrapper>
    </Root>
  );
};
