import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BEAUTY_VIDEO_SECTION_SKELETON } from './constants';
import { ButtonSkeleton, Root, VideoSkeleton } from './styles';

export const BeautyVideoSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(BEAUTY_VIDEO_SECTION_SKELETON)}>
      <VideoSkeleton />
      <ButtonSkeleton />
    </Root>
  );
};
