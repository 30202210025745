import { useToken } from '@ori/auth';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerWithProductsAndButton } from '../../components';
import { BANNER_ROOT_PLACEHOLDER_LEFT } from '../../constants';
import { useFrontpage } from '../../contexts';
import { CategoryListingPlaceholder } from '../../images';
import { CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY, CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY_MOBILE } from './constants';

export const CategoryListingSection: FC = () => {
  const { getTestId } = useTestId();
  const { customerId } = useToken();
  const {
    categoryListing: { banner, productBoxes },
    hideRating,
  } = useFrontpage();
  const { headline, shortText, link } = banner ?? {};

  return (
    <BannerWithProductsAndButton
      header={{ title: headline ?? '', description: shortText }}
      button={{
        title: link?.title ?? '',
        href: link?.relativeUrl ?? '',
        alignment: 'right',
      }}
      banner={{
        banner,
        alignment: 'left',
        bannerOrientation: 'landscape',
      }}
      bannerPlaceholder={{
        image: <CategoryListingPlaceholder testId={getTestId(BANNER_ROOT_PLACEHOLDER_LEFT)} />,
      }}
      products={{
        hideRating,
        maxProductsToDisplay: CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY,
        maxProductsToDisplayMobile: CATEGORY_LISTING_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
        productBoxes,
        customerId,
      }}
    />
  );
};
