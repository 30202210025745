import { Skeleton, styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginBlockEnd: theme.spacing(12),
  [theme.breakpoints.up('sm')]: {
    marginBlockEnd: theme.spacing(20),
  },
}));

export const UnifiedSkeleton = styled(Skeleton)({ transform: 'none' });
