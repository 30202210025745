import { CategoryBar } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useFrontpage } from '../../contexts';
import { CATEGORY_NAVIGATION } from './constants';
import { Root } from './styles';

export const CategoryNavigation: FC = () => {
  const { getTestId } = useTestId();
  const { categoryNavigationItems } = useFrontpage();
  if (categoryNavigationItems.length === 0) {
    return null;
  }

  return (
    <Root data-testid={getTestId(CATEGORY_NAVIGATION)}>
      <CategoryBar linkData={categoryNavigationItems} />
    </Root>
  );
};
