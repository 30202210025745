import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BenefitBanner, Carousel, HeroBannerMetadata, VipCustomerBanner } from '../../components';
import { HERO_BANNER_SECTION, HERO_BANNER_SECTION_SKELETON } from './constants';
import { BannerWrapper, Root, Top } from './styles';

export const HeroBannerSection: FC = () => {
  const { getTestId } = useTestId();

  const skeleton = <Root data-testid={getTestId(HERO_BANNER_SECTION_SKELETON)} />;

  return (
    <>
      <HeroBannerMetadata />
      <Root data-testid={getTestId(HERO_BANNER_SECTION)}>
        <Top>
          <BannerWrapper>
            <BenefitBanner />
            <VipCustomerBanner />
          </BannerWrapper>
        </Top>
        <Carousel skeleton={skeleton} />
      </Root>
    </>
  );
};
