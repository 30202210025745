import type { ResponsiveImageVariant } from './getResponsiveImageVariants';

export const getMediaQueryForRange = ({ from, to }: Pick<ResponsiveImageVariant, 'from' | 'to'>) => {
  const mediaQueries: string[] = [];

  if (from !== undefined) {
    mediaQueries.push(`(min-width: ${from}px)`);
  }

  if (to !== undefined) {
    mediaQueries.push(`(max-width: ${to}px)`);
  }

  return mediaQueries.join(' and ');
};
