import { styled } from '@ori-ui/mui';

import { HERO_BANNER_SECTION_MOBILE_HEIGHT } from './constants';

export const Root = styled('div')(({ theme }) => ({
  margin: 0,
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: HERO_BANNER_SECTION_MOBILE_HEIGHT,
  [theme.breakpoints.up('sm')]: {
    height: 'auto',
  },
}));

export const Top = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10,
  width: '100%',
});

export const BannerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  [theme.breakpoints.up('sm')]: {
    flexWrap: 'nowrap',
  },
}));
