import { gql } from '@ori/fetching';

export const NOTIFICATION_BANNER_FRAGMENT = gql`
  fragment NotificationBanner on Frontpage {
    notificationBanner {
      messageLinkTitle
      messageLinkUrl
      messageText
    }
  }
`;
