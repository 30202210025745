import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BEAUTY_VIDEO_BUTTON } from './constants';
import { Root } from './styles';

export interface BeautyVideoButtonProps {
  relativeUrl: string;
  title: string;
}

export const BeautyVideoButton: FC<BeautyVideoButtonProps> = ({ relativeUrl, title }) => {
  const { getTestId } = useTestId();

  return (
    <Root
      href={relativeUrl}
      data-testid={getTestId(BEAUTY_VIDEO_BUTTON)}
      variant="outlined"
      color="default"
    >
      {title}
    </Root>
  );
};
