import type { BannerData } from '@ori/analytics-data';
import type { BestOffersBanner, Maybe } from '@ori/graphql-schema';

export const analyticsBannersMapper = (banners: Array<Maybe<BestOffersBanner>>): BannerData[] =>
  banners
    .filter((banner) => banner !== null)
    .map(({ headline, imageUrl, link }) => ({
      localBannerTitle: headline ?? '',
      media: imageUrl ? [imageUrl] : [],
      target: link?.relativeUrl ?? '',
    }));
