import { useTheme } from '@ori-ui/mui';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { generateSrcSet, getImageVariants } from '@ori/image-sizes-helper';
import { useMemo, type FC } from 'react';

import type { FrontpageImage } from '../../../../models';
import { PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE, PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE } from './constants';
import { Root } from './styles';

export type ImageProps = Pick<FrontpageImage, 'title' | 'url'>;

export const Image: FC<ImageProps> = ({ url, title }) => {
  const theme = useTheme();
  const imagesVariants = useMemo<ImageSizesHelperParams>(
    () => ({
      url,
      backgroundColor: theme.palette.grey[100],
      imageFormat: 'WebP',
      params: [
        {
          width: PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE,
          quality: 95,
        },
        {
          width: PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE,
          quality: 95,
        },
      ],
    }),
    [theme.palette.grey, url],
  );

  return (
    <Root
      alt={title}
      loading="lazy"
      sizes={`(max-width: ${theme.breakpoints.values.sm - 1}px) ${PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE}px, ${PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE}px`}
      srcSet={generateSrcSet(getImageVariants(imagesVariants))}
    />
  );
};
