import { BestsellerProductsSectionSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';

import { LazyWrapper } from './LazyWrapper';

const DynamicBestsellerProductsApp = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ori_best-seller-component" */ '@ori/frontpage')).BestsellerProductsSection,
  {
    ssr: true,
    loading: () => <BestsellerProductsSectionSkeleton />,
  },
);

export const LazyBestsellerProductsSection = () => {
  const {
    bestsellerProducts: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicBestsellerProductsApp}
      section="best-seller"
      shouldRender={shouldRender}
      skeleton={<BestsellerProductsSectionSkeleton />}
    />
  );
};
