import { defaultLayoutOptionalServices, defaultLayoutRequiredServices } from '@ori-appshell/layouts';
import type { PickBaseServiceUrls, Service } from '@ori/configuration-manager';

export type FrontPageClientServiceUrls = Partial<PickBaseServiceUrls<(typeof frontPageOptionalServices)[number]>> &
  PickBaseServiceUrls<(typeof frontPageRequiredServices)[number]>;

export const frontPageRequiredServices = [...defaultLayoutRequiredServices] satisfies Service[];

export const frontPageOptionalServices = [
  // add all services required by the used layout
  ...defaultLayoutOptionalServices,
] satisfies Service[];
