import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import { type AlignmentTypes } from '../../types';
import { UnifiedSkeleton } from '../styles';

export const ViewMoreButtonSkeleton = styled(UnifiedSkeleton, { shouldForwardProp: shouldForwardProp() })<{
  alignment: AlignmentTypes;
}>(({ theme, alignment }) => ({
  borderRadius: theme.spacing(5),
  fontSize: '1.4rem',
  height: '45px',
  textAlign: 'center',
  gridArea: 'title',
  alignSelf: 'center',
  justifySelf: 'center',
  margin: theme.spacing(1, 'auto'),
  order: 4,
  width: '133px',
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'end',
    justifySelf: alignment === 'left' ? 'start' : 'end',
    margin: 0,
    order: 'unset',
  },
}));

interface ProductsListWrapperProps {
  maxProductsToDisplayMobile: number;
  maxProductsToDisplay: number;
}
export const ProductsListWrapper = styled('div', { shouldForwardProp: shouldForwardProp() })<ProductsListWrapperProps>(
  ({ theme, maxProductsToDisplayMobile, maxProductsToDisplay }) => ({
    gridArea: 'products',
    display: 'grid',
    gap: theme.spacing(3, 2),
    gridTemplateColumns: `repeat(2, 1fr)`,
    listStyle: 'none',
    width: '100%',
    [`& >:nth-of-type(n + ${maxProductsToDisplayMobile + 1})`]: {
      display: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: `repeat(${maxProductsToDisplay}, 1fr)`,
      [`& >:nth-of-type(n + ${maxProductsToDisplayMobile + 1})`]: {
        display: 'block',
      },
    },
  }),
);
