import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../../../styles';

export const TitleSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  width: '150px',
  height: '35px',
  [theme.breakpoints.up('sm')]: {
    width: '240px',
    height: '48px',
  },
}));

export const DescriptionWrapper = styled('div')(({ theme }) => ({
  maxWidth: 600,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const CaptionSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  height: '1.4rem',
}));
