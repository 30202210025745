import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { UnifiedSkeleton } from '../../components';
import { Line, Wrapper, Root, TitleWrapper } from '../MotivationContentSection/styles';
import { MOTIVATION_CONTENT_SKELETON } from './constants';
import { TextWrap, TitleSkeleton } from './styles';

export const MotivationContentSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(MOTIVATION_CONTENT_SKELETON)}>
      <Wrapper>
        <TitleWrapper>
          <TitleSkeleton
            height={49}
            width={200}
          />
        </TitleWrapper>
        <TextWrap>
          <UnifiedSkeleton
            variant="text"
            width="100%"
          />
          <UnifiedSkeleton
            variant="text"
            width="100%"
          />
          <UnifiedSkeleton
            variant="text"
            width="100%"
          />
          <UnifiedSkeleton
            variant="text"
            width="100%"
          />
          <UnifiedSkeleton
            variant="text"
            width="30%"
          />
        </TextWrap>
        <Line />
      </Wrapper>
    </Root>
  );
};
