import { Clear } from '@ori-ui/icons';
import { styled, Typography } from '@ori-ui/mui';
import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useEnvironment } from '@ori/environment';
import { getHeaders, useGraphqlQuery } from '@ori/fetching';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { PACKAGE_NAME } from '../constants-generated';
import type { GetVipCustomerBannerQuery, GetVipCustomerBannerQueryVariables } from '../models/graphql-types/net';
import { vipCustomerBannerQuery } from '../queries-net/vipCustomerBanner';
import { getCookie, setCookie } from '../utils';

// kept styles desktop first as banner will be soon deprecated
export const Root = styled('div')(({ theme }) => ({
  background: theme.palette.purple[100],
  height: 50,
  justifyContent: 'flex-end',
  left: 0,
  opacity: 0.8,
  position: 'absolute',
  top: 'var(--top-nav-height)',
  width: '100%',
  zIndex: 100,
}));

export const TextWrapperLink = styled('a')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontWeight: theme.typography.fontWeightBold,
  height: '100%',
  width: '100%',
}));

export const BannerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '22px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  },
}));

export const ClearIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: 'pointer',
  fontSize: '2.7rem',
  height: 50,
  margin: theme.spacing('auto', 3),
  position: 'absolute',
  right: 0,
  top: 0,
  transition: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
}));

export const VipCustomerBanner: FC = () => {
  const COOKIE_VISITOR_NAME = 'ecref';
  const COOKIE_OWNER_NAME = 'ecref_owner';
  const COOKIE_BANNER_HIDDEN = 'ecref_hidden';
  const PAGES_WITHOUT_BANNER: string[] = ['/shopping/', '/products/digital-catalogue-current'];
  const QUERY_STRING_PARAMETER = 'invite';

  const { getTestId } = useTestId();
  const { tenant } = useEnvironment();
  const { language } = useTranslationsWithNamespaces();
  const { graphQlNetUrl } = useServiceUrls(['graphQlNet']);
  const { customerId, token } = useToken();
  const { data } = useGraphqlQuery<GetVipCustomerBannerQuery, GetVipCustomerBannerQueryVariables>({
    query: vipCustomerBannerQuery,
    url: graphQlNetUrl,
    headers: {
      ...getHeaders({
        tenant,
        token,
        customerId,
        language,
        sourceAppName: PACKAGE_NAME,
      }),
    },
  });

  const [shouldDisplayBanner, setShouldDisplayBanner] = useState(false);

  useEffect(() => {
    const cookieVisitorExists =
      getCookie(COOKIE_VISITOR_NAME) !== '' &&
      getCookie(COOKIE_VISITOR_NAME) !== 'null' &&
      getCookie(COOKIE_VISITOR_NAME) !== null;
    const cookieOwnerExists =
      getCookie(COOKIE_OWNER_NAME) !== '' &&
      getCookie(COOKIE_OWNER_NAME) !== 'null' &&
      getCookie(COOKIE_OWNER_NAME) !== null;
    const cookieBannerHidden = getCookie(COOKIE_BANNER_HIDDEN) === 'true';
    const isValidPageAddress = PAGES_WITHOUT_BANNER.every((page) => !window.location.pathname.includes(page));

    if (cookieVisitorExists) {
      const url = new URL(window.location.href);
      url.searchParams.set(QUERY_STRING_PARAMETER, String(getCookie(COOKIE_VISITOR_NAME)));
      window.history.replaceState({}, '', url.toString());
    }

    setShouldDisplayBanner((cookieVisitorExists || cookieOwnerExists) && isValidPageAddress && !cookieBannerHidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- false positive
  }, []);

  const onClick = useCallback(() => {
    setCookie(COOKIE_BANNER_HIDDEN, true);
    setShouldDisplayBanner(false);
  }, []);

  if (!shouldDisplayBanner || !data?.vipCustomerBanner) {
    return null;
  }

  return (
    /* eslint-disable @typescript-eslint/no-unnecessary-condition -- False positive */
    <Root data-testid={getTestId('vip-customer-banner')}>
      <TextWrapperLink href={String(data?.vipCustomerBanner?.linkUrl)}>
        <BannerText>{data?.vipCustomerBanner?.linkTitle}</BannerText>
      </TextWrapperLink>
      <ClearIcon
        fontSize="inherit"
        onClick={onClick}
      />
    </Root>
    /* eslint-enable @typescript-eslint/no-unnecessary-condition */
  );
};
