import useTestId from '@ori/testid-generator';

import { HEADER_DESCRIPTION, HEADER_ROOT, HEADER_TITLE } from '../../../../constants';
import { Description, Root, Title } from './styles';
import type { HeaderProps } from './types';

export const Header = ({ title, description }: HeaderProps) => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(HEADER_ROOT)}>
      <Title
        data-testid={getTestId(HEADER_TITLE)}
        variant="h3"
        component="h3"
        textAlign="center"
        color="textPrimary"
      >
        {title}
      </Title>
      {description ? <Description data-testid={getTestId(HEADER_DESCRIPTION)}>{description}</Description> : null}
    </Root>
  );
};
