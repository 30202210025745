export const generateSrcSetWithDensity = (url: string): string => {
  const urlParams = new URL(url).searchParams;
  const width = urlParams.get('w');

  if (!width) {
    return url;
  }

  const densityMap = [
    { density: 1, url },
    { density: 2, url: url.replace(`&w=${width}`, `&w=${Number(width) * 2}`) },
  ];

  return densityMap.map((size) => `${size.url} ${size.density}x`).join(',');
};
