import * as BannerClick from '@ori-events/banner-click';
import { Placement } from '@ori/analytics-data';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useFrontpage } from '../../contexts';
import { analyticsBannersMapper } from '../../utils';
import { CAMPAIGN_BANNER_DETAILS, CAMPAIGN_BANNER_DETAILS_DESCRIPTION } from './constants';
import { ButtonWrapper, Description, Root, StyledButton, Title } from './styles';

export const CampaignBannerDetails: FC = () => {
  const { getTestId } = useTestId();
  const { campaignBanner } = useFrontpage();
  const { headline, imageUrl, link, shortText } = campaignBanner ?? {};
  const { title, relativeUrl } = link ?? {};
  const onClick = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent<BannerClick.Data>(BannerClick.Name, {
        detail: {
          banners: analyticsBannersMapper([{ headline, imageUrl, link }]),
          placement: Placement.CampaignBanner,
          position: 0,
        },
      }),
    );
  }, [headline, imageUrl, link]);

  if (!relativeUrl || !title) {
    return null;
  }

  return (
    <Root data-testid={getTestId(CAMPAIGN_BANNER_DETAILS)}>
      <Title
        component="h3"
        variant="h3"
      >
        {headline}
      </Title>
      <Description data-testid={getTestId(CAMPAIGN_BANNER_DETAILS_DESCRIPTION)}>
        {shortText?.replaceAll('\u00A0', ' ') ?? null}
      </Description>
      <ButtonWrapper>
        <StyledButton
          href={relativeUrl}
          variant="outlined"
          color="default"
          onClick={onClick}
        >
          {title}
        </StyledButton>
      </ButtonWrapper>
    </Root>
  );
};
