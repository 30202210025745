import { MotivationContentSkeleton, useFrontpage } from '@ori/frontpage/';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import { LazyWrapper } from './LazyWrapper';

const DynamicMotivationContent = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ori_motivation-content-component" */ '@ori/frontpage'))
      .MotivationContentSection,
  {
    ssr: true,
    loading: () => <MotivationContentSkeleton />,
  },
);

export const LazyMotivationContent: FC = () => {
  const {
    motivationContent: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicMotivationContent}
      section="motivation-content"
      shouldRender={shouldRender}
      skeleton={<MotivationContentSkeleton />}
    />
  );
};
