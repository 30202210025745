import { gql } from '@ori/fetching';

export const PRODUCT_PROMOTION_BANNER_FRAGMENT = gql`
  fragment ProductPromotionBanner on ProductsPromotionBanner {
    fontColor
    headline
    imageUrl
    link {
      relativeUrl
      title
    }
  }
`;
