import type { MotivationContent, MotivationContentFragment } from '../models';

export const shouldRenderMotivationContent = ({
  headline,
  text,
}: Pick<MotivationContent, 'headline' | 'text'>): boolean => !!headline && !!text;

export const getMotivationContent = (data: MotivationContentFragment['motivationContent']): MotivationContent => {
  const parsedData: Pick<MotivationContent, 'headline' | 'text'> = {
    headline: data?.headline ?? '',
    text: data?.text ?? '',
  };

  return {
    ...parsedData,
    shouldRender: shouldRenderMotivationContent(parsedData),
  };
};
