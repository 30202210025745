import { useRef } from 'react';

export const useInstance = <T>(factory: () => T): T => {
  // use ref to persist value between re-renders
  const ref = useRef<T>();

  if (ref.current === undefined) {
    ref.current = factory();
  }

  return ref.current;
};
