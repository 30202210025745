// istanbul ignore file -- Will be replaced with TopAreaBanner
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useCallback, useEffect, useState, useMemo } from 'react';

import { useVipCustomerBannerData } from '../../hooks';
import { cookieExists, getCookie, setCookie } from '../../utils';
import { VIP_CUSTOMER_BANNER } from './constants';
import { Root, StyledClearIcon, Text, TextWrapper } from './styles';

const COOKIE_VISITOR_NAME = 'ecref';
const COOKIE_OWNER_NAME = 'ecref_owner';
const COOKIE_BANNER_HIDDEN = 'ecref_hidden';
const QUERY_STRING_PARAMETER = 'invite';

export const VipCustomerBanner: FC = () => {
  const { cookieVisitorExists, cookieOwnerExists, cookieBannerHidden } = useMemo(() => {
    if (typeof window !== 'undefined') {
      const visitorExists = cookieExists(COOKIE_VISITOR_NAME);
      const ownerExists = cookieExists(COOKIE_OWNER_NAME);
      const bannerHidden = getCookie(COOKIE_BANNER_HIDDEN) === 'true';

      return {
        cookieVisitorExists: visitorExists,
        cookieOwnerExists: ownerExists,
        cookieBannerHidden: bannerHidden,
      };
    }

    return { cookieVisitorExists: false, cookieOwnerExists: false, cookieBannerHidden: false };
  }, []);

  const [shouldDisplayBanner, setShouldDisplayBanner] = useState(
    (cookieVisitorExists || cookieOwnerExists) && !cookieBannerHidden,
  );

  const { getTestId } = useTestId();
  const { vipCustomerBannerData, loading } = useVipCustomerBannerData({ enabled: shouldDisplayBanner });

  useEffect(() => {
    if (cookieVisitorExists) {
      const url = new URL(window.location.href);
      url.searchParams.set(QUERY_STRING_PARAMETER, String(getCookie(COOKIE_VISITOR_NAME)));
      window.history.replaceState({}, '', url.toString());
    }
  }, [cookieVisitorExists]);

  const onClick = useCallback(() => {
    setCookie(COOKIE_BANNER_HIDDEN, true);
    setShouldDisplayBanner(false);
  }, []);

  if (loading || !shouldDisplayBanner || !vipCustomerBannerData) {
    return null;
  }

  return (
    <Root data-testid={getTestId(VIP_CUSTOMER_BANNER)}>
      <TextWrapper href={String(vipCustomerBannerData.linkUrl)}>
        <Text>{vipCustomerBannerData.linkTitle}</Text>
      </TextWrapper>
      <StyledClearIcon
        fontSize="inherit"
        onClick={onClick}
      />
    </Root>
  );
};
