import { useTheme } from '@ori-ui/mui';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { generateSrcSet, getImageVariants } from '@ori/image-sizes-helper';
import useTestId from '@ori/testid-generator';
import { useMemo, type FC } from 'react';

import type { VipBenefitsImage } from '../../models';
import { VIP_BENEFIT_ICON, VIP_BENEFIT_ICON_DESKTOP_SIZE, VIP_BENEFIT_ICON_MOBILE_SIZE } from './constants';
import { Root } from './styles';

export const VipBenefitIcon: FC<Pick<VipBenefitsImage, 'url'>> = ({ url }) => {
  const { getTestId } = useTestId();
  const theme = useTheme();
  const imagesVariants = useMemo<ImageSizesHelperParams>(
    () => ({
      url,
      backgroundColor: theme.palette.grey[100],
      params: [
        {
          height: VIP_BENEFIT_ICON_MOBILE_SIZE,
          width: VIP_BENEFIT_ICON_MOBILE_SIZE,
          quality: 90,
        },
        {
          height: VIP_BENEFIT_ICON_DESKTOP_SIZE,
          width: VIP_BENEFIT_ICON_DESKTOP_SIZE,
          quality: 90,
        },
      ],
    }),
    [theme.palette.grey, url],
  );

  return (
    <Root
      alt=""
      data-testid={getTestId(VIP_BENEFIT_ICON)}
      sizes={`(max-width: ${theme.breakpoints.values.sm - 1}px) ${VIP_BENEFIT_ICON_MOBILE_SIZE}px, ${VIP_BENEFIT_ICON_DESKTOP_SIZE}px`}
      srcSet={generateSrcSet(getImageVariants(imagesVariants))}
    />
  );
};
