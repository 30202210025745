import type { FC } from 'react';
import { memo } from 'react';

import { NotificationBanner as NotificationBannerComponent } from '../../components/NotificationBanner';
import { useNotificationBannerController } from './useNotificationBannerController';

export const NotificationBanner: FC = () => {
  const notificationBanner = useNotificationBannerController();

  if (!notificationBanner.visible) {
    return null;
  }

  const { messageLinkTitle, messageLinkUrl, messageText, onCloseClick } = notificationBanner;

  return (
    <NotificationBannerComponent
      messageLinkTitle={messageLinkTitle}
      messageLinkUrl={messageLinkUrl}
      messageText={messageText}
      onCloseClick={onCloseClick}
    />
  );
};

export const NotificationBannerMemo = memo(NotificationBanner);
