import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerWithProductsAndButtonSkeleton } from '../../components';
import {
  BEST_SELLERS_MAX_PRODUCTS_TO_DISPLAY,
  BEST_SELLERS_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
  BEST_SELLERS_SECTION_SKELETON,
} from './constants';

export const BestsellerProductsSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <BannerWithProductsAndButtonSkeleton
      displayCircle
      testId={getTestId(BEST_SELLERS_SECTION_SKELETON)}
      button={{
        alignment: 'left',
      }}
      banner={{
        alignment: 'right',
        bannerOrientation: 'portrait',
      }}
      products={{
        maxProductsToDisplay: BEST_SELLERS_MAX_PRODUCTS_TO_DISPLAY,
        maxProductsToDisplayMobile: BEST_SELLERS_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
      }}
    />
  );
};
