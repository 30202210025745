import type { CategoryLink } from '@ori/presentation-components';

import type { CategoryNavigationFragment } from '../models';

interface GetCategoryNavigationItemsParams {
  categoryNavigation: CategoryNavigationFragment['categoryNavigation'];
}

interface NonNullableLink {
  relativeUrl: string;
  title: string;
}

export const getCategoryNavigationItems = ({ categoryNavigation }: GetCategoryNavigationItemsParams): CategoryLink[] =>
  (categoryNavigation ?? [])
    .filter((categoryNavigationItem): categoryNavigationItem is NonNullableLink =>
      Boolean(categoryNavigationItem?.relativeUrl && categoryNavigationItem.title),
    )
    .map(
      ({ relativeUrl, title }): CategoryLink => ({
        id: relativeUrl,
        relativeUrl,
        title,
      }),
    );
