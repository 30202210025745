import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { VipBenefit, VipBenefitsButton } from '../../components';
import { useFrontpage } from '../../contexts';
import { VIP_BENEFITS_LIST, VIP_BENEFITS_SECTION } from './constants';
import { BenefitsList, Root } from './styles';

export const VipBenefitsSection: FC = () => {
  const { getTestId } = useTestId();
  const {
    vipBenefits: { images },
  } = useFrontpage();

  return (
    <Root data-testid={getTestId(VIP_BENEFITS_SECTION)}>
      <BenefitsList data-testid={getTestId(VIP_BENEFITS_LIST)}>
        {images.map((image) => (
          <VipBenefit
            key={image.title}
            subTitle={image.subTitle}
            title={image.title}
            url={image.url}
          />
        ))}
      </BenefitsList>
      <VipBenefitsButton />
    </Root>
  );
};
