import { capitalize, useTheme } from '@ori-ui/mui';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { generateSrcSet, getImageVariants } from '@ori/image-sizes-helper';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';

import {
  BANNER_BUTTON_TITLE,
  BANNER_HEADLINE,
  BANNER_IMAGE,
  BANNER_LINK,
  BANNER_ROOT_LEFT,
  BANNER_ROOT_RIGHT,
} from '../../../../constants';
import { onImageError } from '../../../../utils';
import { BANNER_WIDTH_BY_ORIENTATION } from './constants';
import { Content, HeadlineTypography, Link, Root, StyledBannerImage, StyledButton } from './styles';
import type { BannerProps } from './types';

export const Banner = ({ banner, alignment, bannerOrientation }: BannerProps) => {
  const { headline, imageUrl, link, fontColor } = banner;
  // Be is not sending mobileFontColor for all banners.
  const mobileFontColor = 'mobileFontColor' in banner ? banner.mobileFontColor : undefined;

  const { getTestId } = useTestId();
  const theme = useTheme();
  // eslint-disable-next-line unicorn/consistent-destructuring -- cannot use destructuring here because tagline is not always present
  const bannerHeadline = 'tagline' in banner ? banner.tagline : headline;

  if (!imageUrl) {
    return null;
  }

  const imagesVariants: ImageSizesHelperParams = {
    url: imageUrl,
    backgroundColor: theme.palette.grey[100],
    imageFormat: 'WebP',
    params: [
      {
        width: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md,
        quality: 95,
      },
      {
        width: BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].sm,
        quality: 95,
      },
    ],
  };

  return (
    <Root
      bannerOrientation={bannerOrientation}
      alignment={alignment}
      data-testid={getTestId(alignment === 'left' ? BANNER_ROOT_LEFT : BANNER_ROOT_RIGHT)}
    >
      <Link
        data-testid={getTestId(BANNER_LINK)}
        href={link?.relativeUrl ?? '#'}
        desktopColor={mapBackendColorToTheme({
          theme,
          backendColor: capitalize(fontColor ?? ''),
          fallBackColor: 'inherit',
        })}
        mobileColor={mapBackendColorToTheme({
          theme,
          backendColor: capitalize(mobileFontColor ?? fontColor ?? ''),
          fallBackColor: 'inherit',
        })}
      >
        <StyledBannerImage
          bannerOrientation={bannerOrientation}
          alt={(bannerHeadline === '' ? link?.title : bannerHeadline) ?? ''}
          alignment={alignment}
          data-testid={getTestId(BANNER_IMAGE)}
          sizes={`(max-width: ${theme.breakpoints.values.sm - 1}px) ${theme.breakpoints.values.sm}px, ${BANNER_WIDTH_BY_ORIENTATION[bannerOrientation].md}px`}
          srcSet={generateSrcSet(getImageVariants(imagesVariants))}
          fetchPriority="high"
          onError={onImageError}
        />
        <Content>
          {bannerHeadline ? (
            <HeadlineTypography
              data-testid={getTestId(BANNER_HEADLINE)}
              variant="h4"
              component="h4"
            >
              {bannerHeadline}
            </HeadlineTypography>
          ) : null}
          {link?.title ? (
            <StyledButton
              data-testid={getTestId(BANNER_BUTTON_TITLE)}
              color="bannerButton"
              variant="contained"
            >
              {link.title}
            </StyledButton>
          ) : null}
        </Content>
      </Link>
    </Root>
  );
};
