import type { FC } from 'react';

import { UnifiedSkeleton } from '../../components';
import { Content, Root } from '../../components/VipBenefit/styles';
import { BenefitIconSkeleton, TextWrap } from './styles';

export const VipBenefitSkeleton: FC = () => (
  <Root>
    <BenefitIconSkeleton />
    <Content>
      <UnifiedSkeleton
        width={200}
        height={30}
      />
      <TextWrap>
        <UnifiedSkeleton
          variant="text"
          width="100%"
        />
        <UnifiedSkeleton
          variant="text"
          width="90%"
        />
        <UnifiedSkeleton
          variant="text"
          width="35%"
        />
      </TextWrap>
    </Content>
  </Root>
);
