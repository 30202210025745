import { useEventCallback } from '@ori-ui/mui';
import { useCallback, useEffect, useState } from 'react';

import { createSessionStoreRepository } from '../utils/sessionStorage/factory';
import { useInstance } from './useInstance';

export type UseSessionStorageOptions = {
  initializeWithValue?: boolean;
};

export type UseSessionStorageApi<T> = {
  value: T;
  setValue: (value: T | ((val: T) => T)) => void;
};

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  options: UseSessionStorageOptions = {},
): UseSessionStorageApi<T> {
  const storage = useInstance(createSessionStoreRepository);

  const { initializeWithValue = true } = options;

  // Get from session storage then
  // parse stored json or return initialValue
  const readValue = useCallback((): T => {
    try {
      return storage.getOrCreate(key, initialValue);
    } catch (error) {
      return initialValue;
    }
  }, [initialValue, key, storage]);

  const [storedValue, setStoredValue] = useState(() => {
    if (initializeWithValue) {
      return readValue();
    }

    return initialValue;
  });

  const setValue = useEventCallback((value: T | ((val: T) => T)) => {
    try {
      setStoredValue((originalValue) => {
        const newValue = value instanceof Function ? value(originalValue) : value;
        storage.save(key, newValue);

        return newValue;
      });
    } catch (error) {
      /* empty */
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
  }, [key, readValue]);

  return {
    value: storedValue,
    setValue,
  };
}
