import { useFrontpage, VipBenefitsSectionSkeleton } from '@ori/frontpage';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import { LazyWrapper } from './LazyWrapper';

const DynamicVipBenefits = dynamic(
  async () => (await import(/* webpackChunkName: "ori_vip-benefits-component" */ '@ori/frontpage')).VipBenefitsSection,
  {
    ssr: true,
    loading: () => <VipBenefitsSectionSkeleton />,
  },
);

export const LazyVipBenefits: FC = () => {
  const {
    vipBenefits: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicVipBenefits}
      section="vip-benefits"
      shouldRender={shouldRender}
      skeleton={<VipBenefitsSectionSkeleton />}
    />
  );
};
