import { IconicProductsSectionSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';

import { LazyWrapper } from './LazyWrapper';

const DynamicIconicProductsApp = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ori_iconic-products-component" */ '@ori/frontpage')).IconicProductsSection,
  {
    ssr: true,
    loading: () => <IconicProductsSectionSkeleton />,
  },
);

export const LazyIconicProductsSection = () => {
  const {
    iconicProducts: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicIconicProductsApp}
      section="iconic-products"
      shouldRender={shouldRender}
      skeleton={<IconicProductsSectionSkeleton />}
    />
  );
};
