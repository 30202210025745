import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../../components';
import { Root as BeautyVideoSectionRoot } from '../BeautyVideoSection/styles';

export const Root = styled(BeautyVideoSectionRoot)({
  alignItems: 'center',
});

export const VideoSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  width: '100%',
  aspectRatio: '3.5 / 2',
  [theme.breakpoints.up('sm')]: {
    width: '800px',
  },
}));

export const ButtonSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  marginTop: theme.spacing(6),
  width: '266px',
  height: '45px',
}));
