import type { HeroBannerFragment } from '../models';

export const getHeroBanners = (
  banners: ReadonlyArray<HeroBannerFragment | null> | null | undefined,
): HeroBannerFragment[] => {
  if (!banners) {
    return [];
  }

  return banners
    .filter((banner) => banner !== null)
    .sort((bannerA, bannerB) => (bannerA.priority ?? 0) - (bannerB.priority ?? 0))
    .slice(0, 3);
};
