import { styled } from '@ori-ui/mui';

import { SECTION_MAX_WIDTH } from '../../constants';
import { TABLET_BANNER_BREAKPOINT } from './constants';

export const Root = styled('section')(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& *': {
    boxSizing: 'inherit',
  },
}));

export const Banner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column-reverse wrap',
  justifyContent: 'center',
  maxWidth: SECTION_MAX_WIDTH,
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up(TABLET_BANNER_BREAKPOINT)]: {
    flexFlow: 'row wrap',
  },
}));

export const Circle = styled('div')(({ theme }) => ({
  background: theme.palette.purple[100],
  borderRadius: 258,
  height: '235px',
  left: '104%',
  opacity: 0.2,
  position: 'absolute',
  top: '-15%',
  transform: 'translateX(-50%)',
  width: '235px',
  zIndex: -1,
  [theme.breakpoints.up('sm')]: {
    height: '478px',
    left: theme.spacing(-12),
    top: '21%',
    width: '478px',
  },
  [theme.breakpoints.up('xl')]: {
    left: theme.spacing(-4),
  },
}));
