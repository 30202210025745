import { mapProductBoxesFromGql } from '@ori/product-box';

import type { BestSellerProducts, BestsellerProductsFragment, CategoryListing } from '../models';

export const shouldRenderBestSellerProducts = ({ productBoxes }: Pick<CategoryListing, 'productBoxes'>): boolean =>
  productBoxes.length > 0;

export const getBestSellerProducts = (data: BestsellerProductsFragment['bestsellerProducts']): BestSellerProducts => {
  const banner = data?.banner;
  const productBoxes = mapProductBoxesFromGql([...(data?.products ?? [])]);

  return {
    banner: banner ?? null,
    headline: data?.headline ?? null,
    productBoxes,
    shouldRender: shouldRenderBestSellerProducts({ productBoxes }),
  };
};
