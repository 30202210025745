import { ProductBoxSkeleton } from '@ori/product-box';
import useTestId from '@ori/testid-generator';
import range from 'lodash.range';

import { Circle, Root } from '../BannerWithProductsAndButton/styles';
import { BannerSkeleton } from './components/BannerSkeleton';
import { HeaderSkeleton } from './components/HeaderSkeleton';
import {
  BANNER_WITH_PRODUCTS_AND_BUTTON_PRODUCT_BOX_SKELETON,
  BANNER_WITH_PRODUCTS_AND_BUTTON_PRODUCT_VIEW_MORE_BUTTON_SKELETON,
  BANNER_WITH_PRODUCTS_AND_BUTTON_SKELETON,
  BANNER_WITH_PRODUCTS_AND_BUTTON_SKELETON_CIRCLE,
} from './constants';
import { ProductsListWrapper, ViewMoreButtonSkeleton } from './styles';
import { type BannerWithProductsAndButtonSkeletonProps } from './types';

/**
 * Component responsible for displaying skeleton of various sections on front page.
 * Right now is it suitable for What's new, Best Offers, Category banner and Best seller products skeletons.
 */
export const BannerWithProductsAndButtonSkeleton = ({
  testId,
  products,
  header,
  banner,
  button,
  displayCircle,
}: BannerWithProductsAndButtonSkeletonProps) => {
  const { getTestId } = useTestId();
  const { hasDescription } = header ?? {};
  const { alignment, bannerOrientation } = banner;
  const { alignment: buttonAlignment } = button;
  const { maxProductsToDisplay, maxProductsToDisplayMobile } = products;

  return (
    <Root
      bannerOrientation={bannerOrientation}
      bannerAlignment={alignment}
      data-testid={testId ?? getTestId(BANNER_WITH_PRODUCTS_AND_BUTTON_SKELETON)}
    >
      {displayCircle ? <Circle data-testid={getTestId(BANNER_WITH_PRODUCTS_AND_BUTTON_SKELETON_CIRCLE)} /> : null}
      <HeaderSkeleton hasDescription={hasDescription} />
      <ViewMoreButtonSkeleton
        data-testid={getTestId(BANNER_WITH_PRODUCTS_AND_BUTTON_PRODUCT_VIEW_MORE_BUTTON_SKELETON)}
        alignment={buttonAlignment}
      />
      <BannerSkeleton
        bannerOrientation={bannerOrientation}
        alignment={alignment}
      />
      <ProductsListWrapper
        maxProductsToDisplayMobile={maxProductsToDisplayMobile}
        maxProductsToDisplay={maxProductsToDisplay}
      >
        {range(maxProductsToDisplay).map((index: number) => (
          <div key={index}>
            <ProductBoxSkeleton
              animation="pulse"
              data-testid={getTestId(BANNER_WITH_PRODUCTS_AND_BUTTON_PRODUCT_BOX_SKELETON)}
            />
          </div>
        ))}
      </ProductsListWrapper>
    </Root>
  );
};
