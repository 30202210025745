import { styled } from '@ori-ui/mui';

import { PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE, PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE } from './constants';

export const Root = styled('img')(({ theme }) => ({
  height: PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE,
  width: PRODUCT_STANDARDS_IMAGE_MOBILE_SIZE,
  [theme.breakpoints.up('sm')]: {
    height: PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE,
    width: PRODUCT_STANDARDS_IMAGE_DESKTOP_SIZE,
  },
}));
