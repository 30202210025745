import { CampaignBannerSectionSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import { LazyWrapper } from './LazyWrapper';

const DynamicCampaignBannerSection = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ori_campaign-banner-component" */ '@ori/frontpage')).CampaignBannerSection,
  {
    ssr: true,
    loading: () => <CampaignBannerSectionSkeleton />,
  },
);

export const LazyCampaignBannerSection: FC = () => {
  const { campaignBanner } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicCampaignBannerSection}
      section="campaign-banner"
      shouldRender={!!campaignBanner}
      skeleton={<CampaignBannerSectionSkeleton />}
    />
  );
};
