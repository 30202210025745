import { styled } from '@ori-ui/mui';
import { OlapicWidget } from '@ori/olapic-lib';

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(12),
  },
}));

export const StyledOlapicWidget = styled(OlapicWidget)(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    marginTop: 0,
    marginBottom: theme.spacing(12),
  },
}));
