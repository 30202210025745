import * as LinkClick from '@ori-events/link-click';
import { Placement } from '@ori/analytics-data';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useFrontpage } from '../../contexts';
import { VIP_BENEFITS_BUTTON } from './constants';
import { Root } from './styles';

export const VipBenefitsButton: FC = () => {
  const { getTestId } = useTestId();
  const {
    vipBenefits: { linkRelativeUrl, linkTitle },
  } = useFrontpage();
  const handleClick = useCallback(() => {
    window.dispatchEvent(
      new CustomEvent<LinkClick.Data>(LinkClick.Name, {
        detail: {
          globalName: '',
          localName: linkTitle,
          placement: Placement.VipCustomerBenefits,
          target: linkRelativeUrl,
        },
      }),
    );
  }, [linkRelativeUrl, linkTitle]);

  return (
    <Root
      color="default"
      data-testid={getTestId(VIP_BENEFITS_BUTTON)}
      href={linkRelativeUrl}
      variant="outlined"
      onClick={handleClick}
    >
      {linkTitle}
    </Root>
  );
};
