import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { NotificationBannerCloseButton } from './NotificationBannerCloseButton';
import { NotificationBannerLinkArea } from './NotificationBannerLinkArea';
import { NOTIFICATION_BANNER } from './constants';
import { Root } from './styles';
import type { NotificationBannerProps } from './types';

export const NotificationBanner: FC<NotificationBannerProps> = ({
  messageLinkTitle,
  messageLinkUrl,
  messageText,
  onCloseClick,
}) => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(NOTIFICATION_BANNER)}>
      <NotificationBannerLinkArea
        messageLinkTitle={messageLinkTitle}
        messageLinkUrl={messageLinkUrl}
        messageText={messageText}
      />

      <NotificationBannerCloseButton onCloseClick={onCloseClick} />
    </Root>
  );
};
