import { gql } from '@ori/fetching';

export const BESTSELLER_PRODUCTS_FRAGMENT = gql`
  fragment BestsellerProducts on Frontpage {
    bestsellerProducts {
      headline
      banner {
        ...ProductPromotionBanner
      }
      products {
        ...ProductWithShades
      }
    }
  }
`;
