/* istanbul ignore file */

/**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */
import { useTranslationsFromNamespace, type UseTranslationsFromNamespaceResult } from '@ori/i18n';

import type { DefaultTranslations } from '../constants/defaultTranslations';

/**
 * A hook for accessing translations within a specific namespace along with a formatting function.
 *
 * @returns The translations within the specified namespace and a formatting function.
 */
export const useTranslations: () => UseTranslationsFromNamespaceResult<DefaultTranslations> = () =>
  useTranslationsFromNamespace<DefaultTranslations>();
