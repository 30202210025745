/* istanbul ignore file */
import * as LinkClick from '@ori-events/link-click';
import { Placement } from '@ori/analytics-data';

export const onLinkClick = (name: string, url: string) => {
  window.dispatchEvent(
    new CustomEvent<LinkClick.Data>(LinkClick.Name, {
      detail: {
        globalName: '',
        localName: name,
        placement: Placement.TopBrands,
        target: url,
      },
    }),
  );
};
