import { styled } from '@ori-ui/mui';

import { Root } from '../../../BannerWithProductsAndButton/components/Banner/styles';
import { UnifiedSkeleton } from '../../../styles';

export const BannerSkeletonRoot = styled(Root)({
  padding: 0,
});

export const InnerSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
  },
}));
