import { gql } from '@ori/fetching';

export const WHATS_NEW_BANNERS_FRAGMENT = gql`
  fragment WhatsNewBanners on WhatsNewBanner {
    fontColor
    mobileFontColor
    headline
    imageUrl
    link {
      relativeUrl
      title
    }
  }
`;
