import { styled } from '@ori-ui/mui';

import { Root as BasicRoot, UnifiedSkeleton } from '../../components';

export const Root = styled(BasicRoot)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing(4),
}));

export const ItemsWrap = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(4, 2),
  gridTemplateColumns: '160px 160px',
  gridTemplateRows: '160px 160px',
  justifyContent: 'center',
  order: 3,
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(10),
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    width: '100%',
    order: 2,
    maxWidth: 950,
  },
}));

export const SkeletonWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    height: 0,
    overflow: 'hidden',
    paddingTop: '100%',
    position: 'relative',
    width: '100%',
  },
}));
export const ItemSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  width: '160px',
  height: '160px',
  borderRadius: '50%',
  position: 'static',
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

export const ButtonSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  height: theme.spacing(9),
  width: 160,
  borderRadius: theme.spacing(5),
  order: 2,
  [theme.breakpoints.up('sm')]: {
    order: 3,
  },
}));

export const TitleSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  height: 45,
  width: 140,
  order: 1,
}));
