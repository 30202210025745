import { CategoryListingSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';

import { LazyWrapper } from './LazyWrapper';

const DynamicCategoryListing = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ori_category-listing-component" */ '@ori/frontpage')).CategoryListingSection,
  {
    ssr: true,
    loading: () => <CategoryListingSkeleton />,
  },
);

export const LazyCategoryListing = () => {
  const {
    categoryListing: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicCategoryListing}
      section="category-listing"
      shouldRender={shouldRender}
      skeleton={<CategoryListingSkeleton />}
    />
  );
};
