import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BeautyVideoButton } from '../../components';
import { useFrontpage } from '../../contexts';
import { useTranslations } from '../../hooks';
import { BEAUTY_VIDEO_ROOT } from './constants';
import { Root, Video } from './styles';

export const BeautyVideoSection: FC = () => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const {
    beautyVideo: { relativeUrl, title, videoId, videoPlayInLoop },
  } = useFrontpage();

  return (
    <Root data-testid={getTestId(BEAUTY_VIDEO_ROOT)}>
      <Video
        aria-label={translations.play}
        playInLoop={videoPlayInLoop}
        src={videoId}
      />
      <BeautyVideoButton
        relativeUrl={relativeUrl}
        title={title}
      />
    </Root>
  );
};
