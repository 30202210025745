import { useTheme } from '@ori-ui/mui';
import type { PositionData } from '@ori/analytics-data';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { memo } from 'react';

import type { HeroBannerFragment } from '../../models';
import { generateSrcSetWithDensity, getMediaQueryForRange, getResponsiveImageVariants } from '../../utils';
import { CAROUSEL_IMAGE, CAROUSEL_IMAGE_ROOT, CAROUSEL_IMAGE_SKELETON } from './constants';
import { Image } from './styles';

export type CarouselImageProps = Pick<
  HeroBannerFragment & PositionData,
  'headline' | 'imageUrl' | 'smallImageUrl' | 'position'
>;

export const CarouselImage: FC<CarouselImageProps> = memo(({ headline, imageUrl, smallImageUrl, position }) => {
  const theme = useTheme();
  const { getTestId } = useTestId();
  const skeleton = (
    <Image
      data-testid={getTestId(CAROUSEL_IMAGE_SKELETON)}
      as="div"
    />
  );

  if (!imageUrl) {
    return skeleton;
  }

  // Image variants are sorted from smallest to the largest image
  // We pick first and create array from rest by deconstructing
  const [mainVariant, ...restVariants] = getResponsiveImageVariants({
    desktopUrl: imageUrl,
    mobileUrl: smallImageUrl,
    backgroundColor: theme.palette.grey[100],
  });

  // If there are no images then we show skeleton
  if (!mainVariant) {
    return skeleton;
  }

  return (
    <picture data-testid={getTestId(CAROUSEL_IMAGE_ROOT)}>
      {restVariants.map(({ url, from, to }) => (
        <source
          key={`${url}${from}${to}`}
          srcSet={generateSrcSetWithDensity(url)}
          media={getMediaQueryForRange({ from, to })}
        />
      ))}
      <Image
        data-testid={getTestId(CAROUSEL_IMAGE)}
        alt={headline ?? ''}
        src={(restVariants.at(1) ?? mainVariant).url}
        loading={position === 0 ? 'eager' : 'lazy'}
        decoding={position === 0 ? 'auto' : 'async'}
        fetchPriority={position === 0 ? 'high' : 'low'}
      />
    </picture>
  );
});
