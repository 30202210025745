import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../../components';
import { VIP_BENEFIT_ICON_DESKTOP_SIZE, VIP_BENEFIT_ICON_MOBILE_SIZE } from '../../components/VipBenefitIcon/constants';

export const TextWrap = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    justifyItems: 'center',
  },
}));

export const ButtonSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  marginTop: theme.spacing(3),
  alignSelf: 'center',
  height: '45px',
  borderRadius: theme.spacing(5),
}));

export const BenefitIconSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  height: VIP_BENEFIT_ICON_MOBILE_SIZE,
  margin: 6,
  width: VIP_BENEFIT_ICON_MOBILE_SIZE,

  [theme.breakpoints.up('sm')]: {
    height: VIP_BENEFIT_ICON_DESKTOP_SIZE,
    margin: 7,
    width: VIP_BENEFIT_ICON_DESKTOP_SIZE,
  },
}));
