import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { CampaignBannerDetails, CampaignBannerImage } from '../../components';
import { CAMPAIGN_BANNER_SECTION } from './constants';
import { Banner, Circle, Root } from './styles';

export const CampaignBannerSection: FC = () => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(CAMPAIGN_BANNER_SECTION)}>
      <Banner>
        <CampaignBannerImage />
        <CampaignBannerDetails />
        <Circle />
      </Banner>
    </Root>
  );
};
