export const setCookie = (cookieName: string, cookieValue: unknown, expirationDays?: number) => {
  if (expirationDays === undefined) {
    document.cookie = `${cookieName}=${cookieValue};path=/`;

    return;
  }

  const actualTimeInMiliseconds = Date.now();
  const expirationDaysInMilliSeconds = expirationDays * 24 * 60 * 60 * 1000;
  const expirationTime = new Date(actualTimeInMiliseconds + expirationDaysInMilliSeconds);

  document.cookie = `${cookieName}=${cookieValue};expires=${expirationTime.toUTCString()};path=/`;
};

export const getCookie = (cookieName: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);

  return parts.length === 2 ? parts.pop()?.split(';').shift() : null;
};
