// View more button
export const VIEW_MORE_BUTTON_LEFT = 'button-left';
export const VIEW_MORE_BUTTON_RIGHT = 'button-right';

// Header
export const HEADER_ROOT = 'header';
export const HEADER_TITLE = 'title';
export const HEADER_DESCRIPTION = 'description';

// Banner
export const BANNER_ROOT_LEFT = 'banner-left';
export const BANNER_ROOT_RIGHT = 'banner-right';
export const BANNER_LINK = 'link';
export const BANNER_IMAGE = 'image';
export const BANNER_BUTTON_TITLE = 'button-title';
export const BANNER_HEADLINE = 'headline';

// BannerPlaceholder
export const BANNER_ROOT_PLACEHOLDER_LEFT = 'banner-placeholder-left';
export const BANNER_ROOT_PLACEHOLDER_RIGHT = 'banner-placeholder-right';

// Products
export const PRODUCTS_ROOT = 'products';
export const PRODUCTS_BOX = 'product-box';
