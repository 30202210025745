import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../../components';

export const TitleSkeleton = styled(UnifiedSkeleton)({
  maxWidth: '490px',
  width: '100%',
});

export const TextWrap = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
  padding: theme.spacing(0, 3),
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '600px',
    padding: 0,
  },
}));
