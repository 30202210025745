import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../../components';
import { SECTION_MAX_WIDTH } from '../../constants';
import { TABLET_BANNER_BREAKPOINT } from '../CampaignBannerSection/constants';

export const Root = styled('div')(({ theme }) => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  marginBlockEnd: theme.spacing(20),
  overflow: 'hidden',
  padding: theme.spacing(8, 0),
  width: '100%',
  '& *': {
    boxSizing: 'inherit',
  },
  [theme.breakpoints.up('sm')]: {
    overflow: 'visible',
  },
}));

export const InnerRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyContent: 'center',
  position: 'relative',
  maxWidth: SECTION_MAX_WIDTH,
  width: '100%',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '250px 238px',
  [theme.breakpoints.up(TABLET_BANNER_BREAKPOINT)]: {
    gridTemplateColumns: '0.5fr 0.5fr',
    gridTemplateRows: '434px',
  },
}));

export const ButtonSkeleton = styled(UnifiedSkeleton)(({ theme }) => ({
  justifySelf: 'center',
  marginBottom: theme.spacing(6),
  marginTop: theme.spacing(6),
  textTransform: 'uppercase',
  borderRadius: theme.spacing(5),
  [theme.breakpoints.up(TABLET_BANNER_BREAKPOINT)]: {
    marginBottom: 0,
    justifySelf: 'start',
  },
}));

export const DetailsRoot = styled('div')(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  gridRow: 1,
  textAlign: 'center',
  [theme.breakpoints.up(TABLET_BANNER_BREAKPOINT)]: {
    gridTemplateColumns: '73%',
    textAlign: 'start',
    gridRow: 'auto',
  },
}));

export const Headline = styled(UnifiedSkeleton)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}));

export const Text = styled(UnifiedSkeleton)(({ theme }) => ({
  height: '19px',
  marginTop: theme.spacing(2),
}));
