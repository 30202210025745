import { gql } from '@ori/fetching';

export const PRODUCT_STANDARDS_FRAGMENT = gql`
  fragment ProductStandards on Frontpage {
    productStandards {
      headline
      text
      images {
        title
        subTitle
        url
      }
    }
  }
`;
