import { Logger } from '@ori/logger';

import { PACKAGE_NAME, TEAM_NAME } from './constants-generated';

export const mainLogger = new Logger(PACKAGE_NAME);

export const frontpageMainLogger = new Logger(PACKAGE_NAME, {
  part: 'frontpage',
  teamName: TEAM_NAME,
});
