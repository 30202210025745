import { styled } from '@ori-ui/mui';

export const Root = styled('li')(({ theme }) => ({
  display: 'flex',
  flex: 'auto',
  gap: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    flex: 1,
    maxWidth: 300,
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  gap: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    textAlign: 'center',
    gap: theme.spacing(2),
  },
}));
