import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useFrontpage } from '../../contexts';
import { useTranslations } from '../../hooks/useTranslations';
import { Image } from './components';
import {
  TOP_BRANDS_SECTION,
  TOP_BRANDS_SECTION_BRANDS,
  TOP_BRANDS_SECTION_BUTTON,
  TOP_BRANDS_SECTION_TITLE,
} from './constants';
import { Brands, ButtonWrapper, Root, Title, ViewAllBrandsButton } from './styles';
import { onLinkClick } from './utils';

export const TopBrandsSection: FC = () => {
  const {
    topBrands: { brands, title },
  } = useFrontpage();
  const { translations } = useTranslations();
  const { getTestId } = useTestId();
  const handleViewAllBrandsButtonClick = useCallback(
    () => onLinkClick(translations.viewAllBrands, '/brands'),
    [translations.viewAllBrands],
  );

  return (
    <Root data-testid={getTestId(TOP_BRANDS_SECTION)}>
      {title ? (
        <Title
          variant="h3"
          component="h3"
          textAlign="center"
          color="textPrimary"
          data-testid={getTestId(TOP_BRANDS_SECTION_TITLE)}
        >
          {title}
        </Title>
      ) : null}
      <Brands data-testid={getTestId(TOP_BRANDS_SECTION_BRANDS)}>
        {brands.map(({ imageUrl, name, url }) => (
          <Image
            key={imageUrl}
            imageUrl={imageUrl}
            name={name}
            url={url}
          />
        ))}
      </Brands>
      <ButtonWrapper>
        <ViewAllBrandsButton
          color="default"
          data-testid={getTestId(TOP_BRANDS_SECTION_BUTTON)}
          href="/brands"
          variant="outlined"
          onClick={handleViewAllBrandsButtonClick}
        >
          {translations.viewAllBrands}
        </ViewAllBrandsButton>
      </ButtonWrapper>
    </Root>
  );
};
