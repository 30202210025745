import { Team, TestIdProvider } from '@ori/testid-generator';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

const DynamicNotificationBanner = dynamic(
  async () => (await import(/* webpackChunkName: "ori-frontpage" */ '@ori/frontpage')).NotificationBannerMemo,
  {
    ssr: true,
  },
);

export const LazyNotificationBanner: FC = () => (
  <TestIdProvider
    team={Team.Presentation}
    project="notification-banner"
  >
    <DynamicNotificationBanner />
  </TestIdProvider>
);
