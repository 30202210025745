import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerWithProductsAndButtonSkeleton } from '../../components';
import {
  ICONIC_MAX_PRODUCTS_TO_DISPLAY,
  ICONIC_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
  ICONIC_SECTION_SKELETON,
} from './constants';

export const IconicProductsSectionSkeleton: FC = () => {
  const { getTestId } = useTestId();

  return (
    <BannerWithProductsAndButtonSkeleton
      displayCircle
      testId={getTestId(ICONIC_SECTION_SKELETON)}
      button={{
        alignment: 'left',
      }}
      banner={{
        alignment: 'right',
        bannerOrientation: 'portrait',
      }}
      products={{
        maxProductsToDisplay: ICONIC_MAX_PRODUCTS_TO_DISPLAY,
        maxProductsToDisplayMobile: ICONIC_MAX_PRODUCTS_TO_DISPLAY_MOBILE,
      }}
    />
  );
};
