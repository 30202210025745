import { styled, Typography } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 3),
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  maxWidth: '345px',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '600px',
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(6),
  },
}));

export const StyledContent = styled(Typography)(({ theme }) => ({
  maxWidth: '100%',
  padding: theme.spacing(0, 3),
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '600px',
    padding: 0,
  },
}));

export const Line = styled('div')(({ theme }) => ({
  border: `2px solid ${theme.palette.green[200]}`,
  borderRadius: theme.spacing(1),
  height: '0px',
  opacity: 1,
  width: '86px',
}));
