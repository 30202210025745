import type { VipBenefits, VipBenefitsFragment, VipBenefitsImage } from '../models';

export const shouldRenderVipBenefits = ({
  images,
  linkRelativeUrl,
  linkTitle,
}: Pick<VipBenefits, 'images' | 'linkRelativeUrl' | 'linkTitle'>): boolean =>
  images.length > 0 && !!linkRelativeUrl && !!linkTitle;

export const getVipBenefits = (data: VipBenefitsFragment['vipBenefits']): VipBenefits => {
  const parsedData: Omit<VipBenefits, 'shouldRender'> = {
    images: (data?.images ?? []).filter(
      (image): image is VipBenefitsImage => !!image?.subTitle && !!image.title && !!image.url,
    ),
    linkRelativeUrl: data?.link?.relativeUrl ?? '',
    linkTitle: data?.link?.title ?? '',
  };

  return {
    ...parsedData,
    shouldRender: shouldRenderVipBenefits(parsedData),
  };
};
