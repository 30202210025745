import { ProductStandardsSectionSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import { LazyWrapper } from './LazyWrapper';

const DynamicProductStandards = dynamic(
  async () =>
    (await import(/* webpackChunkName: "ori_product-standards-component" */ '@ori/frontpage')).ProductStandardsSection,
  {
    ssr: true,
    loading: () => <ProductStandardsSectionSkeleton />,
  },
);

export const LazyProductStandards: FC = () => {
  const {
    productStandards: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicProductStandards}
      section="product-standards"
      shouldRender={shouldRender}
      skeleton={<ProductStandardsSectionSkeleton />}
    />
  );
};
