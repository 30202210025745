import { BeautyVideoSectionSkeleton, useFrontpage } from '@ori/frontpage';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import { LazyWrapper } from './LazyWrapper';

const DynamicBeautyVideo = dynamic(
  async () => (await import(/* webpackChunkName: "ori_beauty-video-component" */ '@ori/frontpage')).BeautyVideoSection,
  {
    ssr: true,
    loading: () => <BeautyVideoSectionSkeleton />,
  },
);

export const LazyBeautyVideo: FC = () => {
  const {
    beautyVideo: { shouldRender },
  } = useFrontpage();

  return (
    <LazyWrapper
      Component={DynamicBeautyVideo}
      section="beauty-video"
      shouldRender={shouldRender}
      skeleton={<BeautyVideoSectionSkeleton />}
    />
  );
};
