import { gql } from '@ori/fetching';

export const CAMPAIGN_BANNERS_FRAGMENT = gql`
  fragment CampaignBanners on CampaignBanner {
    imageUrl
    headline
    tagline
    shortText
    fontColor
    mobileFontColor
    link {
      title
      relativeUrl
    }
  }
`;
