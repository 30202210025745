import { gql } from '@ori/fetching';

export const BEST_OFFERS_BANNERS_FRAGMENT = gql`
  fragment BestOffersBanners on BestOffersBanner {
    fontColor
    mobileFontColor
    headline
    imageUrl
    link {
      relativeUrl
      title
    }
  }
`;
