/* istanbul ignore file */

/**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */
import type { TranslationKey } from '../models/TranslationKey';

export type DefaultTranslations = Record<keyof typeof TranslationKey, string>;

export const defaultTranslations: DefaultTranslations = {
  bestOffers: 'Best offers',
  bestSellers: 'Best sellers',
  close: 'Close',
  favorites: 'Favorites',
  iconicProducts: 'Icons of our time',
  play: 'Play',
  quickBuy: 'Quick buy',
  viewAll: 'View all',
  viewAllBrands: 'View all brands',
  viewOffers: 'View offer',
  whatsNew: "What's new",
};

export const translationsNamespace = 'frontpage';
