import { mapProductBoxesFromGql } from '@ori/product-box';

import type { GetFrontPageQuery, WhatsNew } from '../models';

export const shouldRenderWhatsNew = ({ productBoxes }: Pick<WhatsNew, 'productBoxes'>): boolean =>
  productBoxes.length > 0;

export const getWhatsNew = (data: GetFrontPageQuery): WhatsNew => {
  const [banner] = data.application?.frontpage?.digitalPromotions?.whatsNewBanners ?? [];
  const productBoxes = mapProductBoxesFromGql([
    ...(data.application?.frontpage?.digitalPromotions?.whatsNewProducts ?? []),
  ]);

  return {
    banner: banner ?? null,
    productBoxes,
    shouldRender: shouldRenderWhatsNew({ productBoxes }),
  };
};
